import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/user-management.service';
import { EncdecService } from 'src/app/services/encdec.service';
import { ConstantsModule } from 'src/app/constants/constants.module';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordForm: FormGroup = this.fb.group({
    currentPassword: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    confirmNewPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
  })

  showError: boolean = false;
  context: string = 'Change';
  
  constructor(
    private userService: UserManagementService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modal: NgbActiveModal,
    private toastrService: ToastrService,
    private encdecservice : EncdecService,
    private constant : ConstantsModule
  ) { }

  ngOnInit() {

  }

  getCtrl(ctrlName) {
    return !isNullOrUndefined(this.passwordForm.controls[ctrlName]) ? this.passwordForm.controls[ctrlName] : null;
  }

  hasError(ctrlName) {
    return (this.getCtrl(ctrlName).touched || this.getCtrl(ctrlName).dirty) && !isNullOrUndefined(this.getCtrl(ctrlName).errors);
  }

  handleChangePassword() {
    this.showError = true;
    if (this.passwordForm.valid) {
      let temp = this.passwordForm.value;
      let passwordHash  = this.encdecservice.rsaenc(this.constant.PUBLIC_KEY,temp.currentPassword+","+temp.newPassword);
      let newPassword = temp.newPassword;
      let confirmNewPassword = temp.confirmNewPassword;
      console.log(passwordHash)
      if(newPassword == confirmNewPassword){
        let postdata = {
          passwordHash
        }
        this.userService.changePassword(postdata.passwordHash).subscribe((res: any) => {
          if (res.result.success) {
            Swal.fire('Success...', res.result.content, 'success');
            this.modal.close();
          } else {
            if(res.result.status != 406){
            Swal.fire('Failure...',res.result.content, 'error');
            }
          }
        }, (err) => {
          Swal.fire('Failure...', err.message, 'error');
        })
        return;
      }else {
        Swal.fire('Failure...', "New Password and Confirm New Password should be same", 'error');
      }
    }
  }

  resetValues() {
    this.passwordForm.reset();
  }

  closeModal() {
    this.modal.dismiss();
  }
}