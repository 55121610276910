export const ProjConstants = {
  LOCATION_HIE: {
      DEFAULT_HIE: ['state', 'district', 'mandal', 'village', 'borewell'],
      GEO: ['state', 'district', 'mandal', 'college','student'],
      // CAMS_TABLE : ['state','canal','sub canal','distributary','minor','subMinor']
      CAMS_TABLE : ['state','sub canal','distributary'],
      PMS_REPORT : ['component','package','activity'],
      LA : ['srlip','package no','package'],
      FINANCIAL : ['srlip','package no','package'],
      ALERTS : ['srlip','package no','package'],
      PHYSICAL : ['srlip','package','component','activity']
    },
    LOCATION_CONSTANT_OPTIONS: {
      url: ['entityName', 'entityId'],
      dataType: 'FINALLOCATION',
      child: 'district',
      jsonkey: 'entityName',
      name: 'District'
    },
    CHART_LOC_MAPPING :{
      'land acquisition' : 'LA',
      'financial' : 'FINANCIAL',
      'physical' : 'PHYSICAL', 
      'alerts' : 'ALERTS'
    }
}
