import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ProjectDashboardComponent } from './pages/project-dashboard/project-dashboard.component';
import { OverViewComponent } from './pages/over-view/over-view.component';
import { LoginComponent } from './login/login.component';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsModule } from './constants/constants.module';
import { HttpClient } from '@angular/common/http';
import { UserManagementModule } from './modules/user-management';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { GisMapComponent } from './pages/gis-map/gis-map.component';
import { MapConstantsModule } from './pages/gis-map/map-constants.module';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ComponentDetailsComponent } from './pages/component-details/component-details.component';
import { BottomBarComponent } from './pages/bottom-bar/bottom-bar.component';
import { DrawToolComponent } from './pages/bottom-bar/draw-tool/draw-tool.component';
import { BaselayersComponent } from './pages/bottom-bar/baselayers/baselayers.component';
import { MeasurementToolComponent } from './pages/bottom-bar/measurement-tool/measurement-tool.component';
import { LayercontrolComponent } from './pages/bottom-bar/layercontrol/layercontrol.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { DistanceConversionPipe } from './pages/bottom-bar/measurement-tool/distance-conversion.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { GeoportalLandingComponent } from './geoportal-landing/geoportal-landing.component';
import { AngularSplitModule } from 'angular-split';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DatatabComponent } from './datatab/datatab.component';
import { HeaderComponent } from './pages/header/header.component';
import { CommonModule } from '@angular/common';
import { ExpandModalComponent } from './pages/expand-modal/expand-modal.component';
import { TableModule } from './modules/table/table.module';
import { ReportComponent } from './pages/report/report.component';
import { ReportModalComponent } from './pages/report/report-modal/report-modal.component';
import { SharedConstantsModule } from './shared/shared-constants.module';
import { ImageExpandModalComponent } from './image-expand-modal/image-expand-modal.component';
import { ReportAlertMobileComponent } from './pages/report/report-alert-mobile/report-alert-mobile.component';
import { ReportDataValidationComponent } from './pages/report/report-data-validation/report-data-validation.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 10,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 0, // progress bar thickness
  hasProgressBar : false,
  bgsOpacity : 0
};

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ProjectDashboardComponent,
    OverViewComponent,
    LoginComponent,
    LogoutPopupComponent,
    ProjectDetailsComponent,
    GisMapComponent,
    SpinnerComponent,
    ComponentDetailsComponent,
    BottomBarComponent,
    DrawToolComponent,
    BaselayersComponent,
    MeasurementToolComponent,
    LayercontrolComponent,
    DistanceConversionPipe,
    GeoportalLandingComponent,
    SidebarComponent,
    DatatabComponent,
    HeaderComponent,
    ExpandModalComponent,
    ReportComponent,
    ReportModalComponent,
    ImageExpandModalComponent,
    ReportAlertMobileComponent,
    ReportDataValidationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    UserManagementModule,
    ToastrModule.forRoot(),
    MapConstantsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularDraggableModule,
    ColorPickerModule,
    AngularSplitModule,
    TableModule,
    SharedConstantsModule  
  ],
  providers: [NgbActiveModal,ConstantsModule,CookieService],
  bootstrap: [AppComponent],
  entryComponents : [LoginComponent,ExpandModalComponent,ReportModalComponent,ImageExpandModalComponent]
})
export class AppModule { }
