import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SharedConstantsModule } from 'src/app/shared/shared-constants.module';
@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  urlParams: any;
  projectList: any = [];
  type: any = 'all';
  status: any = 'all'
  currentList: any = [];
  projectDetailsList = {};
  constructor(private router: Router, private httpDataService: HttpService,
    private utilsService: UtilsService,private sharedConstants : SharedConstantsModule) { }

  ngOnInit() {
    this.getProjectList();
    this.getPhysicalData();
    this.getFinancialData();
  }

  goToLandingPage() {
    this.router.navigate(['tsripms']);
  }

  showProjectDetails(item) {
    if (item == 'Sita Rama LIS') {
      this.urlParams = {
        projectName: 'sitaRama',
        locType: 'PROJECT'
      }
      this.router.navigate(['tsripms', 'projectDashboard', 'overview', this.urlParams]);
    }

  }

  onTypeChange(type) {
    this.currentList = [];
    if (this.status == 'all') {
      for (let i = 0; i < this.projectList.length; i++) {
        let list = this.projectList[i];

        Object.keys(list).forEach((key: any) => {
          if (key == type) {
            this.currentList = list[key];
          }
        })
      }
    } else {
      let selectedStatus, selectedType;
      for (let i = 0; i < this.projectList.length; i++) {
        let list = this.projectList[i];
        Object.keys(list).forEach((key: any) => {
          if (key == type) {
            selectedType = list[key];
          } else if (key == this.status) {
            selectedStatus = list[key];
          }
        })
      }
      this.currentList = selectedType.filter(x => selectedStatus.includes(x));

    }

  }

  onStatusChange(status) {
    this.currentList = [];
    if (this.type == 'all') {
      for (let i = 0; i < this.projectList.length; i++) {
        let list = this.projectList[i];
        Object.keys(list).forEach((key: any) => {
          if (key == status) {
            this.currentList = list[key];
          }
        })
      }
    } else {
      let selectedStatus, selectedType;
      for (let i = 0; i < this.projectList.length; i++) {
        let list = this.projectList[i];
        Object.keys(list).forEach((key: any) => {
          if (key == status) {
            selectedStatus = list[key];
          } else if (key == this.type) {
            selectedType = list[key];
          }
        })
      }
      this.currentList = selectedType.filter(x => selectedStatus.includes(x));

    }

  }

  getProjectList() {
    this.httpDataService.getProjectList(this.sharedConstants.telanganaUUID).subscribe((response: any) => {
      let major = [];
      let minor =[];
      let medium =[];
      let onGoing = [];
      let completed =[];

      Object.keys(response).forEach((key: any) => {{
               let data = response[key];    
                 let item = {}; 
                 item['name'] = key;
                 item['type'] = data['type'];    
                 item['status'] = data['status'];    
                  if(item['type']=='Major'){
                    if(item['name']=="Sita Rama LIS"){
                      major.unshift("Sita Rama LIS");
                    }
                    else{
                      major.push(item['name']);
                    }
                  }else if(item['type']=='Minor'){
                    minor.push(item['name']);
                  }else if(item['type']=='Medium'){
                    medium.push(item['name']);
                  }
                  if(item['status']=='Ongoing'){
                    if(item['name']=="Sita Rama LIS"){
                      onGoing.unshift(item['name']);
                    }
                    else{
                    onGoing.push(item['name']);
                    }
                  }else if(item['status']=='Completed'){
                    completed.push(item['name']);
                  }
                  let all = major.concat(medium);
                  this.projectList.push({"major":major});
                  this.projectList.push({"minor":minor});
                  this.projectList.push({"medium":medium});
                  this.projectList.push({"onGoing":onGoing});
                  this.projectList.push({"completed":completed});
                  this.projectList.push({"all":all});
                  this.onTypeChange('all');
        }});

    })
  }
  getPhysicalData() {
    let params = this.httpDataService.getProjectComponentLevelParams('20210306', 'PROJECT', '932cff74-cc1f-4d47-b222-c2802abd986c')
    this.httpDataService.getProjectComponentLevelData(params).subscribe((response) => {
      if (response) {
        response = response.eData;
        this.projectDetailsList['workCompleted'] = response['cumulative']['per'].toFixed(2);
      }
    });
  }
  getFinancialData() {
    this.httpDataService.getFinancialLevelData().subscribe((res) => {
      if (res) {
        this.projectDetailsList['amountSpent'] = this.utilsService.fixedToThousandsDecimal(res['SITA-RAMA-PPM']['amountSpent'], 2);
      }
    });
  }
}
