import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { isNullOrUndefined } from 'util';
import { ReportModalComponent } from '../report-modal/report-modal.component';

@Component({
  selector: 'app-report-alert-mobile',
  templateUrl: './report-alert-mobile.component.html',
  styleUrls: ['./report-alert-mobile.component.scss']
})
export class ReportAlertMobileComponent implements OnInit {
  navigationSubscription: Subscription;
  urlParams: any;
  tableData: any = [];
  dataArray: any = [];
  tableSpinner: boolean = false;
  totalDataCopy: any[];
  alertTableDDList: any = ['Yes', 'No'];
  alertTableDDSelection: any = {};
  actionHeaderSelection: boolean = false;
  packageListMapping: any = {
    'PACKAGE1': 'P-1',
    'PACKAGE2': 'P-2',
    'PACKAGE3': 'P-3',
    'PACKAGE4': 'P-4',
    'PACKAGE5': 'P-5',
    'PACKAGE6': 'P-6',
    'PACKAGE7': 'P-7',
    'PACKAGE8': 'P-8',
  };
  constructor(private utilsService: UtilsService, private httpDataService: HttpService,
    private router: Router, private route: ActivatedRoute, private modalSrv: NgbModal) {
    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        this.initializeOnStateLoad();
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Hide loading indicator  // Present error to user
      }
    });
  }

  ngOnInit() {
    this.initializeOnStateLoad();
  }
  initializeOnStateLoad() {
    this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
    document.body.classList.add('light')
    this.getAlertTable();
  }
  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }
  getAlertTable() {
    this.dataArray = [];
    this.tableSpinner = true;
    let i = 0;
    const params = this.httpDataService.getAlertsParams(this.urlParams);
    this.httpDataService.getAlertsData(params).subscribe((res) => {
      if(!this.utilsService.isEmptyObj(res)){
      let data = res[this.urlParams['packageName']];
      data.forEach((response: any) => {
        let item = {};
        const index = this.urlParams['packageName'].search(/[0-9]/g);
        let key = this.urlParams['packageName'];
        if (index > -1) {
          const packageNo = this.urlParams['packageName'].slice(index);
          key = this.urlParams['packageName'].split(packageNo).join('');
          key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          key += ' ' + packageNo;
        }
        item['name'] = key;
        item['id'] = response['esid'] ? response['esid'] : '-';
        this.alertTableDDSelection[++i] = '';
        item['loc'] = response['en'] ? this.utilsService.splitLocationString(response['en']) : '-';
        item['type'] = response['etype'] ? response['etype'].split(' ')[0] : '-';
        item['category'] = response['estype'] ? response['estype'] : '-';
        item['raisedOn'] = response['eraisd'] ? this.utilsService.getDateInDDMMYYYYFormat(response['eraisd'].toString()) : '-';
        item['initiatedBy'] = response['uname'] ? response['uname'] : '-';
        item['referredTo'] = response['rto'] ? response['rname'].concat(' ').concat(response['rto']) : '-';
        item['status'] = response['status'] ? response['status'] : '-';
        item['resolvedOn'] = response['resd'] ? this.utilsService.getDateInDDMMYYYYFormat(response['resd'].toString()) : '-';
        item['resolvedBy'] = response['irby'] ? response['irby'] : '-';
        item['remarks'] = response['erem'] ? response['erem'] : '-';
        this.dataArray.push(item);
        this.tableSpinner = false;
        this.totalDataCopy.push(item);
      })
    }
    else{
      this.tableSpinner = false;
    }
    });
  }

  callReportModalComponent(event, remarks?) {
    let value = document.body.classList.value;
    let styling;
    if (value == 'body-bg-dark') {
      styling = {
        backdrop: true,
        centered: true,
        windowClass: "chart-dark-modal"
      }
    } else {
      styling = {
        backdrop: true,
        centered: true,
        windowClass: "chart-light-modal"
      }
    }
    const fullScreenModal = this.modalSrv.open(ReportModalComponent, styling);
    fullScreenModal.componentInstance.title = event == 'REMARKS' ? 'Remarks History' : 'Action';
    fullScreenModal.componentInstance.selectionType = event;
    fullScreenModal.componentInstance.urlParams = this.urlParams;
    if (remarks) {
      fullScreenModal.componentInstance.remarks = remarks;
    }
  }
  change() {
    this.actionHeaderSelection = true;
  }
}
