import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as jsPDF from 'jspdf';
import * as domtoimage from '../../../../assets/mapLibrary/print/dom-to-image';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';
import { DrawtoolService } from './drawtool.service';
@Component({
  selector: 'app-draw-tool',
  templateUrl: './draw-tool.component.html',
  styleUrls: ['./draw-tool.component.scss']
})
export class DrawToolComponent implements OnInit {
  map :L.Map;
  @ViewChild('shape_label') shapeLabelDom: ElementRef;
  selectedShape = 'line';
  isMouseDown = false;
  restricted = false;
  shapeLabel = false;
  shapeLabelPosition = {
    x: 150,
    y: 150,
    r: 0
  };
  currentShapeLatLng: any[] = [];
  currentShape: any;
  currentShapeStyle: any;
  textInput = '';
  isDraggable: boolean;

  showSpinner = false;
  // mapLoaderActive: boolean =false;
 @Input() mapComponent : L.Map;
 @Input() layersList : any;
 @Output() drawToolClearShape : any = new EventEmitter<object>()
 
 constructor(private toastr : ToastrService,
  private rConstants : DrawtoolService,
  private utilsSrv :UtilsService) { }

 ngOnInit() {
  this.map = this.mapComponent;
  this.restricted = false;
  this.rConstants.enabled = true;
  this.currentShapeStyle = this.rConstants.LINE_STYLES[0];    
  this.mapHandlers();
  for(const key in this.layersList.overlaylayers){
    if(this.layersList.overlaylayers[key].options.type == 'VECTOR' &&
    this.layersList.overlaylayers[key].options.component!='WATER_SPREAD'){
      this.layersList.overlaylayers[key].off('click');
    }
  }
}

ngOnDestroy(): void {
  this.map.dragging.enable();
  this.rConstants.enabled = false;
  this.map.off('mousedown');
  this.map.off('mousemove');
  this.map.off('mouseup');
  // for(const key in this.layersList.overlaylayers){
  //   if(this.layersList.overlaylayers[key].options.type == 'VECTOR'
  //   &&  this.layersList.overlaylayers[key].options.component!='WATER_SPREAD'){
  //     this.utilsSrv.sendNavigatedLayer(this.layersList.overlaylayers[key])
  //     }
  // }
}

mapHandlers() {
  this.map.dragging.disable();
  const that = this;
  this.map.on('mousedown', (event: { latlng: { lat: any; lng: any; }; }) => {
    if (that.rConstants.enabled && !that.restricted) {
      that.map.dragging.disable();
      that.isMouseDown = true;
      // that.currentShapeLatLng = [[event.latlng.lat,event.latlng.lng]];
      if (that.selectedShape !== 'polygon') {
        that.currentShapeLatLng = [[event.latlng.lat, event.latlng.lng]];
        that.currentShape = null;
      }
    }
  });

  this.map.on('mousemove', (event: { latlng: { lat: any; lng: any; }; }) => {
    if (that.isMouseDown && that.rConstants.enabled && !that.restricted) {
      if (that.selectedShape === 'line') {
        if (that.currentShapeLatLng.length >= 2) {
          that.currentShapeLatLng[1] = [event.latlng.lat, event.latlng.lng];
        } else {
          that.currentShapeLatLng.push([event.latlng.lat, event.latlng.lng]);
        }
        if (that.currentShape) {
          that.currentShape.remove();
        }
        that.currentShape = L.polyline(that.currentShapeLatLng, that.currentShapeStyle);
        const radius = L.latLng(that.currentShapeLatLng[0]).distanceTo(that.currentShapeLatLng[1]);
        that.currentShape.bindTooltip(`Distance : ${(radius / 1000).toFixed(3)} km`).openTooltip();
        that.currentShape.addTo(that.map);
        // that.currentShape.setLatLngs(that.currentShapeLatLng);
      } else if (that.selectedShape === 'rectangle') {
        if (that.currentShapeLatLng.length >= 2) {
          that.currentShapeLatLng[1] = [event.latlng.lat, event.latlng.lng];
        } else {
          that.currentShapeLatLng.push([event.latlng.lat, event.latlng.lng]);
        }
        if (that.currentShape) {
          that.currentShape.remove();
        }
        that.currentShape = L.rectangle(that.currentShapeLatLng, that.currentShapeStyle);
        const radius = L.latLng(that.currentShapeLatLng[0]).distanceTo(that.currentShapeLatLng[1]);
        const area = that.getAreaRectangle(that.currentShapeLatLng[0], that.currentShapeLatLng[1]);
        const perimeter = that.getPerimeterRectangle(that.currentShapeLatLng[0], that.currentShapeLatLng[1]);
        that.currentShape.bindTooltip(`Diagonal : ${(radius / 1000).toFixed(3)} km<br/>Perimeter : ${(perimeter).toFixed(3)} km<br/>Area : ${(area).toFixed(3)} km<sup>2</sup>`).openTooltip();
        that.currentShape.addTo(that.map);
      } else if (that.selectedShape === 'circle') {
        if (that.currentShapeLatLng.length >= 2) {
          that.currentShapeLatLng[1] = [event.latlng.lat, event.latlng.lng];
        } else {
          that.currentShapeLatLng.push([event.latlng.lat, event.latlng.lng]);
        }
        if (that.currentShape) {
          that.currentShape.remove();
        }
        // let radius = that.getDistance(that.currentShapeLatLng[0], that.currentShapeLatLng[1]);
        const radius = L.latLng(that.currentShapeLatLng[0]).distanceTo(that.currentShapeLatLng[1]);
        const area = that.getAreaCircle(radius);
        const perimeter = that.getPerimeterCircle(radius);
        that.currentShape = L.circle(that.currentShapeLatLng[0], radius, that.currentShapeStyle);
        that.currentShape.bindTooltip(`Radius : ${(radius / 1000).toFixed(3)} km<br/>Circumference : ${(perimeter).toFixed(3)} km<br/>Area : ${(area).toFixed(3)} km<sup>2</sup>`).openTooltip();
        that.currentShape.addTo(that.map);
      }
    }

  });

  this.map.on('mouseup', (event: { containerPoint: any; latlng: { lat: number; lng: number; }; }) => {
    if (that.rConstants.enabled && !that.restricted) {
      // that.currentShapeLatLng.push(event.latlng);

      if (that.currentShape && (that.selectedShape === 'line' || that.selectedShape === 'rectangle' || that.selectedShape === 'circle')) {
        that.rConstants.visibleShapes.push({ shape: that.currentShape });
        if (that.selectedShape === 'rectangle') {
          const lng = (that.currentShapeLatLng[0][1] + that.currentShapeLatLng[1][1]) / 2;
          const lat = Math.min(that.currentShapeLatLng[0][0], that.currentShapeLatLng[1][0]);
          const point = that.map.latLngToContainerPoint([lat, lng]);
          that.showShapeLabel(point, 0, 7, 0);
        } else if (that.selectedShape === 'line') {
          const lng = (that.currentShapeLatLng[0][1] + that.currentShapeLatLng[1][1]) / 2;
          const lat = (that.currentShapeLatLng[0][0] + that.currentShapeLatLng[1][0]) / 2;
          const point = that.map.latLngToContainerPoint([lat, lng]);
          const slope = this.getSlopeLine(that.currentShapeLatLng[0], that.currentShapeLatLng[1]);
          that.showShapeLabel(point, 0, 0, slope);
        } else {
          that.showShapeLabel(event.containerPoint, 20, 0, 0);
             }

        that.currentShape = null;
        that.currentShapeLatLng = [];
      } else if (that.selectedShape === 'polygon') {
        if (that.currentShapeLatLng.length > 0 && that.compareLatLngs(that.currentShapeLatLng[that.currentShapeLatLng.length - 1], [event.latlng.lat, event.latlng.lng])) {
          // simulating double click
          that.rConstants.visibleShapes.push({ shape: that.currentShape });
          that.currentShape = null;
          const centroid = that.getCentroidPolygon(that.currentShapeLatLng);
          const centroidPoint = that.map.latLngToContainerPoint([centroid[0],centroid[1]]);
          that.showShapeLabel(centroidPoint, 20, 0, 0);
          that.currentShapeLatLng = [];
        } else {
          that.currentShapeLatLng.push([event.latlng.lat, event.latlng.lng]);
          if (that.currentShape) {
            that.currentShape.remove();
          }
          that.currentShape = L.polygon(that.currentShapeLatLng, that.currentShapeStyle);
          const perimeter = that.getPerimeterPolygon(that.currentShapeLatLng);
          const area = turf.area(that.currentShape.toGeoJSON());
          that.currentShape.bindTooltip(`Perimeter : ${perimeter.toFixed(3)} km<br>Area : ${(area / 1000000).toFixed(3)} km<sup>2</sup>`).openTooltip();
          that.currentShape.addTo(that.map);
        }
      } else if (that.selectedShape === 'point') {
        const icon = L.icon({
          iconUrl: that.currentShapeStyle.src,
          iconSize: [40, 40],
        });
        const point = L.marker(event.latlng, { icon });
        point.bindTooltip(`${event.latlng.lat}, ${event.latlng.lng}`).openTooltip();
        point.addTo(that.map);
        that.rConstants.visibleShapes.push({ shape: point });
        that.currentShape = point;
        // that.shapeLabel = true;
        that.showShapeLabel(event.containerPoint, 20, 0, 0);
      } else if (that.selectedShape === 'text') {
        const icon = L.divIcon({
          className: 'div-icon',
          html: `<p style='color: ${that.currentShapeStyle.color}; font-size: ${that.currentShapeStyle.size}px; white-space: nowrap;'>${that.textInput}</p>`
        });
        const label = L.marker(event.latlng, { icon: icon }).addTo(that.map);
        that.rConstants.visibleShapes.push({ shape: label });
        that.currentShape = label;
      }
      this.drawToolClearShape.emit({visibleShapes: that.rConstants.visibleShapes});
      that.isMouseDown = false;
    } else if (that.restricted === true) {
      that.restricted = false;
    }
  });
}

selectShape(shape: string) {
  // this.discardStrayShape();
  this.selectedShape = shape;
  this.currentShapeStyle = this.rConstants.getStyles(shape)[0];
}

selectStyle(shape: string, index: number) {
  // this.discardStrayShape();
  this.currentShapeStyle = this.rConstants.getStyles(shape)[index];
}

undoShape() {
  this.shapeLabel = false;
  const length = this.rConstants.visibleShapes.length;
  if (length > 0) {
    const shapeObj = this.rConstants.visibleShapes[length - 1];
    shapeObj.shape.remove();
    if (shapeObj.text) { shapeObj.text.remove(); }
    this.rConstants.removedShapes.push(this.rConstants.visibleShapes.pop());
  }
}

redoShape() {
  const length = this.rConstants.removedShapes.length;
  if (length > 0) {
    const shapeObj = this.rConstants.removedShapes[length - 1];
    shapeObj.shape.addTo(this.map);
    if (shapeObj.text) { shapeObj.text.addTo(this.map); }
    this.rConstants.visibleShapes.push(this.rConstants.removedShapes.pop());
    this.drawToolClearShape.emit({visibleShapes: this.rConstants.visibleShapes});
  }
}


clearAllShapes() {
  this.shapeLabel = false;
  for (const shape of this.rConstants.visibleShapes) {
    if (shape) {
      shape.shape.remove();
      if (shape.text) { shape.text.remove(); }
    }
  }
  this.rConstants.visibleShapes = [];
  this.rConstants.removedShapes = [];
}

discardStrayShape() {
  if (this.currentShape) {
    this.currentShape.remove();
    this.currentShape = null;
    this.currentShapeLatLng = [];
  }
}

shapeLabelSubmit(event: any, value: string) {
  if (event.key === 'Enter') {
    const rotation = this.shapeLabelPosition.r;
    this.shapeLabelPosition.x += ((rotation >= 0 && rotation <= 90) ? (rotation - 10) : (rotation / 4.0 + 10));
    const latlng = this.map.containerPointToLatLng([this.shapeLabelPosition.x,this.shapeLabelPosition.y]);
    this.shapeLabel = false;
    const icon = L.divIcon({
      className: 'div-icon',
      html: `<p style="font:italic bold 18px arial; transform:rotate(${this.shapeLabelPosition.r}deg);white-space: nowrap;">${value}</p>`
    });
    const label = L.marker(latlng, { icon }).addTo(this.map);
    this.rConstants.visibleShapes[this.rConstants.visibleShapes.length - 1].text = label;
  }
}

showShapeLabel(point: { x: number; y: number; }, xOffset: number, yOffset: number, rotation: number) {
  this.shapeLabelPosition.x = point.x + xOffset - (rotation >= 0 && rotation <= 90 ? rotation : rotation / 4.0);
  this.shapeLabelPosition.y = point.y + yOffset;
  this.shapeLabelPosition.r = rotation;
  this.shapeLabel = true;
  // this.shapeLabelDom.nativeElement.focus();
}

// ----------------------MENSURATION-------------------


getAreaRectangle(latlng1: number[], latlng2: number[]): number {
  const latlng3 = [latlng2[0], latlng1[1]];
  const sideA = L.latLng([latlng1[0],latlng1[1]]).distanceTo([latlng3[0],latlng3[1]]);
  const sideB = L.latLng([latlng2[0],latlng2[1]]).distanceTo([latlng3[0],latlng3[1]]);
  return sideA * sideB / 1000000;
}

getAreaCircle(radius: number): number {
  return Math.PI * radius * radius / 1000000;
}

compareLatLngs(latlng1: number[], latlng2: number[]): boolean {
  if (latlng1[0] === latlng2[0] && latlng1[1] === latlng2[1]) {
    return true;
  } else {
    return false;
  }
}

getPerimeterRectangle(latlng1: number[], latlng2: number[]): number {
  const latlng3 = [latlng2[0], latlng1[1]];
  const sideA = L.latLng([latlng1[0],latlng1[1]]).distanceTo([latlng3[0],latlng3[1]]);
  const sideB = L.latLng([latlng2[0],latlng2[1]]).distanceTo([latlng3[0],latlng3[1]]);
  return 2 * (sideA + sideB) / 1000;
}

getPerimeterCircle(radius: number): number {
  return 2 * Math.PI * radius / 1000;
}

getPerimeterPolygon(latlngs: number[]): number {
  let perimeter = 0;
  for (let i = 0; i < latlngs.length - 1; i++) {
    perimeter += L.latLng([latlngs[i][0],latlngs[i][1]]).distanceTo([latlngs[i + 1][0],latlngs[i + 1][1]]);
  }
  return perimeter / 1000;
}

getSlopeLine(latlng1: number[], latlng2: number) {// slope of line in degrees
  const m = (latlng2[1] - latlng1[1]) / (latlng1[0] - latlng2[0]);
  const rad = Math.atan(m);
  const degree = (180 * rad) / Math.PI;
  if (degree >= 0) {
    return (90 - degree);
  } else { return (270 - degree); }

}

getCentroidPolygon(latlngs: number[][]): number[] {
  const flatlng = [0, 0];
  for (const latlng of latlngs) {
    flatlng[0] += latlng[0];
    flatlng[1] += latlng[1];
  }
  flatlng[0] /= latlngs.length;
  flatlng[1] /= latlngs.length;
  return flatlng;
}

saveMap() {
  this.toggleControls(false);
  this.downloadPDF();
}

downloadPDF() {
  const  fileName = 'SITARAM';
  this.showSpinner = true;
  const pdf = new jsPDF({
    unit: 'pt',
    format: 'a4',
    orientation: 'l',
    compress: true
  });
  const that = this;
  const width = pdf.internal.pageSize.getWidth();
  const height = pdf.internal.pageSize.getHeight();
  const printContainer = document.getElementById('mapid');
  const dpi = '150';
  domtoimage.toPng(printContainer, {
    width: parseInt('1240', 10),
    height: parseInt('700', 10),
    dpi: parseInt(dpi, 10)
  }).then((data: any) => {
    pdf.addImage(data, 'png', 0, 0, width, height, null, 'FAST');
    pdf.save(fileName);
    that.toggleControls(true);
    that.showSpinner = false;    
    this.toastr.success('Snapshot saved successfully', '', {
      positionClass: 'toast-bottom-right',
      timeOut: 3500
    });
  }, (error: any) => {
    that.toggleControls(true);
    that.showSpinner = false;
    this.toastr.error('Snapshot could not be taken', '', {
      positionClass: 'toast-bottom-right'
    });
    console.log('Error:: ', error);
  });
}

toggleControls(show: boolean): any {
  const controlContainer = document.getElementsByClassName('leaflet-control-container')[0];
  controlContainer.setAttribute('style', 'display:none;');
  if (show) {
    controlContainer.setAttribute('style', 'display:block;');
  }
  return;
}
}
