import { Component, OnInit, Input } from '@angular/core';
import { DataTableEventsService } from '../../services/datatable.events.service';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class TableBreadcrumbComponent implements OnInit {

  @Input() params: string;

  breadcrumb = [];

  constructor(
    private tableEventsHandler: DataTableEventsService
  ) { }

  ngOnInit() { }

  goToLevel(level) {
    this.tableEventsHandler.breadcrumbClicked.next(level);
  }
}
