import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTableEventsService {

  constructor() { }

  sortOptions = new Subject();

  togglePage = new Subject();

  breadcrumbClicked = new Subject();

  popupCalled = new Subject();

  recordData = new Subject<object[]>();

  totalData = new Subject<object[]>();
  
  data = new Subject<object[]>();

  tableHeaderExpansion = new Subject<object>();

  searchHolder = new Subject<string>();
}
