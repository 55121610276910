import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { DataTableService } from '../../../services/data-table.service';

@Component({
  selector: 'lib-row, [lib-row]',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.css']
})
export class TableRowComponent implements OnInit {

  @Input() datarow: any;
  @Input() totalrow: any;
  @Input() index: string;
  @Input() customTemplates: TemplateRef<any>;
  @Input() headers: any;
  @Input() indexCons: any;

  @Output() onRowClick = new EventEmitter<Object>();

  obj:object = {};

  constructor(private dataTableService: DataTableService) {}

  ngOnInit() { }

  click(event){
    this.onRowClick.emit(event);
  }
  cellInfo(rowInfo, e,i, headers) {
    this.dataTableService.getCellInfo(rowInfo, e, i, headers);
  }

}
