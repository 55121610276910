import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/utils.service';
import "src/assets/mapLibrary/L.vectorgrid.bundle.js";
import "src/assets/mapLibrary/L.marker.resize.js";
import { isNullOrUndefined } from 'util';
import { MapConstantsModule } from './map-constants.module';
import { GisService } from 'src/app/services/gis.service';
import * as R from 'leaflet-responsive-popup';
import cloneLayer from 'leaflet-clonelayer';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class GisDataService {
  activeFeatureData: Object = {
    "id": null,
    "uuid": null,
    "layer": null,
    "external_i": null
  };
  urlParams = {};
  last3DaysRainfall: any;
  next24hrRainfall: any;
  next3DaysRainfall: any;
  next3to5DaysRainfall: any;
  canalLayerObj = new (L as any).LayerGroup();

  linksToCanalMapping = {
    "KAKATIYA_MAINCANAL": { "t": "Sri Ram Sagar Project", "canal": "Kakathiya Canal", "check": "ot" },
    "KAKATIYA_MAINCANAL_Below LMD": { "t": "Lower Manair Dam", "canal": "Kakatya main canal", "check": "ot" },
    "SRSP_FFCMainCanal": { "t": "Sri Ram Sagar Project", "canal": "Flood flow canal", "check": "if" }
  }
  storageData = {};

  colorSymbolIdMapping = {
    0: '#93bcf9',
    1: '#46403e',
    2: '#ffff75',
    3: '#297adf',
    4: '#ff31d0',
    5: '#ff3b3b',
    6: '#baffef',
    7: '#d1ffba',
    8: '#680c0c',
    9: '#cee4ff',
    10: '#a6cec1',
    11: '#f9c0dd'
  }

  public navigatedLayer: BehaviorSubject<any> = new BehaviorSubject<object>({});

  sendNavigatedLayer(value) {
    this.navigatedLayer.next(value);
  }
  getNavigatedLayer() {
    return this.navigatedLayer.asObservable();
  }
  constructor(private gisMapConst: MapConstantsModule,
    private utilsService: UtilsService,
    private http: HttpClient,
    private router: Router,
    private httpDataService: HttpService
  ) {
    this.gisMapConst.getSelectedParams().subscribe(data => this.urlParams = data);
  }

  checkIsMobileView() {
    let windowSize = window.matchMedia("(max-width:992px)");
    if (windowSize['matches']) {
      return true;
    }
    return false;
  }

  createLegendControl() {
    return new (L as any).Control.HtmlLegend({
      position: 'bottomright',
      collapseSimple: false,
      detectStretched: false,
      collapsedOnInit: false
    });
  }

  getApplicationBasedLayer(layerList, layerType, params?, val?) {
    if (layerType == this.gisMapConst.LAYER_TYPES['WMS_LAYER'])
      return this.getWMSOverLays(layerList, params);
    else if (layerType == this.gisMapConst.LAYER_TYPES['VECTOR_LAYER'])
      return this.getVECTOROverlays(layerList, params);
    else if (layerType == this.gisMapConst.LAYER_TYPES['GEOJSON_LAYER'])
      return this.getGEOJSONOverLays(layerList, val);
  }

  addBaseTileLayers(layerList, mapObj) {
    let baselayers = {}
    for (let index in layerList) {
      let layer = layerList[index];
      let layerObj = this.addTileLayer(layer);
      if (layer['visible']) {
        mapObj.removeLayer(layerObj);
        mapObj.addLayer(layerObj);
      } else {
        mapObj.removeLayer(layerObj);
      }
      baselayers[layer['name']] = layerObj;
    }
    return baselayers;
  }

  addTileLayer(layerOptions) {
    let tileLayer;
    // if (layerOptions.name == 'Google Satellite') {
    //   tileLayer = (L.tileLayer as any).colorFilter(
    //     layerOptions.url,
    //     layerOptions
    //   );
    // }
    // else
    tileLayer = new L.TileLayer(layerOptions.url, layerOptions);
    return tileLayer;
  }
  getTimeWmsLayerFromLayerName(layerObj, layerName, date) {
    let wmsLayer = new (L as any).TileLayer.WMS(layerObj._url, {
      layers: layerName,
      format: 'image/png',
      transparent: true,
      maxZoom: 25,
      minZoom: 5,
      pane: 'base',
      time: date,
      timeDimension: true,
      srs: 'EPSG:4326'
    });
    // let timeWmsLayer = new (L as any).timeDimension.layer.wms(wmsLayer,{updateTimeDimension: true});
    // console.log(timeWmsLayer);
    return wmsLayer;
  }
  public getFeatureInfoForGivenPoint(point, bboxString, size, layer) {
    let url = this.getFeatureInfoUrlForGivenPoint(point, bboxString, size, layer);
    return this.http.get(url).pipe(
      catchError((err) => {
        return EMPTY;
      })
    );
  }
  public getFeatureInfoUrlForGivenPoint(point, bboxString, size, layer) {
    let params = {
      request: 'getFeatureInfo',
      service: 'WMS',
      srs: 'EPSG:4326',
      transparent: true,
      version: layer.wmsParams.version,
      format: layer.wmsParams.format,
      bbox: bboxString,
      height: Math.floor(size.y),
      width: Math.floor(size.x),
      layers: layer.wmsParams.layers,//queryLayers.join(','),
      query_layers: layer.wmsParams.layers,//queryLayers.join(','),
      info_format: 'application/json',
      feature_count: 50,
      CQL_FILTER: layer.wmsParams.cql_filter ? layer.wmsParams.cql_filter : '1=1'
    };
    params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
    params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;
    let url = layer._url + L.Util.getParamString(params, layer._url, true);
    return url;
  }
  getWMSOverLays(data, params?) {
    let overlays = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        let overlay: any = {};
        overlay.name = value.name;
        overlay.type = 'wms';
        overlay.component = value.component;
        overlay.layerOptions = {};
        if (value.cType && value.cType.length > 0) {
          overlay.layerOptions.cType = value.cType;
        }
        if (params && params['pUUID']) {
          overlay.layerOptions.pUUID = params['pUUID'];
        }
        overlay.layerOptions.name = value.name;
        overlay.layerOptions.format = 'image/png';
        overlay.layerOptions.transparent = true;
        if (overlay.name == 'State Names')
          overlay.url = 'http://159.65.4.230/geoserver/forecast/ows';
        else if (overlay.name == 'Sentinel 2') {
          overlay.url = value.url;
        }
        else overlay.url = this.getGeoServerURL() + "/wms";
        if (this.validateValue(value.hasLegend))
          overlay.layerOptions.hasLegend = value.hasLegend;
        if (this.validateValue(value.legendName))
          overlay.layerOptions.legendName = value.legendName;
        if (this.validateValue(value.key))
          overlay.layerOptions.key = value.key;
        if (this.validateValue(value.view))
          overlay.layerOptions.view = value.view;
        if (this.validateValue(value.parentView))
          overlay.layerOptions.parentView = value.parentView;
        if (this.validateValue(value.childView))
          overlay.layerOptions.childView = value.childView;
        if (this.validateValue(value.pType))
          overlay.layerOptions.pType = value.pType;
        if (this.validateValue(value.clickZoom))
          overlay.layerOptions.clickZoom = value.clickZoom;
        if (this.validateValue(value.imagesrc))
          overlay.layerOptions.imagesrc = value.imagesrc;
        if (this.validateValue(value.source))
          overlay.layerOptions.source = value.source;
        if (this.validateValue(value.layer_name))
          overlay.layerOptions.layer_name = value.layer_name;
        if (this.validateValue(value.cql_filter))
          overlay.layerOptions.cql_filter = value.cql_filter;
        if (this.validateValue(value.subgroup))
          overlay.layerOptions.subgroup = value.subgroup;
        if (this.validateValue(value.visible))
          overlay.layerOptions.visible = value.visible;
        if (this.validateValue(value.group))
          overlay.layerOptions.group = value.group;
        if (this.validateValue(value.layeroptions_name))
          overlay.layerOptions.layers = value.layeroptions_name;
        if (this.validateValue(value.layeroptions_min_zoom))
          overlay.layerOptions.minZoom = value.layeroptions_min_zoom;
        if (this.validateValue(value.layeroptions_max_zoom))
          overlay.layerOptions.maxZoom = value.layeroptions_max_zoom;
        if (this.validateValue(value.layeroptions_pane)) {
          overlay.layerOptions.pane = value.layeroptions_pane;
        }
        if (this.validateValue(value.layeroptions_zindex)) {
          overlay.layerOptions.zIndex = value.layeroptions_zindex;
        }
        if (this.validateValue(value.layeroptions_show_on_selector) && !value.layeroptions_show_on_selector)
          overlay.layerOptions.showOnSelector = value.layeroptions_show_on_selector;
        if (this.validateValue(value.group_type)) {
          overlay.layerOptions.groupType = value.group_type
        }
        if (this.validateValue(value.data)) {
          overlay.layerOptions.data = value.data
        }
        if (this.validateValue(value.crs) && value.crs == "EPSG4326") {
          overlay.layerOptions.crs = L.CRS.EPSG4326;
        }
        if (this.validateValue(value.position)) {
          overlay.layerOptions.position = value.position;
        }
        if (value.type == this.gisMapConst.LAYER_TYPES['WMS_LAYER']) {
          overlay.layerOptions.type = this.gisMapConst.LAYER_TYPES['WMS_LAYER'];
          if (overlay.name.includes('Sentinel')) {
            overlay.key = overlay.name;
            overlays[overlay.name] = overlay;
          }
          else {
            overlay.key = key;
            overlays[key] = overlay;
          }
        }
        if (this.validateValue(value.layeroptions_styles)) {
          overlay.layerOptions.STYLES = value.layeroptions_styles;
        }
        if (this.validateValue(value.info)) {
          overlay.layerOptions.info = value.info
        }
      }
    }
    return overlays;
  }
  getVECTOROverlays(data, params?) {
    let overlays: any = {};
    for (const key in data) {
      let element = data[key];
      if (element.type == this.gisMapConst.LAYER_TYPES['VECTOR_LAYER']) {
        const overlay: any = {};
        overlay.type = 'vector';
        overlay.name = element.name;
        overlay.component = element.component;
        overlay.layerOptions = {};
        overlay.layerOptions.interactive = true;
        overlay.layerOptions.name = element.layer_name;
        overlay.layerOptions.rendererFactory = (L as any).canvas.tile;
        overlay.url = this.getKaleshwaramVectorLayerURL(element.layeroptions_name, overlay.layerOptions.cql_filter);
        if (this.validateValue(element.view))
          overlay.layerOptions.view = element.view;
        if (this.validateValue(element.pType))
          overlay.layerOptions.pType = element.pType;
        overlay.layerOptions.minZoom = element.layeroptions_min_zoom;
        overlay.layerOptions.maxZoom = element.layeroptions_max_zoom;
        overlay.layerOptions.pane = element.layeroptions_pane;
        overlay.layerOptions.visible = element.visible;
        overlay.layerOptions.type = this.gisMapConst.LAYER_TYPES['VECTOR_LAYER'];
        if (this.validateValue(element.source))
          overlay.layerOptions.source = element.source;
        if (this.validateValue(element.layeroptions_max_nativeZoom))
          overlay.layerOptions.maxNativeZoom = element.layeroptions_max_nativeZoom;
        if (this.validateValue(element.dashboardView))
          overlay.layerOptions.dashboardView = element.dashboardView;
        if (this.validateValue(element.hasLegend))
          overlay.layerOptions.hasLegend = element.hasLegend;
        if (this.validateValue(element.legendName))
          overlay.layerOptions.legendName = element.legendName;
        if (this.validateValue(element.parentView))
          overlay.layerOptions.parentView = element.parentView;
        if (this.validateValue(element.childView))
          overlay.layerOptions.childView = element.childView;
        overlay.layerOptions.group = element.group;
        overlay.layerOptions.displayName = element.displayName;
        if (this.validateValue(element.layeroptions_show_on_selector) && !element.layeroptions_show_on_selector)
          overlay.layerOptions.showOnSelector = element.layeroptions_show_on_selector;
        if (this.validateValue(element.group_type)) {
          overlay.layerOptions.groupType = element.group_type
        }
        overlay.layerOptions.vectorTileLayerStyles = {};

        if (element.layer_name == this.gisMapConst.LAYERNAMES_LIST[this.gisMapConst.LOCATIONS.DISTRICT]['Boundaries']) {
          overlay.layerOptions.vectorTileLayerStyles["corewris_kwaram_telangana_district_updated"] = {
            opacity: 0.75,
            weight: 0.5,
            color: "#000000",
            // fillColor: "#d5d5d6",
            // fill: true,
            // fillOpacity: 1,
          };
          overlay.layerOptions.getFeatureId = function (feature) {
            return feature.properties.loc_uuid;
          }
        }
        else if (element.layer_name == this.gisMapConst.LAYERNAMES_LIST[this.gisMapConst.LOCATIONS.MANDAL]['Boundaries']) {
          overlay.layerOptions.vectorTileLayerStyles["corewris_kwaram_ts_mandal"] = {
            opacity: 1,
            weight: 0.33,
            color: '#000000',
          };
          overlay.layerOptions.getFeatureId = function (feature) {
            return feature.properties.loc_uuid;
          }
        }
        else if (element.layer_name == this.gisMapConst.LAYERNAMES_LIST[this.gisMapConst.LOCATIONS.ALL]['Canal']) {
          overlay.layerOptions.vectorTileLayerStyles["tsripms_sitaram_maincanal_v2"] = {
            opacity: 0.8,
            weight: 3,
            color: '#2f82ee',
          };
          overlay.layerOptions.getFeatureId = function (feature) {
            return feature.properties.canal_name;
          }
        }
        if (element.type == this.gisMapConst.LAYER_TYPES['VECTOR_LAYER']) {
          overlay.key = element;
          overlays[key] = overlay;
        }
      }
    }
    return overlays;
  }
  getGEOJSONOverLays(data, layerName?) {
    let overlays = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        let overlay: any = {};
        overlay.name = value.name;
        overlay.type = this.gisMapConst.LAYER_TYPES['GEOJSON_LAYER'];
        overlay.layerOptions = {};
        overlay.layerOptions.type = this.gisMapConst.LAYER_TYPES['GEOJSON_LAYER'];
        overlay.layerOptions.name = value.name;
        overlay.layerOptions.format = 'image/png';
        overlay.layerOptions.transparent = true;
        if (this.validateValue(value.pType))
          overlay.layerOptions.pType = value.pType;
        if (this.validateValue(value.hasLegend))
          overlay.layerOptions.hasLegend = value.hasLegend;
        if (this.validateValue(value.legendName))
          overlay.layerOptions.legendName = value.legendName;
        if (this.validateValue(value.legendData))
          overlay.layerOptions.legendData = value.legendData;
        if (this.validateValue(value.component))
          overlay.layerOptions.component = value.component;
        if (this.validateValue(value.view))
          overlay.layerOptions.view = value.view;
        if (this.validateValue(value.parentView))
          overlay.layerOptions.parentView = value.parentView;
        if (this.validateValue(value.childView))
          overlay.layerOptions.childView = value.childView;
        if (this.validateValue(value.layer_name))
          overlay.layerOptions.layer_name = value.layer_name;
        if (this.validateValue(value.cqlfilter))
          overlay.layerOptions.cqlfilter = value.cqlfilter;
        if (this.validateValue(value.source))
          overlay.layerOptions.source = value.source;
        if (this.validateValue(value.clickZoom))
          overlay.layerOptions.clickZoom = value.clickZoom;
        if (this.validateValue(value.visible))
          overlay.layerOptions.visible = value.visible;
        if (this.validateValue(value.layerFrom))
          overlay.layerOptions.layerFrom = value.layerFrom;
        if (this.validateValue(value.includeAll))
          overlay.layerOptions.includeAll = value.includeAll;
        if (this.validateValue(value.style))
          overlay.layerOptions.style = value.style;
        if (this.validateValue(value.onEachFeature))
          overlay.layerOptions.onEachFeature = value.onEachFeature;
        if (this.validateValue(value.pointToLayer))
          overlay.layerOptions.pointToLayer = value.pointToLayer;
        if (this.validateValue(value.afterLayerAddition))
          overlay.layerOptions.afterLayerAddition = value.afterLayerAddition;
        if (this.validateValue(value.group))
          overlay.layerOptions.group = value.group;
        if (this.validateValue(value.layeroptions_name))
          overlay.layerOptions.layers = value.layeroptions_name;
        if (this.validateValue(value.layeroptions_min_zoom))
          overlay.layerOptions.minZoom = value.layeroptions_min_zoom;
        if (this.validateValue(value.layeroptions_max_zoom))
          overlay.layerOptions.maxZoom = value.layeroptions_max_zoom;
        if (this.validateValue(value.layeroptions_pane)) {
          overlay.layerOptions.pane = value.layeroptions_pane;
        }
        if (this.validateValue(value.layeroptions_zindex)) {
          overlay.layerOptions.zIndex = value.layeroptions_zindex;
        }
        if (this.validateValue(value.layeroptions_show_on_selector) && !value.layeroptions_show_on_selector)
          overlay.layerOptions.showOnSelector = value.layeroptions_show_on_selector;
        if (this.validateValue(value.group_type)) {
          overlay.layerOptions.groupType = value.group_type
        }
        if (this.validateValue(value.data)) {
          overlay.layerOptions.data = value.data
        }
        if (this.validateValue(value.crs) && value.crs == "EPSG4326") {
          overlay.layerOptions.crs = L.CRS.EPSG4326;
        }
        if (this.validateValue(value.position)) {
          overlay.layerOptions.position = value.position;
        }
        if (value.type == this.gisMapConst.LAYER_TYPES['GEOJSON_LAYER']) {
          overlay.key = key;
          overlays[key] = overlay;
        }
        if (this.validateValue(value.layeroptions_styles)) {
          overlay.layerOptions.STYLES = value.layeroptions_styles;
        }
        if (this.validateValue(value.info)) {
          overlay.layerOptions.info = value.info
        }
      }
    }
    if (layerName)
      return { [layerName]: overlays[layerName] };
    else
      return overlays;
  }
  setWMSOverlays(mapObject, wmsLayers, layersList?) {
    let layers = {};
    for (const key in wmsLayers) {
      if (wmsLayers.hasOwnProperty(key)) {
        const layer: any = wmsLayers[key];
        if (!layer.layerOptions.cql_filter)
          layer.layerOptions.cql_filter = '1=1';
        let wmslayer;
        if (key == 'SRTM_DEM_30') {
          wmslayer = new L.TileLayer.WMS(layer.url,
            {
              layers: layer.layerOptions.layers,
              format: "image/png",
              transparent: true,
              attribution: layer.layerOptions.attribution || "",
              maxNativeZoom: 18,
              minZoom: layer.layerOptions.minZoom,
              maxZoom: layer.layerOptions.maxZoom,
              pane: layer.layerOptions.pane,
              styles: layer.layerOptions.STYLES,
            }
          );
        }
        else {
          wmslayer = new L.TileLayer.WMS(layer.url,
            layer.layerOptions)
        }
        if (!isNullOrUndefined(wmslayer))
          layers[key] = wmslayer;
        if (layersList) {
          if (mapObject.hasLayer(layersList[key])) {
            mapObject.removeLayer(layersList[key]);
            delete layersList[key];
          }
        }
        if (layer.layerOptions.visible === true && !isNullOrUndefined(layers[key])) {
          mapObject.addLayer(layers[key]);
        }
      }
    }
    return layers;
  }
  setVECTOROverlays(mapObj, vectorLayer: any, layerList?: any) {
    const layers: any = {};
    for (const key in vectorLayer) {
      const layer = vectorLayer[key];
      const vectorTileLayer = new (L as any).vectorGrid.protobuf(layer.url, layer.layerOptions);
      layers[key] = vectorTileLayer;
      if (layer.layerOptions.visible == true) {
        if (layerList) {
          if (mapObj.hasLayer(layerList[key]))
            mapObj.removeLayer(layerList[key])
        }
        mapObj.addLayer(layers[key]);
      }
    }

    return layers;
  }
  setGEOJSONOverlays(mapObject, layerList, layerObjectsList, params, gisMapComp?): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let geoJsonOverlay = {};
      for (const key in layerList) {
        let filter = null;
        if (mapObject.hasLayer(layerObjectsList[key])) {
          mapObject.removeLayer(layerObjectsList[key]);
          delete layerObjectsList[key];
        }
        const val = layerList[key];
        if (val.type == 'GEOJSON' && val.layerOptions.layerFrom == this.gisMapConst.SOURCES['GEOSERVER']) {
          let layerName = val.layerOptions.layers.split('kaleshwaram:');
          if (val.layerOptions.cqlfilter) {
            filter = val.layerOptions.cqlfilter;
          }
          if (!isNullOrUndefined(layerName[1])) {
            let geodata = await this.getGeoJsonLayerData(layerName[1], filter);
            geoJsonOverlay[key] = await this.addGeoJsonLayersToMap(geodata, mapObject, val.layerOptions, params, gisMapComp);
          }
        }
        else if (val.type == 'GEOJSON' && val.layerOptions.layerFrom == this.gisMapConst.SOURCES['BACKEND']) {
          geoJsonOverlay[key] = await this[val.layerOptions.onEachFeature](val.layerOptions, mapObject, gisMapComp);
        }
        geoJsonOverlay[key].options = val.layerOptions;
        if (gisMapComp.activeLayers[key]) {
          geoJsonOverlay[key].options.visible = gisMapComp.activeLayers[key];

        }
        if (!isNullOrUndefined(mapObject) && !isNullOrUndefined(geoJsonOverlay[key]) &&
          this.checkZoomlevelsForGeoJSON(geoJsonOverlay[key].options, mapObject.getZoom()) && geoJsonOverlay[key].options.visible) {
          mapObject.addLayer(geoJsonOverlay[key]);
          gisMapComp.activeLayers[key] = true;
        }
      }
      resolve(geoJsonOverlay);
    });
  }

  checkZoomlevelsForGeoJSON(layeroptions, currentZoom) {
    if (layeroptions && layeroptions['maxZoom'] && layeroptions['minZoom'] && layeroptions['maxZoom'] >= currentZoom && layeroptions['minZoom'] <= currentZoom && layeroptions['visible']) return true;
    return false;
  }

  getGeoJsonLayerData(layerName, filter): Promise<any> {
    let response = {};
    let promise = new Promise((resolve, reject) => {
      this.getLayerWithFilter(layerName, filter).subscribe(async (geodata: GeoJSON.GeoJsonObject) => {
        if (geodata) {
          response = geodata;
        }
        resolve(response);
      });
      err => {
        console.log("Error Occurred", err);
        reject(response);
      }
    });
    return promise;
  }

  addGeoJsonLayersToMap(geoJsonData: GeoJSON.GeoJsonObject, mapObj: L.Map, layerOptions: any, params, gisMapComp) {
    let that = this;
    let geoJsonLayer = (L as any).geoJSON(geoJsonData, {
      pane: layerOptions.pane,
      cacheLayers: true,
      style: function (feature) {
        if (layerOptions.style) return that[layerOptions.style](feature);
      },
      pointToLayer: (feature, layer) => {
        if (layerOptions.pointToLayer) {
          return this[layerOptions.pointToLayer](feature, mapObj, gisMapComp);
        }
      },
      onEachFeature: (feature, layer) => {
        if (layerOptions.onEachFeature) {
          return this[layerOptions.onEachFeature](layer, mapObj, gisMapComp);
        }
      }
    });
    return geoJsonLayer;
  }
  stylingforGeoJson(feature) {
    return {
      // fillColor: '#15397e',
      fillColor: '#003399',
      opacity: 1,
      color: "#000000",
      fillOpacity: 1,
      weight: 1,
      pane: 'popUp'
    };
  }

  addStructurePolygonLayer(layer, map: L.Map, gisMapComp) {
    layer.setStyle({
      color: this.colorSymbolIdMapping[layer.feature.properties.SymbolID],
      fillColor: this.colorSymbolIdMapping[layer.feature.properties.SymbolID],
      fillOpacity: 1,
      opacity: 1,
      pane: 'structure',
      weight: 4
    });
    // layer.on({
    //   click: (e) => {
    //     // gisMapComp.mapSpinner = true;
    //     // if(e['sourceTarget']['feature']['properties']['PopupInfo'] == 'CON-1'){
    //     //   map.setView(e['latlng'],17);
    //     // }else{
    //     //         map.setView(e['latlng'],20);
    //     // }
    //   },
    //   mouseover :(e)=>{
    //     layer.setStyle(this.structurePolygonLayerStyleOnHover());
    //   },
    //   mouseout:(e)=>{
    //     layer.setStyle({
    //       color: this.colorSymbolIdMapping[layer.feature.properties.SymbolID],
    //       fillColor: this.colorSymbolIdMapping[layer.feature.properties.SymbolID],
    //       fillOpacity: 1,
    //       opacity: 1,
    //       pane: 'popUpGeoJson',
    //       weight: 4
    //     });
    //   },

    //   });
    // if(!isNullOrUndefined(map)){
    //   map.on({
    //     zoomend:(e:any)=>{
    //         gisMapComp.mapSpinner = false;
    //     }
    //   });
    // }
  }
  structurePolygonLayerStyleOnHover() {
    return {
      color: '#eac53a',
      fillColor: '#ffd63f',
      opacity: 1,
      fillOpacity: 0.5,
      pane: 'popUpGeoJson',
      weight: 1,
    }
  }
  addStructurePointLayer(feature, mapObject: L.Map, gisMapComp) {
    let concentricCircleLayer = new L.LayerGroup();
    let Radius = 0.75;
    var outerCircleOptions = {
      stroke: true,
      fill: true,
      color: "#ffffff",
      weight: 0.75,
      fillColor: "#ffffff",
      fillOpacity: 0.85,
      radius: 0.75,
      pane: "mask"
    };
    let markerData = feature['properties']
    if (markerData['discriptio']) {
      outerCircleOptions.fillColor = "#e889ff";
      outerCircleOptions.color = '#6e327d';
      outerCircleOptions["properties"] = feature.properties;
      markerData['structure'] = feature['properties']['discriptio'];
      let latlng: any = [feature['properties']['latitude'], feature['properties']['longitude']];
      const popup = R.responsivePopup({ hasTip: true, autoPan: false, minWidth: 150, pane: 'max', maxWidth: 200, closeButton: false })

      if (markerData['discriptio'] == 'PUMPHOUSE NO-I') {
        const newMarkerIcon: any = (L as any).icon({
          iconUrl: "assets//imgs/PH_red_horizontal.svg",
          iconSizeArray: [[45, 15], [45, 17], [46, 20], [10, 11], [11, 10], [60, 24], [70, 28], [70, 28], [70, 28], [75, 30]],
          iconAnchor: [12, 14],
          iconZoomArray: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
          pane: 'max',
          popupAnchor: [0, 0],
        });
        let mar = L.marker(latlng).setIcon(newMarkerIcon);
        mar['properties'] = feature.properties;
        mar.on({
          mouseover: (e) => {
            popup.setContent(this.getPopUpContent(markerData));
            popup.setLatLng(e['latlng'])
            mar.bindPopup(popup).openPopup();
          },
          click: (e) => {
            gisMapComp.mapSpinner = true;
            let data = e['sourceTarget']['properties'];
            mapObject.setView(latlng, 17);
            this.urlParams = {
              type: 'structure',
              structure: data['discriptio'],
              chainage: data['chainage'],
              lat: this.utilsService.fixedToDecimal(data['latitude'], 2),
              lng: this.utilsService.fixedToDecimal(data['longitude'], 2),
              component: 'gis',
              click: 'mapClick',
              locType: this.urlParams['locType'],
              sDate: this.urlParams['sDate'],
              eDate: this.urlParams['eDate'],
            };
            this.router.navigate(['tsripms', 'geoportal', 'gis', this.urlParams]);
            gisMapComp.mapSpinner = false;

          },
          mouseout: (e) => {
            mar.closePopup();
          }
        })
        let initialZoom = mapObject.getZoom();
        mapObject.on({
          zoomend: (e: L.LeafletEvent) => {
            let currentZoom = mapObject.getZoom();
            let size = (mapObject.getZoom()) * (14 / 6);
            let diff = initialZoom - currentZoom;
            if (diff > 0) {
              newMarkerIcon.options.iconSize = [size - 1, size - 1];
            } else if (diff < 0 && currentZoom > initialZoom) {
              newMarkerIcon.options.iconSize = [size + 1, size + 1];
            } else {
              newMarkerIcon.options.iconSize = [size + 1, size + 1];
            }
            gisMapComp.mapSpinner = false;
            mar.setIcon(newMarkerIcon)
          }
        })
        concentricCircleLayer.addLayer(mar);
      }
      else {
        let outerCircle = L.circleMarker(latlng, outerCircleOptions);
        outerCircle.on({
          click: (e) => {
            gisMapComp.mapSpinner = true;
            let data = e['sourceTarget']['options']['properties'];
            mapObject.setView(latlng, 19);
            this.urlParams = {
              type: 'structure',
              structure: data['discriptio'],
              chainage: data['chainage'],
              lat: this.utilsService.fixedToDecimal(data['latitude'], 2),
              lng: this.utilsService.fixedToDecimal(data['longitude'], 2),
              component: 'gis',
              click: 'mapClick',
              locType: this.urlParams['locType'],
              sDate: this.urlParams['sDate'],
              eDate: this.urlParams['eDate']
            };
            this.router.navigate(['tsripms', 'geoportal', 'gis', this.urlParams]);
            gisMapComp.mapSpinner = false;
          },
          mouseover: (e) => {
            popup.setContent(this.getPopUpContent(markerData));
            outerCircle.setStyle({ color: 'black', weight: 2 });
            popup.setLatLng(e['latlng'])
            outerCircle.bindPopup(popup).openPopup();
          },
          mouseout: (e) => {
            outerCircle.setStyle({ color: outerCircleOptions.color, weight: outerCircleOptions.weight });
            outerCircle.closePopup();
          }
        })

        if (!isNullOrUndefined(mapObject)) {
          let initialZoom = mapObject.getZoom();
          mapObject.on({
            zoomend: (e: L.LeafletEvent) => {
              let currentZoom = mapObject.getZoom();
              let diff = currentZoom - initialZoom;
              if (diff > 0)
                outerCircle.setRadius(this.getCircleMarkerRadius(currentZoom, Radius + 1))
              else if (diff < 0)
                outerCircle.setRadius(this.getCircleMarkerRadius(currentZoom, Radius - 2))
              else
                outerCircle.setRadius(Radius);
              gisMapComp.mapSpinner = false;
            }
          })
        }
        concentricCircleLayer.addLayer(outerCircle);
      }
    }
    return concentricCircleLayer;
  }

  addChainagePointLayer(feature, mapObject: L.Map, gisMapComp) {
    let concentricCircleLayer = new L.LayerGroup();
    let Ra = 0.5;
    var outerCircleRaOptions = {
      stroke: true,
      fill: true,
      color: "#ff3b3b",
      weight: 2,
      fillColor: "#ff3b3b",
      fillOpacity: 1,
      radius: 0.5,
      pane: "mask"
    };
    let markerData = feature['properties']

    if (markerData['category']) {
      outerCircleRaOptions["properties"] = feature.properties;
      outerCircleRaOptions.fillColor = "#ff3b3b"; // red
      outerCircleRaOptions.color = '#680c0c'; //maroon
      let latlng: any = [feature['properties']['latitude'], feature['properties']['longitude']];
      let outerCircleI = L.circleMarker(latlng, outerCircleRaOptions);

      const popup = R.responsivePopup({ hasTip: true, autoPan: false, minWidth: 150, pane: 'max', maxWidth: 200, closeButton: false })
      outerCircleI.on({
        click: (e) => {
          gisMapComp.mapSpinner = true;
          let data = e['sourceTarget']['options']['properties'];
          mapObject.setView(latlng, 19);
          this.urlParams = {
            type: 'chainage',
            chainage: data['name'],
            lat: this.utilsService.fixedToDecimal(data['latitude'], 2),
            lng: this.utilsService.fixedToDecimal(data['longitude'], 2),
            component: 'gis',
            click: 'mapClick',
            locType: this.urlParams['locType'],
            sDate: this.urlParams['sDate'],
            eDate: this.urlParams['eDate']
          };
          this.router.navigate(['tsripms', 'geoportal', 'gis', this.urlParams]);
          gisMapComp.mapSpinner = false;
        },
        mouseover: (e) => {
          popup.setContent(this.getPopUpContent(markerData, 'point'));
          outerCircleI.setStyle({ color: 'black', weight: 2 });
          popup.setLatLng(e['latlng'])
          outerCircleI.bindPopup(popup).openPopup();
        },
        mouseout: (e) => {
          outerCircleI.setStyle({ color: outerCircleRaOptions.color, weight: outerCircleRaOptions.weight });
          outerCircleI.closePopup();
        }
      })

      if (!isNullOrUndefined(mapObject)) {
        let initialZoom = mapObject.getZoom();
        mapObject.on({
          zoomend: (e: L.LeafletEvent) => {
            let currentZoom = mapObject.getZoom();
            let diff = currentZoom - initialZoom;
            if (diff > 0)
              outerCircleI.setRadius(this.getCircleMarkerRadius(currentZoom, Ra + 1))
            else if (diff < 0)
              outerCircleI.setRadius(this.getCircleMarkerRadius(currentZoom, Ra - 2))
            else
              outerCircleI.setRadius(Ra);
            gisMapComp.mapSpinner = false;
          }
        })
      }
      concentricCircleLayer.addLayer(outerCircleI);
    }
    return concentricCircleLayer;
  }
  getCircleMarkerRadius(zoomLevel: number, currentRadius) {
    return currentRadius * zoomLevel * 0.25;
  }

  addIssueLayer(layerOptions, mapObject, gisMapComp) {
    let concentricCircleLayer = new L.LayerGroup();
    const params = this.httpDataService.getAlertsParams(this.urlParams);
    return new Promise((resolve, reject) => {
      const promise1 = new Promise((resolve, reject) => {
        this.httpDataService.getAlertsData(params).subscribe((res) => {
          resolve(res);
        });
      });
      promise1.then((data: any) => {
        Object.keys(data).forEach((key: any) => {
          (data[key]).forEach((result: any) => {
            let latlng: any = [result['ilat'], result['ilon']];
            const newMarkerIcon: any = (L as any).icon({
              iconUrl: 'assets//imgs/pulse-gif-2.gif',
              className: 'blinking',
              iconSizeArray: [[47, 19], [60, 24], [70, 28], [70, 28], [70, 28], [75, 30]],
              iconAnchorArray: [[0, 8], [30, 24], [35, 28], [35, 14], [35, 14], [38, 15]],
              iconZoomArray: [14, 15, 16, 17, 18, 19],
              pane: 'max'
            });
            let mar = L.marker(latlng).setIcon(newMarkerIcon);
            mar['properties'] = result;
            mar.on({
              click: (e) => {
                gisMapComp.mapSpinner = true;
                let data = e['sourceTarget']['properties'];
                mapObject.setView(latlng, 17);
                this.urlParams = {
                  type: 'alert',
                  eUUID: data['esuid'],
                  component: 'gis',
                  click: 'alertClick',
                  locType: this.urlParams['locType'],
                  sDate: this.urlParams['sDate'],
                  eDate: this.urlParams['eDate']
                };
                this.router.navigate(['tsripms', 'geoportal', 'gis', this.urlParams]);
                gisMapComp.mapSpinner = false;

              }
            })
            let initialZoom = mapObject.getZoom();
            mapObject.on({
              zoomend: (e: L.LeafletEvent) => {
                let currentZoom = mapObject.getZoom();
                let size = (mapObject.getZoom()) * (14 / 6);
                let diff = initialZoom - currentZoom;
                if (diff > 0) {
                  newMarkerIcon.options.iconSize = [size - 1, size - 1];
                } else if (diff < 0 && currentZoom > initialZoom) {
                  newMarkerIcon.options.iconSize = [size + 1, size + 1];
                } else {
                  newMarkerIcon.options.iconSize = [size + 1, size + 1];
                }
                gisMapComp.mapSpinner = false;
                mar.setIcon(newMarkerIcon)
              }
            })
            concentricCircleLayer.addLayer(mar);
          })
        })
        if (concentricCircleLayer) {
          resolve(concentricCircleLayer);
        }
      })
    })
  }
  getPopUpContent(popupData, point?) {
    if (!point) {
      var htmlString = "<html><body>";
      htmlString += '<div class="popup-table-section">'
      htmlString += `<div class="card-body p-0">
   <table class="table table-text-sm table-dark-mis table-sm table-dark m-0 table-bordered text-nowrap text-center">`
      htmlString += `<tbody>
      <tr>
         <th>Structure</th>
         <td>${popupData['structure'] ? popupData['structure'] : '-'}</td>
       </tr>
       <tr >
         <th> Chainage </th>
         <td>${popupData['chainage'] ? popupData['chainage'] : '-'}</td>
       </tr>
       <tr >
         <th> Lat </th>
         <td>${this.utilsService.fixedToDecimal(popupData['latitude'], 2)}</td>
       </tr>
       <tr >
         <th> Lng </th>
         <td>${this.utilsService.fixedToDecimal(popupData['longitude'], 2)}</td>
       </tr>
   </table>
 </div>
 </div>`;
      return htmlString;
    }
    else {
      var htmlString = "<html><body>";
      htmlString += '<div class="popup-table-section">'
      htmlString += `<div class="card-body p-0">
   <table class="table table-text-sm table-dark-mis table-sm table-dark m-0 table-bordered text-nowrap text-center">`
      htmlString += `<tbody>
       <tr>
       <th> Chainage No </th>
       <td>${popupData['name'] ? popupData['name'] : '-'}</td>
     </tr>
       <tr >
       <th> Lat </th>
       <td>${this.utilsService.fixedToDecimal(popupData['latitude'], 2)}</td>
     </tr>
     <tr >
       <th> Lng </th>
       <td>${this.utilsService.fixedToDecimal(popupData['longitude'], 2)}</td>
     </tr>
   </table>
 </div>
 </div>`;
      return htmlString;
    }
  }

  validateValue(value) {
    if (isNullOrUndefined(value)) return false;
    return true;
  }
  getGeoServerURL(geo?) {
    let url = 'http://159.65.4.230/geoserver/kaleshwaram';
    if (geo)
      url = 'http://geoserver.vassarlabs.com/geoserver/VASSARLABS'
    return url;
  }

  getKaleshwaramVectorLayerURL(layerName, cqlFilter, layername?) {
    let url: String;
    url = this.gisMapConst.gisIPForKaleshwaram + '/geoserver/' + this.gisMapConst.gisKaleshwaramStoreName + '/gwc/service/wmts?layer=' + layerName + '&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=application/x-protobuf;type=mapbox-vector&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}';
    if (this.validateValue(cqlFilter)) {
      url += '&cql_filter=' + cqlFilter;
    }
    return url;
  }
  getLayerWithFilter(layerName, filter?, src?) {
    let layerURL
    layerURL = "http://159.65.4.230/geoserver/kaleshwaram/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=kaleshwaram:" + layerName + "&maxFeatures=5000000&outputFormat=application%2Fjson" as string;
    if (filter)
      layerURL += '&cql_filter=' + filter;

    return this.http.get<any>(layerURL);
  }
  activeFeatureDataId() {
    return this.activeFeatureData["external_i"];
  }
}
