import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-component-details',
  templateUrl: './component-details.component.html',
  styleUrls: ['./component-details.component.scss']
})
export class ComponentDetailsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToLandingPage(){
    this.router.navigate(['tsripms']);
  }

  goToDetails(type){
    this.router.navigate(['tsripms','projectDashboard',type]);
  }
}
