import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { MapConstantsModule } from './map-constants.module';
import * as L from 'leaflet';
import 'src/assets/mapLibrary/L.control.HtmlLegend.js';
import 'src/assets/mapLibrary/L.control.ZoomBar.js';
import { GisDataService } from './gis-data.service';
import { isNullOrUndefined } from 'util';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-gis-map',
  templateUrl: './gis-map.component.html',
  styleUrls: ['./gis-map.component.scss']
})
export class GisMapComponent implements OnInit {
  navigationSubscription: Subscription;
  urlParams: any;
  map: any;
  layers = {
    baselayers: {},
    overlaylayers: {}
  }
  activeLayers: any[] = [];
  newhtmlLegend: any;
  mapSpinner : boolean = true;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private gisMapConst: MapConstantsModule,
    private gisDataService: GisDataService,
    private utilsService : UtilsService) {

    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // to make map in the initial view when coming back from datatab
        this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
        this.gisMapConst.setSelectedParams(this.urlParams);
          if(!isNullOrUndefined(this.map) && this.urlParams['click'] != 'mapClick' && this.urlParams['click'] != 'alertClick'){
            this.map.fitBounds(this.gisMapConst.LOCATIONS_BBOX[this.gisMapConst.LOCATIONS['SITARAM']]);
            this.map.setZoom(this.map.getMinZoom());
            this.initializeOnStateLoad();
          }
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Present error to user
      }
    })
  }

  ngOnInit() {
    this.initMap();
    this.revalidateMap();
    this.addLegendControl();
    this.getAndSetBaseLayers();
    this.getAndSetCommonLayers();
    this.initializeOnStateLoad();
  }

  initMap() {
    this.map = (L.map as any)('mapid', this.gisMapConst.MAP_OPTIONS);
    this.map.fitBounds(this.gisMapConst.LOCATIONS_BBOX[this.gisMapConst.LOCATIONS['SITARAM']]);
    this.map.setZoom(this.map.getMinZoom());
    this.addZoomBar();
    this.createPanes();
    if (this.gisDataService.checkIsMobileView()) {
      this.setMapInMobileView();
    }
  }

  addZoomBar() {
    let zoomBarControl = new (L as any).control.zoomBar();
    zoomBarControl.addTo(this.map);
  }

  private createPanes() {
    this.gisMapConst.PANE_LIST.forEach(pane => {
      this.map.createPane(pane.name);
      this.map.getPane(pane.name).style.zIndex = pane.zindex;
    });
  }

  setMapInMobileView() {
    if (!isNullOrUndefined(this.map)) {
      this.map.fitBounds(this.gisMapConst.LOCATIONS_BBOX[this.gisMapConst.LOCATIONS['SITARAM']], true);
      this.map.setMinZoom(this.map.getZoom());
      this.map.dragging.enable();
    }
  }
  addLegendControl() {
    if (this.newhtmlLegend) {
      this.map.removeControl(this.newhtmlLegend);
      this.newhtmlLegend = {};
    }
    this.createLegend();
  }
  createLegend() {
    this.newhtmlLegend = this.gisDataService.createLegendControl();
    (this.map).addControl(this.newhtmlLegend);
  }
  initializeOnStateLoad(){
    this.revalidateMap();
  }

  getAndSetBaseLayers() {
    let tileLayers = this.gisDataService.addBaseTileLayers(this.gisMapConst.BASE_LAYER_LIST[this.gisMapConst.LAYER_TYPES.TILE_LAYER], this.map);
    let wms = this.gisDataService.getApplicationBasedLayer(this.gisMapConst.BASE_LAYER_LIST[this.gisMapConst.LAYER_TYPES.WMS_LAYER], this.gisMapConst.LAYER_TYPES.WMS_LAYER);
    this.layers.baselayers = Object.assign(tileLayers, this.gisDataService.setWMSOverlays(this.map, wms));
  }


  getAndSetCommonLayers() {
    let wmsLayersBasedOnView = this.gisDataService.getApplicationBasedLayer(this.gisMapConst.COMMON_LOCATION_LAYER[this.gisMapConst.LOCATIONS.ALL], this.gisMapConst.LAYER_TYPES.WMS_LAYER);
    wmsLayersBasedOnView = this.getCommonLayers(wmsLayersBasedOnView);
    this.layers.overlaylayers = Object.assign(this.layers.overlaylayers, this.gisDataService.setWMSOverlays(this.map, wmsLayersBasedOnView, this.layers.overlaylayers));

    let vectorLayersBasedOnView = this.gisDataService.getApplicationBasedLayer(this.gisMapConst.COMMON_LOCATION_LAYER[this.gisMapConst.LOCATIONS.ALL], this.gisMapConst.LAYER_TYPES.VECTOR_LAYER, this.urlParams);
    vectorLayersBasedOnView = this.getCommonLayers(vectorLayersBasedOnView);
    this.layers.overlaylayers = Object.assign(this.layers.overlaylayers, this.gisDataService.setVECTOROverlays(this.map, vectorLayersBasedOnView, this.layers.overlaylayers));

    let geoJsonLayersBasedOnView = this.gisDataService.getApplicationBasedLayer(this.gisMapConst.COMMON_LOCATION_LAYER[this.gisMapConst.LOCATIONS.ALL], this.gisMapConst.LAYER_TYPES.GEOJSON_LAYER);
    geoJsonLayersBasedOnView = this.getCommonLayers(geoJsonLayersBasedOnView);
    this.gisDataService.setGEOJSONOverlays(this.map, geoJsonLayersBasedOnView, this.layers.overlaylayers, this.urlParams, this).then((data) => {
      this.layers.overlaylayers = Object.assign({}, this.layers.overlaylayers, data);
      this.mapSpinner = false;
      this.addLegend();
    })

  }
  getCommonLayers(layerList) {
    let layers = {};
    for (let layerName in layerList) {
      if (layerList[layerName].layerOptions.parentView) {

        if (layerList[layerName].layerOptions.parentView.includes(this.urlParams.pType) ||
          layerList[layerName].layerOptions.parentView.includes(this.gisMapConst.LOCATIONS.ALL)) {
          if (layerList[layerName].layerOptions.type == this.gisMapConst.LAYER_TYPES['WMS_LAYER']) {
            if (layerList[layerName].layerOptions.view == this.gisMapConst.LOCATIONS.DISTRICT) {
              layerList[layerName].layerOptions.cql_filter = "uuid ='" + this.urlParams['pUUID'] + "'";
              if (this.gisDataService.checkIsMobileView()) {
                layerList[layerName].layerOptions.minZoom = layerList[layerName].layerOptions.minZoom - 2;
              }
            }
            //     else if (layerList[layerName].layerOptions.view == this.gisMapConst.LOCATIONS['ALL']) {
            //       layerList[layerName].layerOptions.cql_filter = this.gisDataService.getLocationWiseFilter(layerName);
            //     }

          }
          else if (layerList[layerName].layerOptions.type == this.gisMapConst.LAYER_TYPES['GEOJSON_LAYER']) {
            // layerList[layerName].layerOptions.cqlfilter = this.gisDataService.getLocationWiseFilter(layerName);
          }
          if (layerList[layerName].layerOptions.view.includes(this.urlParams.point) ||
            layerList[layerName].layerOptions.view.includes(this.urlParams.pType) ||
            layerList[layerName].layerOptions.view.includes(this.gisMapConst.LOCATIONS.ALL)) {
            layers[layerName] = layerList[layerName];
            this.activeLayers[layerName] = layerList[layerName].layerOptions.visible;
          }
        }
      }
    }
    return layers;
  }
  addLegend() {
    this.newhtmlLegend.clearLegend();
    for (const key in this.layers.overlaylayers) {
      let layer = this.layers.overlaylayers[key];
      if (layer.options.hasLegend) {
        let legendName = layer.options.legendName;
        let legend = this.getLegendData(legendName, layer);
        if (this.newhtmlLegend.hasLegend(legend))
          this.newhtmlLegend.addLegend(legend);
      }
    }
  }
  getLegendData(legendName, layerObj) {
    let legendData = this.gisMapConst.LEGEND_DATA[legendName];
    if (!legendData) return;
    let legend = {
      name: legendData.title,
      layer: layerObj,
      elements: []
    }
    if (legendData.levels && legendData.levels.length > 0) {
      let html = ``;
      for (let key = 0; key < legendData.levels.length; key++) {
        html += `<p style='text-align:center;height:20px;margin:0px;padding:4px;color:black;background-color: ${legendData.levels[key].color}'>${legendData.levels[key].name}</p>`;
      }
      legend['elements'].push({ html: html });
    }
    return legend;
  }

  revalidateMap(): void {
    window.dispatchEvent(new Event('resize'));
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 200)
    }
  }

  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }

}
