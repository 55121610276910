export const tableConst = {
            tableInfo: {
              type: 'dataTable',
              title: '',
            },
            css: {
              tableClass: 'table table-bordered table-striped align-items-center',
              theadClass: 'thead-dark text-center'
            },
            sortOptions: {
              sortIndex: -1,
              sortType: 'asc'
            },
            searchFilter: false,
            breadcrumbCons: {
              show: false,
              queryparams: []
            },
            downloadButton: {
              display: false,
              text: 'Download',
              align: 'right',       //where to align the element within div i.e left, center or right
              order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
            },
            downloadOptions: [{
              showDownload: false,
              downloadType: 'xlsx',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: false //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            },
            {
              showDownload: false,
              downloadType: 'pdf',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: false //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            }
            ],
            paginatorOptions: {
              visible: false,
              limit: 15,
              maxSize: 5,     // Max pages to display on the link
              dropdownOptions: [{
                value: 15,
                display: '15'
              },
              {
                value: 25,
                display: '25'
              },
              {
                value: 50,
                display: '50'
              },
              {
                value: -1,
                display: 'All'
              }]
            },
            tableHeaderFixerInfo: {
              headerFix: true,
              colFix: false,
              noOfCol: 2
            },
            indexCons: {
              show: true,
              class: 'tc-bg-01'
            },
            columns: [
              {
                name: 'Activity',
                jsonKey: {
                  path: 'name'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              }, 
              {
                name: 'Unit',
                jsonKey: {
                  path: 'unit'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              },
              {
                name: 'Completed Quantity',
                class: 'tc-bg-05',
                childs: [
                  {
                    name: 'Yesterday',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'ystdayTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'ystdayCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      }
                    ]
                  },
                  {
                    name: 'Fortnight',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'fortnightTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'fortnightCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      }
                    ]
                  },
                  {
                    name: 'Current Month',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'currMonthTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'currMonthCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      }
                    ]
                  },
                  {
                    name: 'Cumulative (as on Date)',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'cummOnDateTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'cummOnDateCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'
    
                      }
                    ]
                  },
                ]
              },
              {
                name: 'Balance Quantity',
                jsonKey: {
                  path: 'balQty'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              },
              {
                name: 'Balance Quantity %',
                jsonKey: {
                  path: 'balQtyPer'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              },
            ]
          };
