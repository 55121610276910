import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SharedConstantsModule {
  LOC_CONSTANT = ['PROJECT','PACKAGE','ACTIVITY'];
  UUID_MAPPING = {
    'PACKAGE1': 'f69ad186-ee5a-4886-9e7b-074bd3f23894',
    'PACKAGE2': '9d3e3478-9e82-48c0-a452-0fd7c184099f',
    'PACKAGE3': 'e0534f39-3367-4514-9037-26483ed928e6',
    'PACKAGE4': '6c78a244-7a64-42e6-8629-6922894b575f',
    'PACKAGE5': '003e134f-5ca1-4036-895f-870b0e1dc7a3',
    'PACKAGE6': 'ce7a03da-40cf-429b-8292-ef4aab67f5a8',
    'PACKAGE7': '54bfd646-ec90-4ab9-97a5-892425425870',
    'PACKAGE8': '209a1e49-0e2d-4cc7-b6c6-825165b983b0',
    'PROJECT': '932cff74-cc1f-4d47-b222-c2802abd986c'
  };
    packageUUIDMapping ={
    'f69ad186-ee5a-4886-9e7b-074bd3f23894': 'P1',
    '9d3e3478-9e82-48c0-a452-0fd7c184099f': 'P2',
    'e0534f39-3367-4514-9037-26483ed928e6': 'P3',
    '6c78a244-7a64-42e6-8629-6922894b575f': 'P4',
    '003e134f-5ca1-4036-895f-870b0e1dc7a3': 'P5',
    'ce7a03da-40cf-429b-8292-ef4aab67f5a8': 'P6',
    '54bfd646-ec90-4ab9-97a5-892425425870': 'P7',
    '209a1e49-0e2d-4cc7-b6c6-825165b983b0': 'P8',
  };
  packageFullNameUUID={
    'f69ad186-ee5a-4886-9e7b-074bd3f23894': 'PACKAGE1',
    '9d3e3478-9e82-48c0-a452-0fd7c184099f': 'PACKAGE2',
    'e0534f39-3367-4514-9037-26483ed928e6': 'PACKAGE3',
    '6c78a244-7a64-42e6-8629-6922894b575f': 'PACKAGE4',
    '003e134f-5ca1-4036-895f-870b0e1dc7a3': 'PACKAGE5',
    'ce7a03da-40cf-429b-8292-ef4aab67f5a8': 'PACKAGE6',
    '54bfd646-ec90-4ab9-97a5-892425425870': 'PACKAGE7',
    '209a1e49-0e2d-4cc7-b6c6-825165b983b0': 'PACKAGE8',
  };
    tablePackageList = ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'];
  tablePackageNameMapping = {
    'PACKAGE1': 'PACKAGE 1',
    'PACKAGE2': 'PACKAGE 2',
    'PACKAGE3': 'PACKAGE 3',
    'PACKAGE4': 'PACKAGE 4',
    'PACKAGE5': 'PACKAGE 5',
    'PACKAGE6': 'PACKAGE 6',
    'PACKAGE7': 'PACKAGE 7',
    'PACKAGE8': 'PACKAGE 8',
  };
  tablePackageNameMappingDup = {
    'PACKAGE1': 'Package 1',
    'PACKAGE2': 'Package 2',
    'PACKAGE3': 'Package 3',
    'PACKAGE4': 'Package 4',
    'PACKAGE5': 'Package 5',
    'PACKAGE6': 'Package 6',
    'PACKAGE7': 'Package 7',
    'PACKAGE8': 'Package 8',
  };
  telanganaUUID = '927b67e9-a2aa-46e9-99bc-41cf8c502668';
  projectUUID = '932cff74-cc1f-4d47-b222-c2802abd986c';
 }
