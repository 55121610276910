import { Injectable } from '@angular/core';
import { Observable, of, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import {  CookieService  } from 'ngx-cookie-service'; 
import { ConstantsModule } from '../constants/constants.module';
import { AuthloginService } from './authlogin.service';
import { UtilsService } from './utils.service';
import { HttperrorhandlerService } from './httperrorhandler.service';
import { LogoutPopupComponent } from 'src/app/logout-popup/logout-popup.component';


@Injectable({
  providedIn: 'root'
})
export class SubmitdataService {
  activespineer: boolean;
  activeModal: boolean = false;
  constructor(private http: HttpClient,
    private constants: ConstantsModule,
    private errorHandler: HttperrorhandlerService,
    private utils: UtilsService,
    private modalService : NgbModal,private router: Router,
    private sessionlogin : AuthloginService,
    private cookie : CookieService) { }


 
  getStatus(option){
    let d = new Date();
    let n = d.getTime();
    if(!this.activeModal){
      if(option < n){
        this.activeModal = true;
        let modalRef = this.modalService.open(LogoutPopupComponent,{ backdrop: 'static',windowClass: 'backgroundTransparent  my-class'});
        modalRef.componentInstance.title = 'Session Expired';
        modalRef.componentInstance.onLogout.subscribe((receivedEntry) => {
        if(receivedEntry === true){
          this.activespineer = true;
          let url  = this.router.url; 
          let optionLogout = this.sessionlogin.checkloginstatus();
          this.sessionlogin.logoutuser(optionLogout).subscribe(logoutres =>{
            this.activespineer = false;
            this.activeModal = false;
            if(logoutres['result']['success']){
              this.cookie.delete('ACCESS_TOKEN');
              this.cookie.delete('ROLE');
              this.cookie.delete('USERID');
              this.cookie.delete('USER_INFO');
              this.cookie.delete('USER_DETAILS_INFO');
              this.sessionlogin.clearusersession();
              if(url.search('submitdata') != -1){
                this.router.navigate(["/gis"]);
                setTimeout(() => { location.reload(true); }, 10);
              }else{
                setTimeout(() => { location.reload(true); }, 10);
              }
            }
          
          })
        }
      })
      }else{
      }
    }
  }
  sortStates(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
  getWritableObject(obj: object): object {
    if (obj == null || obj == undefined) return obj;

    let objectConfig = {};
    for (let key in obj) {
      objectConfig[key] = obj[key];
    }

    return objectConfig;
  }
 

}