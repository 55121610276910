import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { isNullOrUndefined } from 'util';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable()
export class ExcelService {
  firstFlag: any = true;

  constructor() { }

  public downloadFile(downloadOptions: object, headers: any[], data: any[], staticRowsData: any[], showOnView: boolean, headerCons: object[], metaData: object) {
    let downloadType: string = downloadOptions['downloadType'];
    let downloadFileName: string = downloadOptions['downloadFileName'];
    let meta: boolean = downloadOptions['metaData'];
    if (downloadType == 'xls' || downloadType == 'xlsx') {
      this.exportAsExcelFile(data, staticRowsData, showOnView, headers, downloadFileName, meta, metaData);
    }
    else if (downloadType == 'pdf') {
      this.generatePdf(data, staticRowsData, showOnView, headers, downloadFileName, meta, metaData);
    }
    else if (downloadType == 'csv') {
      this.exportTableToCSV(data, staticRowsData, showOnView, headers, downloadFileName);
    }
    else {
      this.downloadAsJson(headerCons, data, downloadFileName)
    }

  }

  private exportAsExcelFile(data: any[], staticRowsData: any[], showOnView: boolean, headers: any[], excelFileName: string, meta: boolean, metaData: object) {
    let excelBuffer = this.setExcelData(headers, data, staticRowsData, showOnView, excelFileName, meta, metaData);
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(excelData: any, fileName: string): void {
    let blob = new Blob([excelData], { type: "application/vnd.ms-excel;charset=utf-8" });
    FileSaver.saveAs(blob, fileName + '_' + new Date().getTime() + '.xls');
  }

  private setExcelData(headers, recordData, staticRowsData, showOnView, xlsfilename, meta, metaData) {
    xlsfilename += '.xls;'
    let tableHTML = this.createNewTable(headers, recordData, staticRowsData, showOnView, meta, metaData);
    let excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
    excelFile += "<head>";
    excelFile += "<!--[if gte mso 9]>";
    excelFile += "<xml>";
    excelFile += "<x:ExcelWorkbook>";
    excelFile += "<x:ExcelWorksheets>";
    excelFile += "<x:ExcelWorksheet>";
    excelFile += "<x:Name>";
    excelFile += xlsfilename;
    excelFile += "</x:Name>";
    excelFile += "<x:WorksheetOptions>";
    excelFile += "<x:DisplayGridlines/>";
    excelFile += "</x:WorksheetOptions>";
    excelFile += "</x:ExcelWorksheet>";
    excelFile += "</x:ExcelWorksheets>";
    excelFile += "</x:ExcelWorkbook>";
    excelFile += "</xml>";
    excelFile += "<![endif]-->";
    excelFile += "</head>";
    excelFile += "<body>";
    excelFile += tableHTML;
    excelFile += "</body>";
    excelFile += "</html>";

    return excelFile;
  }

  private createNewTable(headers, recordData, staticRowsData, showOnView, meta, metaData) {
    let numberOfRows = recordData.length;
    let totalColumns = recordData[0].length;

    if (showOnView && this.firstFlag) {
      for (let i = 0; i < staticRowsData.length; i++) {
        let item = staticRowsData[i];
        Object.keys(item).forEach((key) => {
          recordData.splice(Number(key), 0, item[key]);
        })
      }
      this.firstFlag = false;
    }

    let tableForDownload = "";
    if (meta) {
      tableForDownload += this.addMetaData(metaData);
    }
    tableForDownload += "<table><thead>";

    headers.forEach((header) => {
      tableForDownload += "<tr>";
      header.forEach(record => {
        tableForDownload += '\t\t\t\t<th colspan="' + record.cols + '" rowspan="' + record.rows + '">' + record.name + '</th>';
      });
      tableForDownload += '</tr>';
    })
    tableForDownload += '</thead>\n<tbody>\n';

    if (recordData.length > 0) {
      for (let row = 0; row < numberOfRows; row++) {
        tableForDownload += '\t\t\t<tr>\n';
        let item = recordData[row];
        if (typeof (item) == 'string') {
          tableForDownload += '\t\t\t\t<td>' + item + '</td>' + '\n';
        } else {
          for (let column = 0; column < totalColumns; column++) {
            let recordValue = recordData[row][column];
            if (recordValue) {
              let cellValue = recordData[row][column].values[0];
              tableForDownload += '\t\t\t\t<td>' + ((cellValue != null && cellValue != undefined) ? cellValue : '-') + '</td>' + '\n';
            }
            else {
              tableForDownload += '<td>-</td>';
            }
          }
        }
        tableForDownload += '\t\t\t</tr>\n';
      }
    } else {
      tableForDownload += '\t\t\t<tr>\n<td>No data to display!</td></tr>';
    }
    return tableForDownload += '</tbody><table>';
  }

  private exportTableToCSV(recordData, staticRowsData, showOnView, headers, filename) {
    let csvs = [];
    let rowArray = [];
    let startIndex = 0;
    let headerArray = [];

    for (let i = 0; i < headers[0].length; i++) {
      let headerValue = headers[0][i];
      if (headerValue.cols == 1) {
        headerArray.push(headerValue.name);
      }
      else {
        getHeaderName(headers, 1, startIndex, startIndex + headerValue.cols, headerValue.name);
        startIndex += headerValue.cols;
      }
    }

    function getHeaderName(header: object, headerIndex: number, index: number, columns: number, baseName: string) {
      for (let i = index; i < columns; i++) {
        if (header[headerIndex][i].cols == 1) {
          headerArray.push(baseName + '.' + header[headerIndex][i].name);
        }
        else {
          getHeaderName(headers, headerIndex + 1, index, index + header[headerIndex][i].cols, baseName + '.' + header[headerIndex][i].name);
          startIndex += header[headerIndex][i].cols;
        }
      }
    }

    let header = headerArray.join(',');
    rowArray.push(header);

    if (showOnView && this.firstFlag) {
      for (let i = 0; i < staticRowsData.length; i++) {
        let item = staticRowsData[i];
        Object.keys(item).forEach((key) => {
          recordData.splice(Number(key), 0, item[key]);
        })
      }
      this.firstFlag = false;
    }
    for (let i = 0; i < recordData.length; i++) {
      let rows = [], cols = recordData[i].length;
      let item = recordData[i];
      if (typeof (item) == 'string') {
        rows.push(item);
        rowArray.push(rows);
      } else {
        for (let j = 0; j < cols; j++) {
          let recordValue = recordData[i][j];
          if (recordValue) {
            let cellValue = recordData[i][j].values[0];
            // let cellValue = recordValue.dataType.length == 1 && recordValue.values.length == 1 ? recordData[i][j].values : recordData[i][j].values.join(':');            
            let pushValue = (cellValue != null && cellValue != undefined) ? cellValue : '-';
            rows.push(pushValue);
          }
          else {
            rows.push('-');
          }
        }
        let row = rows.join(",");
        rowArray.push(row);
      }
    }
    csvs.push(rowArray.join("\n"));
    this.downloadCSV(csvs, filename);
  }

  private downloadCSV(csv, filename) {
    let csvFile = new Blob([csv], { type: "text/csv" });
    FileSaver.saveAs(csvFile, filename + '_' + new Date().getTime() + '.csv');

  }

  private downloadAsJson(headers, data, filename) {
    let finalObject = []
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let o = this.setDataObject(headers, data[i], 0);
        finalObject.push(o);
      }
    }
    let jsonString = JSON.stringify(finalObject);
    let jsonFile = new Blob([jsonString], { type: "text" });
    FileSaver.saveAs(jsonFile, filename + '_' + new Date().getTime() + '.json');
  }

  private setDataObject(headers, record, index) {
    let jsonObject: Object = {};
    let ind = index;
    headers.forEach((header) => {
      if (!isNullOrUndefined(header.childs) && header.childs.length > 0) {
        jsonObject[header.name] = this.setDataObject(header.childs, record, ind);
      }
      else {
        jsonObject[header.name] = record[ind].values;
        ind++;
      }
    });
    return jsonObject;
  }

  private addMetaData(metaData) {
    let metaEntries = Object.entries(metaData);
    var mData = '<table><thead><tr><th>\t\t\t\t\t</th><th>\t\t\t\t\t</th>';
    if(metaEntries.length)
    mData += "<th colspan = '6'>" + metaData + "</th></tr>"
    // metaEntries.forEach(entry => {
    //   mData += "<tr><th>" + entry[0] + "</th><th>" + entry[1] + "</th></tr>";
    // })
    mData += "<tr><th></th><th></th></tr>";
    mData += "</thead></table>";
    return mData;
  }

  generatePdf(data: any[], staticRowsData: any[], showOnView: boolean, headers: any[], excelFileName: string, meta: boolean, metaData: object) {
    if (showOnView && this.firstFlag) {
      for (let i = 0; i < staticRowsData.length; i++) {
        let item = staticRowsData[i];
        Object.keys(item).forEach((key) => {
          data.splice(Number(key), 0, item[key]);
        })
      }
      this.firstFlag = false;
    }
    let noOfCols;
    if (showOnView) {
      noOfCols = data[1].length;
    } else {
      noOfCols = data[0].length;
    }
    let widths = [];
    for (let i = 0; i < noOfCols; i++) {
      widths.push('*');
    }
    let tableBody = [];
    let j = 0;
    let noOfHeaderRows = headers.length - 1;
    headers.forEach(h => {
      let row = [];
      let i = 0;
      let flag = true;
      let prevCol = {};
      h.forEach(col => {
        if (flag) {
          while (i < col.sortIndex) {
            row.push("");
            i = i + 1;
          }
          flag = false;
        }
        if (j == noOfHeaderRows && !flag) {
          if (col['sortIndex'] != prevCol['sortIndex'] + 1) {
            let diff = col['sortIndex'] - prevCol['sortIndex'];
            while (diff > 1) {
              row.push("");
              i = i + 1;
              diff = diff - 1;
            }
          }
        }
        let o = {};
        o['text'] = col.name;
        o['rowSpan'] = col.rows;
        o['colSpan'] = col.cols;
        o['alignment'] = 'center';
        o['style'] = 'tableHeader';
        row.splice(i, 0, o);
        let k = i + 1;
        while (k < i + col.cols) {
          row.push("");
          k = k + 1;
        }
        i = i + col.cols;
        prevCol = col;
      })
      while (i < noOfCols) {
        row.push("");
        i = i + 1;
      }
      tableBody.push(row);
      j = j + 1;
    })

    data.forEach(d => {
      let row = [];
      if (typeof (d) == 'string') {
        let o = {};
        o['text'] = d;
        o['alignment'] = 'left';
        o['colSpan'] = noOfCols
        row.push(o);
      } else {
        d.forEach(col => {
          let o = {};
          o['text'] = col.values[0];
          o['alignment'] = 'center';
          row.push(o);
        })
      }
      tableBody.push(row);
    })
    let obj = {
      table: {
        widths: widths,
        body: tableBody
      }
    }
    let metaObj = [];
    if(Object.entries(metaData).length){
    let object = {};
    object['text'] = metaData + "\n\n";
    object['style'] = 'subheader';
    metaObj.push(object);
    }
    // let metaEntries = Object.entries(metaData);
    // metaEntries.forEach(entry => {
    //   let obj = {}
    //   obj['text'] = entry[0] + " - " + entry[1] + "\n\n";
    //   obj['style'] = 'subheader';
    // })
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const documentDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A2',
      content: [{ text: excelFileName + "\n\n", alignment: 'center', style: 'header' },
      {text: metaObj , alignment: 'center', style: 'subheader'}, obj,
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 15,
          bold: true
        },
        tableHeader: {
          fillColor: '#478ffe',
          color: '#fff',
          bold: true
        },
      }
    };
    pdfMake.createPdf(documentDefinition).download(excelFileName + ".pdf");
  }
}
