import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  urlParams: any;
  constructor(private router: Router,  private cookie : CookieService,) { 
    //*******  FOR SETTING PREVIOUS THEME when back to landing page **********//
    // if(localStorage.getItem('theme') == 'body-bg-dark'
    //*******  FOR SETTING WHITE THEME when back to landing page **********//
      localStorage.setItem('theme','body-bg-dark light');
  }

  ngOnInit() {
  }

  goToDashboard(type){    
    if(this.cookie.get('loggedIn')=='true'){
      this.router.navigate(['tsripms',type]);
    }else{
      this.router.navigate(['/login']);
    }
    // this.router.navigate(['tsripms',type]);
  }

  goToLandingPage(){
    this.router.navigate(['tsripms']);
  }

  login(){
    this.router.navigate(['tsripms','login']);
  }

}
