import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/user-management.service';
import { EncdecService } from 'src/app/services/encdec.service';
import { ConstantsModule } from 'src/app/constants/constants.module';
@Component({
  selector: 'app-image-expand-modal',
  templateUrl: './image-expand-modal.component.html',
  styleUrls: ['./image-expand-modal.component.scss']
})
export class ImageExpandModalComponent implements OnInit {

  constructor(
    private userService: UserManagementService,
    private route: ActivatedRoute,
    private modal: NgbActiveModal,
    private toastrService: ToastrService,
    private encdecservice : EncdecService,
    private constant : ConstantsModule
  ) { }
  imgPath : any ;
  ngOnInit() {
  }
  closeModal() {
    this.modal.dismiss();
  }

}
