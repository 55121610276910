import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit,OnChanges {

  @Input('fieldName') fieldName: string;
  @Input('ctrl') ctrl: FormControl;
  @Input('showError') showError: boolean;
  @Input('patternErrMsg') patternErrMsg: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}