import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StackbarService {

    targetOR = '98%';
    targetIR = '83%';
    completedOR = '115%';
    completedIR = '100%';
    totalIR = '117%';
    totalOR = '132%';
    targetColor: string = '#fcfcfa';
    completedColor: string = '#fcfcfa';
    totalColor: string = '#fcfcfa';
    constructor() { }

    getLandProgressChart(object, type, unitType) {
        let options: object = {
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent',
                type: type
            },
            title: {
                text: '',
                style: {
                    color: 'white'
                }
            },
            subtitle: {
                // text: ''
            },
            xAxis: [{
                categories: ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'],
                crosshair: true,
                tickInterval: 0,
                labels: {
                    style: {
                        color: 'white',
                        fontSize: '18px'
                    }
                },
                plotLines: [{
                    color: 'white', // Color value
                    width: 0.90,
                    zIndex: 3,
                    dashStyle: 'ShortDash'
                }]

            }],
            yAxis: [
                {
                    gridLineColor: 'rgba(255, 255, 255, 0.1)',
                    title: {
                        text: unitType,
                        margin: 30,
                        style: {
                            color: 'white',
                            fontSize: '20px',
                            fontWeight : 'bold'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'white',
                            fontSize: '18px'
                        }
                    }
                },
            ],
            tooltip: {
                shared: true,
                valueDecimals: 0,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.0f}',
                        style: {
                            color: 'white',
                            fontSize: '18px',
                            textOutline:'0px'
                        }
                    }
                },
                series: {
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '15px'
                }
            },
            series: object,
            exporting: {
                enabled: false,
            },

            credits: {
                enabled: false
            }

        }
        return options;
    }
    getFinancialProgressChart(object, type) {
        let options: object = {
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent',
                type: type
            },
            title: {
                text: '',
                style: {
                    color: 'white'
                }
            },
            subtitle: {
                // text: ''
            },
            xAxis: [{
                categories: ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'],
                crosshair: true,
                tickInterval: 0,
                labels: {
                    style: {
                        color: 'white',
                        fontSize: '20px'
                    }
                },
                plotLines: [{
                    color: 'white', // Color value
                    width: 0.90,
                    zIndex: 3,
                    dashStyle: 'ShortDash'
                }]

            }],
            yAxis: [
                {
                    gridLineColor: 'rgba(255, 255, 255, 0.1)',
                    title: {
                        text : 'Cr',
                        margin: 30,
                        style: {
                            color: 'white',
                            fontSize: '20px',
                            fontWeight : 'bold'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'white',
                            fontSize: '18px'
                        }
                    },
                },
            ],
            tooltip: {
                shared: true,
                valueDecimals: 0,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.0f}',
                        style: {
                            color: 'white',
                            fontSize: '18px',
                            textOutline:'0px'
                        }
                    }
                },
                series: {
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '15px'
                                 }
            },
            series: object,
            exporting: {
                enabled: false,
            },

            credits: {
                enabled: false
            }

        }
        return options;
    }
    getPhysicalAreaChart(object, type) {
        let options: object = {
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent',
                type: type
            },
            title: {
                text: '',
                style: {
                    color: 'white'
                }
            },
            subtitle: {
                // text: ''
            },
            xAxis: [{
                categories: ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'],
                crosshair: true,
                tickInterval: 0,
                labels: {
                    style: {
                        color: 'white',
                        fontSize: '18px'
                    }
                },
                plotLines: [{
                    color: 'white', // Color value
                    width: 0.90,
                    zIndex: 3,
                    dashStyle: 'ShortDash'
                }]

            }],
            yAxis: [
                {
                    gridLineColor: 'rgba(255, 255, 255, 0.1)',
                    title: {
                        text: 'Percentage (%)',
                        margin: 30,
                        style: {
                            color: 'white',
                            fontSize: '20px',
                            fontWeight : 'bold'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'white',
                            fontSize: '18px'
                        }
                    }
                },
            ],
            tooltip: {
                shared: true,
                valueDecimals: 0,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.0f}',
                        style: {
                            color: 'white',
                            fontSize: '18px',
                            textOutline:'0px'
                        }
                    }
                },
                series: {
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '15px'
                }
            },
            series: object,
            exporting: {
                enabled: false,
            },

            credits: {
                enabled: false
            }

        }
        return options;
    }
    getActivityAreaChart(object, type) {
        let options: object = {
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent',
                type: type
            },
            title: {
                text: '',
                style: {
                    color: 'white'
                }
            },
            subtitle: {
                // text: ''
            },
            xAxis: [{
                categories: ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'],
                crosshair: true,
                tickInterval: 0,
                labels: {
                    style: {
                        color: 'white',
                        fontSize: '18px'
                    }
                },
                plotLines: [{
                    color: 'white', // Color value
                    width: 0.90,
                    zIndex: 3,
                    dashStyle: 'ShortDash'
                }]

            }],
            yAxis: [
                {
                    gridLineColor: 'rgba(255, 255, 255, 0.1)',
                    title: {
                        text: '',
                        margin : 20,
                        style: {
                            color: 'white',
                            fontSize: '18px',
                            fontWeight : 'bold'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'white',
                            fontSize: '18px'
                        }
                    }
                },
            ],
            tooltip: {
                shared: true,
                valueDecimals: 0,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.0f}',
                        style: {
                            color: 'white',
                            fontSize: '16px',
                            textOutline:'0px'
                        }
                    }
                },
                series: {
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '15px'
                }
            },
            series: object,
            exporting: {
                enabled: false,
            },

            credits: {
                enabled: false
            }

        }
        return options;
    }
    getProjectProgressChart(object, type) {
        let options: object = {
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent',
                type: type
            },
            title: {
                text: '',
                style: {
                    color: 'white'
                }
            },
            subtitle: {
                // text: ''
            },
            xAxis: [{
                categories: ['Package 1', 'Package 2', 'Package 3', 'Package 4', 'Package 5', 'Package 6', 'Package 7', 'Package 8'],
                crosshair: true,
                tickInterval: 0,
                labels: {
                    style: {
                        color: 'white',
                        fontSize: '18px'
                    }
                },
                plotLines: [{
                    color: 'white', // Color value
                    width: 0.90,
                    zIndex: 3,
                    dashStyle: 'ShortDash'
                }]

            }],
            yAxis: [
                {
                    gridLineColor: 'rgba(255, 255, 255, 0.1)',
                    title: {
                        text: 'Percentage (%)',
                        margin: 30,
                        style: {
                            color: 'white',
                            fontSize : '20px',
                            fontWeight : 'bold'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'white',
                            fontSize: '15px'
                        }
                    }
                },
            ],
            tooltip: {
                shared: true,
                valueDecimals: 0,
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:,.0f}',
                        style: {
                            color: 'white',
                            fontSize: '18px',
                            textOutline:'0px'
                        }
                    }
                },
                series: {
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '15px'
                }
            },
            series: object,
            exporting: {
                enabled: false,
            },

            credits: {
                enabled: false
            }

        }
        return options;
    }

    getProjectIssuesChart(object) {
        let options = {
            chart: {
                // plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: 'transparent'
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: 'Issues',
                y: 10,
                style: {
                    color: 'white'
                }
            },
            tooltip: {
                pointFormat: '{point.y}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    innerSize: 150,
                    borderWidth: 0,
                    // allowPointSelect: true,
                    // cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '18px'
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [{
                // name: 'Brands',
                // colorByPoint: true,
                data: object
            }]
        }
        return options;
    }

    getBalancePendingChart(object) {
        let options = {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: 'transparent'
            },
            title: {
                text: 'Balance Pending',
                verticalAlign: 'middle',
                floating: true,
                y: 20,
                x: -90,
                style: {
                    color: ''
                },
            },
            plotOptions: {
                pie: {
                    innerSize: 150,
                    size: 290,
                    borderWidth: 0,
                    // allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        // format: "<strong>{point.y}</strong><br>",
                        // format: "{point.y}<br>",
                        format: '{point.y:,.0f}',
                        distance: -25,
                        color: "#3b3a37",
                        style: {
                            fontSize: '16px',
                            textOutline: "0px"
                        }
                    },
                    showInLegend: true
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '18px'
                },
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemMarginTop: 5,
                itemMarginBottom: 5,
                navigation: {
                    style: {
                        color: 'white',
                        fontSize: '14px'
                    }
                },
                x: -100,
                y: 0
            },
            tooltip: {
                pointFormat: '{point.y}</b>',
                valueDecimals: 0
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [{
                data: object
            }]
        }
        return options;
    }
    getIssueChart(object) {
        let options = {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: 'transparent'
            },
            title: {
                text: 'Issue',
                verticalAlign: 'middle',
                floating: true,
                y: 20,
                x: -100,
                style: {
                    color: ''
                },
            },
            plotOptions: {
                pie: {
                    innerSize: 150,
                    size: 290,
                    borderWidth: 0,
                    // allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        // format: "<strong>{point.y}</strong><br>",
                        // format: "{point.y}<br>",
                        format: '{point.y:,.0f}',
                        distance: -25,
                        color: "#3b3a37",
                        style: {
                            fontSize: '16px',
                            textOutline: "0px"
                        }
                    },
                    showInLegend: true
                }
            },
            legend: {
                itemStyle: {
                    color: 'white',
                    fontSize: '18px'
                },
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemMarginTop: 5,
                itemMarginBottom: 5,
                navigation: {
                    style: {
                        color: 'white',
                        fontSize: '14px'
                    }
                },
                x: -100,
                y: 0
            },
            tooltip: {
                pointFormat: '{point.y}</b>',
                valueDecimals: 0
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [{
                data: object
            }]
        }
        return options;
    }
}
