import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { isNullOrUndefined } from 'util';
import { UtilsService } from 'src/app/services/utils.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  locationList : any = ['PROJECT','PACKAGE','ACTIVITY' ];
  chartWiseLocationList : any = {
    'physical' : ['PROJECT','PACKAGE','ACTIVITY' ],
    'financial': ['PROJECT','PACKAGE' ],
    'land acquisition' : ['PROJECT','PACKAGE' ],
    'alerts' : ['PROJECT','PACKAGE' ]
  };
  parentLocMapping : any = {
    'package no' : 'PROJECT',
    'package' : 'PROJECT',
    'component' :'PACKAGE'
  }
  packageWiseCompOrder = {
    'PACKAGE1': ['PUMP HOUSE', 'PRESSURE MAIN', 'GRAVITY CANAL', 'STRUCTURES'],
    'PACKAGE2': ['GRAVITY CANAL', 'STRUCTURES'],
    'PACKAGE3': ['GRAVITY CANAL', 'STRUCTURES'],
    'PACKAGE4': ['GRAVITY CANAL', 'STRUCTURES'],
    'PACKAGE5': ['PUMP HOUSE', 'PRESSURE MAIN'],
    'PACKAGE6': ['PUMP HOUSE', 'PRESSURE MAIN'],
    'PACKAGE7': ['GRAVITY CANAL', 'STRUCTURES'],
    'PACKAGE8': ['GRAVITY CANAL', 'STRUCTURES']
  }
  packageWiseChartOrder = {
    'PACKAGE1': ['PUMP HOUSE', 'PRESSURE MAIN', 'CANAL', 'STRUCTURES'],
    'PACKAGE2': ['CANAL', 'STRUCTURES'],
    'PACKAGE3': ['CANAL', 'STRUCTURES'],
    'PACKAGE4': ['CANAL', 'STRUCTURES'],
    'PACKAGE5': ['PUMP HOUSE', 'PRESSURE MAIN'],
    'PACKAGE6': ['PUMP HOUSE', 'PRESSURE MAIN'],
    'PACKAGE7': ['CANAL', 'STRUCTURES'],
    'PACKAGE8': ['CANAL', 'STRUCTURES']
  };
  gravityCanalActivityOrder = [
    'earth_work_excavation','earth_work_embankmant','cement_concrete','cc_lining','cc_walls','shortcreting'];
  constructor(private utilsService : UtilsService) { }

  getChildLocType(location,chartType?){
    let LOC= this.chartWiseLocationList[chartType];
    let i = LOC.findIndex((loc)=> loc.toLowerCase() == location.toLowerCase());
    if(i > -1 && i!= LOC.length-1){
      return LOC[i+1];
    }
    else if (i == LOC.length-1){
      return LOC[LOC.length-1];
    }
    return location  ;
  }

  getParentLocType(child: any){
    return this.parentLocMapping[child];
  }

  exportAlertsReportExcel(): void {
    const fileName = 'Alerts.xlsx';
    const element = document.getElementById('downloadtable').cloneNode(true);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }
  downloadAlertsReportPdf(headers?:any,locType?:any,tableData?:any) {
    let widths = [];
    for (let i = 0; i < Object.keys(tableData[0]).length; i++) {
      widths.push('*');
    }
    let tableBody = [];
    let row = [];
    if(locType == this.locationList[0]){
   row = [
      { 'text': 'Package', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'No of Issues', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'No of Issues(Pending)', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'No of Issues (in Progress)', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'No of Issues (Resolved)', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' }
    ];
  }
  else{
   row = [
      { 'text': 'Package No', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Alert ID', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Location', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Alert Type', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Alert Category', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Alert Raised on', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Referred To', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Initiated By', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Current Status', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      // { 'text': 'Issue Resolved', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Issue Resolved On', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Issue Resolved By', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' },
      { 'text': 'Remarks / History', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center', 'style': 'tableHeader' }
    ];
  }
    tableBody.push(row);
      let i = 1;
     tableData.forEach(d => {
        let row = [];
        if (locType == this.locationList[0]) {
          row = [
            { 'text': d['name'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['noIssues'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['noIssuesPending'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['noIssuesProgress'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['noIssuesResolved'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' }
          ]
        }
        else {
          row = [
            { 'text': d['name'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['id'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['loc'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['type'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['category'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['raisedOn'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['initiatedBy'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['referredTo'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['status'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            // { 'text': '-', 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['resolvedOn'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['resolvedBy'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' },
            { 'text': d['remarks'], 'rowSpan': 1, 'colSpan': 1, 'alignment': 'center' }
          ]
        }
        tableBody.push(row);
      })

    let metaObj = [];
    if(Object.entries(headers).length){
    let object = {};
    object['text'] = headers + "\n\n";
    object['style'] = 'subheader';
    metaObj.push(object);
    }
    let obj = {
      table: {
        widths: widths,
        body: tableBody
      }
    }
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var documentDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A2',
      content: [
        { text: 'Alerts Report' + "\n\n", alignment: 'center', style: 'header' },
        {text: metaObj , alignment: 'center', style: 'subheader'},
         obj],
         styles: {
          header: {
            fontSize: 18,
            bold: true
          },
          subheader: {
            fontSize: 15,
            bold: true
          },
          tableHeader: {
            fillColor: '#478ffe',
            color: '#fff',
            bold: true
          },
        }
    };
    pdfMake.createPdf(documentDefinition).download("Alerts Report.pdf");
  }
  sortResponse(obj) {
    let keys = Object.keys(obj).sort(), sortedobj = {};
    for (let i = 0; i < keys.length; i++) {
      sortedobj[keys[i]] = obj[keys[i]];
    }
    return sortedobj;
  }
  sortComponentTableResponse(obj,urlParams) {
    let sortedobj = {};
    let len = this.packageWiseCompOrder[urlParams.packageName].length;
    for (let i = 0; i < len; i++) {
      sortedobj[this.packageWiseCompOrder[urlParams.packageName][i]] = obj[this.packageWiseCompOrder[urlParams.packageName][i]];
    }
    return sortedobj;
  }
  sortComponentChartResponse(obj,urlParams) {
    let sortedobj = {};
    let len = this.packageWiseChartOrder[urlParams.packageName].length;
    for (let i = 0; i < len; i++) {
      sortedobj[this.packageWiseChartOrder[urlParams.packageName][i]] = obj[this.packageWiseChartOrder[urlParams.packageName][i]];
    }
    return sortedobj;
  }
  sortPressureMainResponse(obj,urlParams) {
    let reversedObject = {};
    Object.keys(obj).sort().reverse().forEach(key => {
      reversedObject[key] = obj[key]
    });
    return reversedObject;
  }
  sortGravityCanalResponse(obj){
    let sortedobj = {};
    let len = this.gravityCanalActivityOrder.length;
    for (let i = 0; i < len; i++) {
      if(!this.utilsService.isEmptyObj(obj[this.gravityCanalActivityOrder[i]]) && !isNullOrUndefined(obj[this.gravityCanalActivityOrder[i]])){
      sortedobj[this.gravityCanalActivityOrder[i]] = obj[this.gravityCanalActivityOrder[i]];
      }
    }
    return sortedobj;
  }
}
