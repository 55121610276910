import { Component, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { GisDataService } from '../../gis-map/gis-data.service';
import { MapConstantsModule } from '../../gis-map/map-constants.module';

@Component({
  selector: 'app-baselayers',
  templateUrl: './baselayers.component.html',
  styleUrls: ['./baselayers.component.scss']
})
export class BaselayersComponent implements OnInit {
  @Input() map: any;
  @Input() layersList: any;
  baseLayerMetaData:any = {};
  static baseLayerObjMap: any = {};
  static selectedSource: string;
  static addedLayer: string;

  constructor(private gisDataService : GisDataService,
              private mapConstants : MapConstantsModule) { }

  ngOnInit() {
    let activeLayer = null;
    for (const key in this.layersList.baselayers) {
      let val = this.layersList.baselayers[key];
      if (this.map.hasLayer(val)) {
        this.layersList.baselayers[key].options.class = 'card card-maps h-100 active';
        activeLayer = true;
      }
      else
        this.layersList.baselayers[key].options.class = "card card-maps h-100";
    }
    if (isNullOrUndefined(activeLayer)) {
      this.layersList.baselayers[BaselayersComponent.addedLayer].options.class = 'card card-maps h-100 active';
    }
    this.baseLayerMetaData = Object.assign({},this.baseLayerMetaData,this.gisDataService.setWMSOverlays(this.map,this.gisDataService.getApplicationBasedLayer(this.mapConstants.BASELAYER_METADATA_LAYER,'WMS',this.map)));
    this.baseLayerMetaData = this.baseLayerMetaData['BASELAYER_METADATA'];    
  }
  showAndHideLayer(layer, base) {
    if (base) {
      for (const key in this.layersList.baselayers) {
        if (this.layersList.baselayers.hasOwnProperty(key)) {
          const value = this.layersList.baselayers[key];
          if (value.options.name && value.options.name.includes('Sentinel')) {
            this.removeSentinelLayers(value.options.name, value.options.layers);
            this.layersList.baselayers[key].options.visible = false;
            this.layersList.baselayers[key].options.class = 'card card-maps h-100';
          } else if (this.map.hasLayer(value)) {
            this.map.removeLayer(value);
            this.layersList.baselayers[key].options.visible = false;
            this.layersList.baselayers[key].options.class = 'card card-maps h-100'
          }
        }
      }
      if (layer.options.name && layer.options.name.includes('Sentinel')) {
        this.addSentinelLayers(layer, layer.options.name, layer.options.layers,this.mapConstants.LAYERKEY_SOURCE_MAP[layer.options.name]);
        // this.gisDataService.emitSourceChangeEvent(MapConstants.LAYERKEY_SOURCE_MAP[layer.options.name]);
        BaselayersComponent.addedLayer = layer.options.name;
        if (BaselayersComponent) {
          BaselayersComponent.selectedSource = this.mapConstants.LAYERKEY_SOURCE_MAP[layer.options.name];
        }
      } else {
        this.map.addLayer(layer);
        if (BaselayersComponent) {
          BaselayersComponent.selectedSource = null;
        }
      }
      layer.options.visible = true;
      layer.options.class = 'card card-maps h-100 active'
      return;
    }
  }
  addSentinelLayers(layerObj: any, layerKey: string, layerName: string, source: string) {
    BaselayersComponent.baseLayerObjMap[layerName] = {};
    if (this.mapConstants.SOURCE_COORDINATE_MAP[source]) {
      for (let coordinate in this.mapConstants.SOURCE_COORDINATE_MAP[source]) {
        if (this.mapConstants.SOURCE_COORDINATE_MAP[source][coordinate]) {
          this.baseLayerMetaData.wmsParams.cql_filter = "source='" + source + "'";
          this.gisDataService.getFeatureInfoForGivenPoint(this.mapConstants.SOURCE_COORDINATE_MAP[source][coordinate].point, this.mapConstants.SOURCE_COORDINATE_MAP[source][coordinate].bbox, this.mapConstants.SOURCE_COORDINATE_MAP[source][coordinate].size, this.baseLayerMetaData).subscribe((res: any) => {
            if (res && res['features'].length >= 1 && res['features'][0]['properties']['latest_date'] && res['features'][0]['properties']['latest_date'].split('T').length > 0) {
              if (source == 'SENTINEL_1') {
                if (coordinate == '0') {
                  BaselayersComponent.baseLayerObjMap[layerName].left = this.gisDataService.getTimeWmsLayerFromLayerName(layerObj, layerName, res['features'][0]['properties']['latest_date'].split('T')[0]);
                  if (!this.map.hasLayer(BaselayersComponent.baseLayerObjMap[layerName].left))
                    BaselayersComponent.baseLayerObjMap[layerName].left.addTo(this.map);
                } else if (coordinate == '1') {
                  BaselayersComponent.baseLayerObjMap[layerName].right = this.gisDataService.getTimeWmsLayerFromLayerName(layerObj, layerName, res['features'][0]['properties']['latest_date'].split('T')[0]);
                  if (!this.map.hasLayer(BaselayersComponent.baseLayerObjMap[layerName].right))
                    BaselayersComponent.baseLayerObjMap[layerName].right.addTo(this.map);
                }
              } else if (source == 'SENTINEL_2') {
                if (coordinate == '0') {
                  BaselayersComponent.baseLayerObjMap[layerName].left = this.gisDataService.getTimeWmsLayerFromLayerName(layerObj, layerName, res['features'][0]['properties']['latest_date'].split('T')[0]);
                  BaselayersComponent.baseLayerObjMap[layerName].left.addTo(this.map);
                } else if (coordinate == '1') {
                  BaselayersComponent.baseLayerObjMap[layerName].middle = this.gisDataService.getTimeWmsLayerFromLayerName(layerObj, layerName, res['features'][0]['properties']['latest_date'].split('T')[0]);
                  BaselayersComponent.baseLayerObjMap[layerName].middle.addTo(this.map);
                } else if (coordinate == '2') {
                  BaselayersComponent.baseLayerObjMap[layerName].right = this.gisDataService.getTimeWmsLayerFromLayerName(layerObj, layerName, res['features'][0]['properties']['latest_date'].split('T')[0]);
                  BaselayersComponent.baseLayerObjMap[layerName].right.addTo(this.map);
                }
              }
            }
          });
        }
      }
    }

  }

  removeSentinelLayers(layerKey: string, layerName: string) {
    if (layerKey == 'Sentinel 1') {
      if (BaselayersComponent.baseLayerObjMap[layerName] && BaselayersComponent.baseLayerObjMap[layerName].left) {
        this.map.removeLayer(BaselayersComponent.baseLayerObjMap[layerName].left);
      }
      if (BaselayersComponent.baseLayerObjMap[layerName] && BaselayersComponent.baseLayerObjMap[layerName].right) {
        this.map.removeLayer(BaselayersComponent.baseLayerObjMap[layerName].right);
      }
    } else if (layerKey.includes('Sentinel 2')) {
      if (BaselayersComponent.baseLayerObjMap[layerName] && BaselayersComponent.baseLayerObjMap[layerName].left) {
        this.map.removeLayer(BaselayersComponent.baseLayerObjMap[layerName].left);
      }
      if (BaselayersComponent.baseLayerObjMap[layerName] && BaselayersComponent.baseLayerObjMap[layerName].right) {
        this.map.removeLayer(BaselayersComponent.baseLayerObjMap[layerName].right);
      }
      if (BaselayersComponent.baseLayerObjMap[layerName] && BaselayersComponent.baseLayerObjMap[layerName].middle)
        this.map.removeLayer(BaselayersComponent.baseLayerObjMap[layerName].middle);
    }
  }
}