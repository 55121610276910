import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationError, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-layercontrol',
  templateUrl: './layercontrol.component.html',
  styleUrls: ['./layercontrol.component.scss']
})
export class LayercontrolComponent implements OnInit {
  @Input() map : any;
  @Input()layersList : any;
  groupList : any
  curerentActiveLayerName: any;
  htmlLegendObjects: any = [];
  activeLayer : any ;
  urlParams : any;
  navigationSubscription : Subscription
 
  constructor(private router : Router,
              private route : ActivatedRoute,
              private utilsService : UtilsService) {
    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Hide loading indicator  // Present error to user
      }
    
    });
   }

  ngOnInit() {
    this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
    // this.map.on('click',(e)=>{
    //   this.floodforecastBottombarComp.bottomBarOpen = true;
    // });
console.log(this.layersList.overlaylayers);
  }

  accordianOnClick(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    target.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    let panel = target.nextElementSibling as HTMLElement;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

  showAndHideLayer(layer,base){
    if(base){
      for (const key in this.layersList.baselayers) {
        if (this.layersList.baselayers.hasOwnProperty(key)) {
          const value = this.layersList.baselayers[key];
          if(this.map.hasLayer(value))
          this.map.removeLayer(value);
          value.options.visible = false;
        }
      }
      this.map.addLayer(layer)
      layer.options.visible = true;
      return;
    }


    var is_deselect = false;
    // for (const key in this.layersList.overlaylayers) {
    //   if (this.layersList.overlaylayers.hasOwnProperty(key)) {
    //     const overlay = this.layersList.overlaylayers[key];
    //     if(layer.options.groupType == 'radio' && overlay.options.group == layer.options.group){
    //       this.map.removeLayer(overlay);
    //       overlay.options.visible = false;
    //     }
    //     if(layer.options.groupType == 'radio-deselect'){
    //       if( (layer.options.group == "Rainfall Forecast" || layer.options.group == "Rainfall Actual")   &&  (overlay.options.group == "Rainfall Forecast"  || overlay.options.group == "Rainfall Actual")){
    //         if (layer == overlay && overlay.options.visible == true) {
    //           is_deselect = true;
    //         }
    //         if(overlay.options.visible == true)
    //         this.map.removeLayer(overlay);
    //         overlay.options.visible = false;
    //       }
    //     }
    //   }
    // }
    if(this.map.hasLayer(layer)){
      this.map.removeLayer(layer);
      layer.options.visible = false;
    }else if (!is_deselect) {
      this.map.addLayer(layer);
      layer.options.visible = true;
      //check first if random heatMap from datePicker is on map or not if it is the first remove it
    }
  }

  ngOnDestroy(){
    this.navigationSubscription.unsubscribe();
  }
}
