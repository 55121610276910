import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as L from 'leaflet';
import { GisDataService } from '../../gis-map/gis-data.service';
import "src/assets/mapLibrary/leaflet-measure.js";
@Component({
  selector: 'app-measurement-tool',
  templateUrl: './measurement-tool.component.html',
  styleUrls: ['./measurement-tool.component.scss']
})
export class MeasurementToolComponent implements OnInit {
  @Output() measureCtrl: any = new EventEmitter<object>();
  @Input() measureType: string = null;
  @Input() measureControl: any = null;
  @Input() measureDistance: any = null;
  @Input() measureArea: any = null;
  @Input() mapComponent: L.Map;
  @Input() layersList: any;

  distanceMeasureUnit: any = 'M-M';
  distanceMeasureUnitList = { 'M-FT': 'Feet', 'M-M': 'Meter', 'M-KM': 'Kilometer' };
  areaMeasureUnit: any = 'SQM-SQM';
  areaMeasureUnitList = { 'SQM-SQFT': 'Sq feet', 'SQM-SQM': 'Sq meters', 'SQM-AC': 'Acres', 'SQM-H': 'Hectares', 'SQM-SQKM': 'Sq kilometers' };

  constructor(private gisDataService: GisDataService) { }

  ngOnInit() {
    this.measureControl = new (L as any).Control.Measure({ measureUnit: 'meters', createButton: false, activeColor: '#000000', completedColor: '#C8F2BE', pane: 'mask' });
    this.measureControl.addTo(this.mapComponent);

    this.mapComponent.on(
      'measure:finishedpath', (e: any) => {
        this.measureDistance = e.measure.length_ori;
        this.measureArea = e.measure.area_ori;
      });
    // for (const key in this.layersList.overlaylayers) {
    //   if (this.layersList.overlaylayers[key].options.type == 'VECTOR' &&
    //     this.layersList.overlaylayers[key].options.component != 'WATER_SPREAD') {
    //     this.layersList.overlaylayers[key].off('click');
    //   }
    // }
  }
  public measure(type: any) {
    this.measureControl.stopMeasuring();
    this.measureArea = null;
    this.measureDistance = null;
    if (this.measureType === type) {
      this.measureType = null;
      return;
    }
    this.measureType = type;
    if (type === 'distance') {
      this.measureControl.startMeasuring({ measureArea: false, measurePoint: false, createButton: false });
    }
    if (type === 'area') {
      this.measureControl.startMeasuring({ measureArea: true, createButton: false });
    }
    if (type === 'point') {
      this.measureControl.startMeasuring({ measurePoint: true, createButton: false });
    }
    this.measureCtrl.emit({
      measureControl: this.measureControl, measureArea: this.measureArea,
      measureDistance: this.measureDistance, measureType: this.measureType
    });
  }

  asIsOrder() {
    return 1;
  }

  ngOnDestroy() {
    this.measureControl.stopMeasuring();
    this.mapComponent.removeControl(this.measureControl);
    this.measureArea = null;
    this.measureDistance = null;
    // for (const key in this.layersList.overlaylayers) {
    //   if (this.layersList.overlaylayers[key].options.type == 'VECTOR' && this.layersList.overlaylayers[key].options.component != 'WATER_SPREAD') {
    //     this.gisDataService.sendNavigatedLayer(this.layersList.overlaylayers[key])
    //   }
    // }
  }
}
