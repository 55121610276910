import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationError,Event } from '@angular/router';
import { AuthloginService } from 'src/app/services/authlogin.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from 'src/app/modules/user-management/change-password/change-password.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { ProjConstants } from 'src/app/modules/table/common/constants/proj.constant';
import { SharedConstantsModule } from 'src/app/shared/shared-constants.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() endDate = '';
  @Input() startDate = '';
  userdetail:object = {};
  userdetails:any;
  urlParams: any;
  lastParam: string;
  projectName : string;
  activeComponent : string = 'overview';
  navigationSubscription: Subscription;
  
  constructor(private router: Router,private sessionlogin : AuthloginService, private utils : UtilsService,
    private cookie : CookieService,private modalService: NgbModal, private toastr : ToastrService,
    private http: HttpService, private route: ActivatedRoute,private sharedConstants : SharedConstantsModule) {
      this.navigationSubscription = this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
  
        }
        if (event instanceof NavigationEnd) {
          this.urlParams = this.utils.checkUrlParams(this.route.snapshot.params);
          let params = this.router.url;
          let paramsList = params.split("/");
          this.lastParam = paramsList[paramsList.length - 1];
          this.activeComponent =  this.getActiveComponentName();
          if(this.urlParams.projectName == 'sitaRama'){
            this.projectName = 'Sita Rama Lift Irrigation Project';
          }
        }
        if (event instanceof NavigationError) {
          console.log(event.error); // Hide loading indicator  // Present error to user
        }
      });
     }

  ngOnInit() {
    if(!(localStorage.getItem('theme')) || localStorage.getItem('theme') == 'body-bg-dark light'){
      this.toggleTheme('light');
    }
    else{
      this.toggleTheme('black');
    }
       
      if(this.cookie.get('loggedIn')=='true'){
      this.userdetail = this.sessionlogin.checkloginstatus();
      this.userdetail['login'] = this.utils.checkUndefinedOrNull(this.userdetail['login']); 
      this.userdetails = JSON.parse(this.cookie.get('userdetails'));
    }
    if(this.urlParams.projectName == 'sitaRama'){
      this.projectName = 'Sita Rama Lift Irrigation Project';
    }
  }
  getActiveComponentName(){
    let params = this.router.url;
    if(params.includes('overview')){
      return 'overview';
    }
    else if(params.includes('projectDetails')){
      return 'projectDetails';
    }
    else if(params.includes('report')){
      return 'report';
    }
  }
  toggleTheme(theme: string){
    if(theme === 'light') {document.body.classList.add('light')}else {document.body.classList.remove('light')}
    localStorage.setItem('theme',document.body.classList.value);
    this.http.themeChanged(theme);
  }
  navigateBreadCrumb(event){
    const params = {};
    if(event == 'srlip'){
      params['projectName'] = this.urlParams['projectName'];
      params['locType'] =  this.sharedConstants.LOC_CONSTANT[0];
      this.router.navigate(['tsripms', 'projectDashboard', 'overview', params]);
    }
    else if(event == 'project'){
      params['projectName'] = this.urlParams['projectName'];
      params['uuid'] =  this.sharedConstants.UUID_MAPPING['PROJECT'];
      params['locType'] =  this.sharedConstants.LOC_CONSTANT[0];
    }
    else if(event == 'package'){
      params['projectName'] = this.urlParams['projectName'];
      params['packageName'] = this.urlParams['packageName'];
      params['uuid'] =  this.sharedConstants.UUID_MAPPING[ params['packageName']];
      params['locType'] =  this.sharedConstants.LOC_CONSTANT[1];
    }
    else if(event == 'activity'){
      params['projectName'] = this.urlParams['projectName'];
      params['packageName'] = this.urlParams['packageName'];
      params['uuid'] =  this.sharedConstants.UUID_MAPPING[ params['packageName']];
      params['locType'] =  this.sharedConstants.LOC_CONSTANT[2];
      params['componentName'] = this.urlParams['componentName'];
    }
    if(event != 'srlip'){
    params['chartType'] = this.urlParams['chartType'] != 'alerts' ?  this.urlParams['chartType'] : 'land acquisition';
    this.router.navigate(['tsripms', 'projectDashboard', 'projectDetails', params]);
    }
  }
  goToLandingPage(){
    this.router.navigate(['tsripms']);
  }
  
  showProjectDetails(){
    this.urlParams={
      projectName : 'sitaRama'
    }
    this.router.navigate(['tsripms','projectDashboard','projectDetails',this.urlParams]);
  }
  routeToGIS(type){
    if(type == 'report'){
      this.activeComponent = 'report';
    let eyear =this.endDate['year'];
    let emonth =this.endDate['month']< 9 ? "0" + this.endDate['month'].toString() : this.endDate['month'].toString();
    let eday = this.endDate['day']< 9 ? "0" + this.endDate['day'].toString() : this.endDate['day'].toString();
    let totalEndDate = eyear + emonth + eday;
    let syear =this.startDate['year'];
    let smonth =this.startDate['month']< 9 ? "0" + this.startDate['month'].toString() : this.startDate['month'].toString();
    let sday =this.startDate['day']< 9 ? "0" + this.startDate['day'].toString() : this.startDate['day'].toString();
    let totalStartDate = syear + smonth + sday;
    
    let params ={
      locType : this.sharedConstants.LOC_CONSTANT[0],
      sDate : totalStartDate,
      eDate : totalEndDate 
    }
    this.router.navigate(['tsripms','geoportal','gis',params]);
  }
else{
  let params ={
    locType : this.sharedConstants.LOC_CONSTANT[0],
    sDate : 20210101,
    eDate : 20210306
  }
  this.router.navigate(['tsripms','geoportal','gis',params]);
    }
  }
  changePassword(){
    const modal = this.modalService.open(ChangePasswordComponent, {
    size: 'lg'
    });
    }

  public logoutUser(){
      let url  = this.router.url; 
      let option = this.sessionlogin.checkloginstatus();
      // this.activespineer = true;
      this.sessionlogin.logoutuser(option).subscribe(logoutres =>{
         if(logoutres['result']['success']){
          this.cookie.set('loggedIn', 'false');
          this.cookie.delete('ACCESS_TOKEN','/');
          this.cookie.delete('ROLE');
          this.cookie.delete('USERID','/');
          this.cookie.delete('USER_INFO','/');
          this.cookie.delete('USER_DETAILS_INFO','/');
          this.sessionlogin.clearusersession();
          this.router.navigate(["tsripms"]);
          this.toastr.success("","Logged Out Successfully", {timeOut: 2000, positionClass: 'toast-top-right'});
        }
      })
    }

  
    getUserRole(){
      let role = this.cookie.get('ROLE');      
      if(role=='ADMIN' || role=='SUPER_ADMIN'){
        return true;
      }else{
        return false;
      }
    }

    goToDetails(type){
      if(type == 'projectDashboard'){
        this.activeComponent = 'projectDetails';
        let params = {};
        params['projectName'] = this.urlParams['projectName'];
        if(this.urlParams['chartType']){
        params['chartType'] = this.urlParams['chartType'] != 'alerts' ? this.urlParams['chartType']  : 'land acquisition' ;
        }
        else{
          params['chartType']  = 'land acquisition';
        }
        params['uuid'] = this.sharedConstants.UUID_MAPPING['PROJECT'];
        params['locType'] = this.sharedConstants.LOC_CONSTANT[0];
        let packageName = this.urlParams['packageName'] ? this.urlParams['packageName'] : '';
        if(packageName!=''){
          params['packageName'] = packageName;
          params['uuid'] = this.sharedConstants.UUID_MAPPING[packageName];
          params['locType'] = this.sharedConstants.LOC_CONSTANT[1];
        }
        if(this.urlParams['componentName']){
        params['componentName'] = this.urlParams['componentName'];
        params['uuid'] = this.sharedConstants.UUID_MAPPING[packageName];
        params['locType'] = this.sharedConstants.LOC_CONSTANT[2];
        }
        this.router.navigate(['tsripms', 'projectDashboard', 'projectDetails', params]);
      }else if(type=='report'){
        this.activeComponent = type;
        let chart = this.urlParams['chartType'] ?  this.urlParams['chartType'] : 'financial';
        let params = {};
        params['projectName'] = this.urlParams['projectName'];
        params['chartType'] = chart;
        params['location'] = 'SRLIP';
        params['locType'] = this.sharedConstants.LOC_CONSTANT[0];
        params['uuid'] = this.sharedConstants.UUID_MAPPING['PROJECT'];
        params['parent'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][0];
        params['child'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][1];
        params['sDate'] = 20210101;
        params['eDate'] = 20210306;
        this.router.navigate(['tsripms','projectDashboard',type,params]);
      }else if(type=='overview'){
        this.activeComponent = type;
        let params = {};
        if(this.urlParams['locType'] == this.sharedConstants.LOC_CONSTANT[0]){
        params['projectName'] = this.urlParams['projectName'];
        params['locType'] = this.sharedConstants.LOC_CONSTANT[0];
        }
        else{
          params['projectName'] = this.urlParams['projectName'];
          params['locType'] = this.sharedConstants.LOC_CONSTANT[0];
          
        // if(this.urlParams['chartType']){
        // params['chartType'] = this.urlParams['chartType'];
        // console.log("poject chart type:",params['chartType']);

        // }
        if(this.urlParams['packageName']){
          params['packageName'] = this.urlParams['packageName'];
          params['locType'] = this.sharedConstants.LOC_CONSTANT[1];
        }
        // if(this.urlParams['componentName']){
        // params['componentName'] = this.urlParams['componentName']
        // params['locType'] = this.sharedConstants.LOC_CONSTANT[2];
        // }
      }
        this.router.navigate(['tsripms','projectDashboard',type,params]);
      }
    }

    ngOnDestroy(){
      this.navigationSubscription.unsubscribe();
    }
}
