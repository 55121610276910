import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { isNullOrUndefined, isNull } from 'util';
import { ConstantsModule } from '../constants/constants.module';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private constants: ConstantsModule,) { }

  public checkUrlParams(obj: object): object {
    let objectConfig = {};

    if (obj == null || obj == undefined) return obj;

    for (let key in obj) {
      objectConfig[key] = obj[key];
    }

    return objectConfig;
  }

  getTimeStamp(date) {
    let current = new Date();
    current.setDate(date.day);
    current.setMonth(date.month - 1);
    current.setFullYear(date.year);
    let timestamp = current.getTime();
    return timestamp;
  }
  getWritableObject(obj: object): object {
    if (obj == null || obj == undefined) return obj;

    let objectConfig = {};
    for (let key in obj) {
      objectConfig[key] = obj[key];
    }

    return objectConfig;
  }

  getSimpleDateStringWithoutTime(timestamp) {
    let monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (isNaN(timestamp)) {
      return "--";
    }
    else {
      let numTimestamp = Number(timestamp);
      let currentDate = new Date(numTimestamp);
      let date = currentDate.getDate();
      let cd = date < 10 ? "0" + date : date;
      let dateValue = cd + '-' + (monthShortLabel[currentDate.getMonth()]) + '-' + currentDate.getFullYear();
      return dateValue;
    }
  }

  getSimpleDateString(timestamp) {
    let monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (isNaN(timestamp)) {
      return "--";
    }
    else {
      let numTimestamp = Number(timestamp);
      let currentDate = new Date(numTimestamp);
      let date = currentDate.getDate();
      let cd = date < 10 ? "0" + date : date;
      let hours = currentDate.getHours();
      let hh = hours < 10 ? "0" + hours : hours;
      let minutes = currentDate.getMinutes();
      let mm = minutes < 10 ? "0" + minutes : minutes;
      let dateValue = cd + '-' + (monthShortLabel[currentDate.getMonth()]) + '-' + currentDate.getFullYear()
        + ' ' + hh + ':' + mm;
      return dateValue;
    }
  }
  public checkUndefinedOrNull(val) {
    // if(val != undefined && val != null){
    if (val == null)
      return '-';
    return val;
    // }else{
    //   return null;
    // }
  }

  getShortedData(value) {
    if (value != null && value != undefined) {
      if (Number.isInteger(value)) {
        return (value).toLocaleString('en-IN');
      } else {
        let k;
        if (typeof value === "number")
          k = value.toString();
        else
          k = value;
        let string = k.split('.');
        let noOfDigitBeforeDecimal = string[0].length;
        if (noOfDigitBeforeDecimal == 1) {
          value = parseFloat(Number(value).toFixed(2));
          return (value).toLocaleString('en-IN');
        } else {
          value = parseFloat(Number(value).toFixed(2));
          return (value).toLocaleString('en-IN');
        }
      }
    } else {
      return '-';
    }

  }

  getShortedDataForChart(value) {
    if (String(value).indexOf(".") != -1) {
      var splitValue = JSON.stringify(value).split('.');
      let intPart = splitValue[0].length;
      if (intPart == 1) {
        value = Number(value).toFixed(2);
        return value;
      }
      else if (intPart > 1) {
        value = Number(value).toFixed(1);
        return value;
      }
    }
    else {
      return value;
    }
  }

  public fixTwoDigitsOfFloat(value) {
    if (value) {
      if (typeof value === "number") {
        if (value.toString().split(".").length === 2) {
          if (value.toString().split(".")[1].length > 2) {
            // value = value.toString().split(".")[0] +"."+ value.toString().split(".")[1].substring(0,2);
            value = value.toString().split(".")[0] + (value.toString().split(".")[1].substring(0, 2) === "00" ? "" : "." + value.toString().split(".")[1].substring(0, 2));
            return value;
          } else {
            return value;
          }
        } else {
          return value;
        }
      }
    } else {
      if (value == 0) {
        return value;
      } else {
        return '-';
      }
    }
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  getDateInFormat(date) {
    let todayDate = date.getDate();
    let todayMonth = date.getMonth() + 1;
    let td = todayDate < 10 ? "0" + todayDate : todayDate;
    let tm = todayMonth < 10 ? "0" + todayMonth : todayMonth;
    return td + '-' + tm + '-' + (date.getFullYear() - 1);
  }

  getDateInDDMMYYYYFormat(value) {
    let year = value.substring(0, 4);
    let month = value.substring(4, 6);
    let date = value.substring(6, 8);
    if (date == '' && month != '') {
      return month + '-' + year;
    }
    else if (month == '') {
      return year;
    }
    return date + '-' + month + '-' + year;
  }
  splitLocationString(str) {
    let s = str.split('_');
    let numberPattern = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
    return 'From ' + s[1].match(numberPattern) + ' To Km ' + s[2];
  }

  getDateandTimeDashSeparatorNew(timestamp) {
    var d = new Date(timestamp);
    var year = d.getFullYear().toString();
    var month: any = d.getMonth() + 1;
    month = month.toString();
    var date = d.getDate().toString();
    var hours = d.getHours().toString();
    var minutes = d.getMinutes().toString();
    var seconds = d.getSeconds().toString();
    if (month.length < 2) month = '0' + month;
    if (date.length < 2) date = '0' + date;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;

    var str = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    return str;
  }
  getNDayListInFormat(referenceTS, noOfDays, offset) {
    if (referenceTS == null || referenceTS == undefined) {
      referenceTS = new Date().getTime();
    }
    var dayList = [];
    for (var i = offset; i < (noOfDays + offset); i++) {
      var day;
      var currDay = new Date(referenceTS + (i * 24 * 60 * 60 * 1000));
      var date = (currDay.getDate() > 9) ? currDay.getDate() : "0" + currDay.getDate();
      var month = ((currDay.getMonth() + 1) > 9) ? (currDay.getMonth() + 1) : "0" + (currDay.getMonth() + 1);
      var year = currDay.getFullYear();

      day = year.toString() + month.toString() + date.toString();
      dayList.push(day);
    }

    return dayList;
  }
  monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  getReadableDateFromDateString(dateString) {
    var year = dateString.substring(0, 4);
    var month = dateString.substring(4, 6);
    var day = dateString.substring(6, 8);

    var date = new Date(year, month - 1, day);

    return day + "-" + this.monthShortLabel[date.getMonth()] + "-" + year
  }
  getDateInFormatForecast(format, refTs) {
    let monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (refTs == undefined || refTs == null)
      return null;

    let d = new Date(refTs),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    let y = year.toString();

    switch (format) {
      case "YYYY-MM-DD":
        return [year, month, day].join('-');

      case "DD-MM-YYYY":
        return [day, month, year].join('-');

      case "MM-dd-yyyy":
        return [month, day, year].join('-');

      case "dd-MMM-yyyy":
        month = monthShortLabel[d.getMonth()];
        return [day, month, year].join('-');

      case "dd/MM/yyyy":
        return [day, month, year].join('/');
      case "yyyyMMdd":
        return [year, month, day].join('');
      case "MMM dd":
        month = monthShortLabel[d.getMonth()];
        return [month, day].join(' ');
      case "dd MMM":
        month = monthShortLabel[d.getMonth()];
        return [day, month].join(' ');
      case 'yyyy-MM-dd':
        return [year, month, day].join('-');
      case "dd/MMM":
        month = monthShortLabel[d.getMonth()];
        return [day, month].join('/');
      case "yyyyMM":
        return [year, month].join('');
      case "MMyyyy":
        return [month, year].join('-');
      case "yyyy":
        return [year].join('')

      // case "MMM-yy":
      //   month = monthShortLabel[d.getMonth()];
      //   year = y.substr(2,4);
      //   return [month, year].join('-');
    }
  }

  getRfMillisForDate(date, format) {
    let monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (date == undefined)
      return 0;
    let year, month, day;
    switch (format) {
      case "YYYY-MM-DD":
        year = date.split('-')[0];
        month = date.split('-')[1];
        day = date.split('-')[2];
        break;
      case "dd-MMM-yyyy":
        year = date.split('-')[2];
        month = date.split('-')[1];
        day = date.split('-')[0];
        break;
      case "yyyy-MM-dd":
        year = date.split('-')[0];
        month = date.split('-')[1];
        day = date.split('-')[2];
        break;
      case "dd-MMM-yy":
        year = '20' + date.split('-')[2];
        month = monthShortLabel.indexOf(date.split('-')[1]) + 1;
        day = date.split('-')[0];
        break;
    }

    let date1 = new Date(year, month - 1, day, 8, 30, 0, 0);
    return date1.getTime();
  }

  getIntervalOfDay(refTs, hours, minutes, seconds) {
    let date = new Date(refTs);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date.getTime();
  }
  getDateObjectFromModelDate(date: string): Date {
    if (!date) return undefined;
    let year = parseInt(date.substr(0, 4), 10);
    let month = parseInt(date.substr(4, 2), 10);
    let day = parseInt(date.substr(6, 2), 10);
    let cD = new Date();
    cD.setDate(isNaN(day) ? 1 : day);
    cD.setMonth(isNaN(month) ? 0 : (month - 1));
    cD.setFullYear(year);
    return cD;
    // return new Date(year, (isNaN(month)?0:(month-1)), (isNaN(day)?1:day));
  }
  getModelDateFromDate(date: Date): string {
    let year = (date.getFullYear()).toString();
    let month = (((date.getMonth() + 1) < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1).toString();
    let day = ((date.getDate() < 10) ? "0" + date.getDate() : date.getDate()).toString();
    return year + month + day;
  }
  getModelDateWithDateFormat(date: Date, format: string): string {
    if (!date) return '';

    let result = '';
    if (format == this.constants.BS_DATEPICKER_DATE_FORMAT.YEAR)
      result = this.getModelDateFromDate(date).substr(0, 4);
    else if (format == this.constants.BS_DATEPICKER_DATE_FORMAT.MONTH)
      result = this.getModelDateFromDate(date).substr(0, 6);
    else
      result = this.getModelDateFromDate(date);

    return result;
  }
  getDateInFormatFromModelDate(date: string, format: string): string {
    if (!date) return date;
    let dateArray, year, month, day;
    if (date.indexOf("_") != -1) {
      dateArray = date.split("_");
      year = parseInt(dateArray[0]);
      month = parseInt(dateArray[1]);
      day = parseInt(dateArray[2]);
    } else {
      year = parseInt(date.substr(0, 4), 10);
      month = parseInt(date.substr(4, 2), 10);
      day = parseInt(date.substr(6, 2), 10);
    }



    try {
      // console.log(year,month,day,format,"try method");

      return this.getDateInFormatModel(year, month, day, format);
    } catch (e) {
      throw e;
    }
  }
  getDateInFormatModel(year: number, month: number, day: number, format: string): string {
    // console.log(day,"day");


    let monthFormat = {
      MMM: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    }
    let result = format;
    /**
     * this object will be used to replace the values in the key in the required format
     */
    let formattedDateObject = {
      MMM: (format.indexOf('MMM') >= 0) ? monthFormat['MMM'][month - 1] : '',
      mmm: (format.indexOf('mmm') >= 0) ? monthFormat['MMM'][month - 1] : '',
      MM: (format.indexOf('MM') >= 0) ? (month < 10 && month.toString().length < 2) ? ("0" + month) : month : '',
      mm: (format.indexOf('mm') >= 0) ? (month < 10 && month.toString().length < 2) ? ("0" + month) : month : '',
      yyyy: (format.indexOf('yyyy') >= 0) ? year : '',
      YYYY: (format.indexOf('YYYY') >= 0) ? year : '',
      yy: (format.indexOf('yy') >= 0) ? year.toString().substr(2, 2) : '',
      YY: (format.indexOf('YY') >= 0) ? year.toString().substr(2, 2) : '',
      dd: (format.indexOf('dd') >= 0) ? (day < 10 && day.toString().length < 2) ? ("0" + day) : day : '',
      DD: (format.indexOf('DD') >= 0) ? (day < 10 && day.toString().length < 2) ? ("0" + day) : day : ''
    }

    for (let key in formattedDateObject) {
      if (result.indexOf(key) >= 0) {
        /**
         * If the model date doesn't match the required format, then try to fill whatever we can and
         * eliminate the other parameters.
         */
        if (!formattedDateObject[key]) {
          result = result.replace(key, '');
          continue;
        }
        result = this.replaceStringAt(result, result.indexOf(key), formattedDateObject[key].toString())
        // console.log(result,"result");

      }
    }

    //remove the preceeding and trailing -
    result = result.replace('--', '-').replace(/^-/, '').replace(/-$/, '');
    return result;
  }
  replaceStringAt(original: string, index: number, replacement: string): string {
    return original.substr(0, index) + replacement + original.substr(index + replacement.length);
  }
  getStartAndEndDatesForType(startDate: string, endDate: string, plan: string) {

    let data = {
      "startDate": startDate,
      "endDate": endDate
    }

    if (plan == 'YEARLY' || plan == this.constants.DATEPICKER_DATE_FORMAT.YEAR) {
      data["startDate"] = data["startDate"] + "0101";
      data["endDate"] = data["endDate"] + this.getmonthyearforyearType(data["endDate"]);
    } else if (plan == 'MONTHLY' || plan == this.constants.DATEPICKER_DATE_FORMAT.MONTH) {
      data["startDate"] = data["startDate"] + "01";
      data["endDate"] = this.getLastDayForGivenModelDate(data['endDate']);
    }

    return data;
  }
  getmonthyearforyearType(enddate): string {
    var date = new Date();
    if (enddate == date.getFullYear()) {
      let month = ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1));
      let curdate = (date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate());
      return (month + "" + curdate);
    } else {
      return "1231";
    }

  }
  getLastDayForGivenModelDate(date: string): string {
    if (!date) return date;
    else if (date.length == 4) return date + this.getmonthyearforyearType(date);
    else if (date.length == 6) {
      let currentDate = new Date();
      let year = Number.parseInt(date.substr(0, 4));
      let month = Number.parseInt(date.substr(4, 2));
      let lastDate = new Date(year, month, 0);
      if (year == currentDate.getFullYear() && month == currentDate.getMonth() + 1)
        date = date + (currentDate.getDate() < 10 ? '0' + currentDate.getDate() : '' + currentDate.getDate());
      else
        date = date + ((lastDate.getDate() < 10) ? "0" + lastDate.getDate() : lastDate.getDate());
    }
    return date;
  }

  checkfield(field): boolean {
    if (field === null || field === '' || field === undefined)
      return false;
    else
      return true;
  }

  capitalize(word: string): string {
    if (!word) return '';
    word = word.toLowerCase();
    return word.replace(/^\w/, c => c.toUpperCase());
  }

  getMonsoonYearFromModelDate() {
    let modelDate = moment().format('YYYYMMDD');
    modelDate = modelDate.toString()
    let year = modelDate.substring(0, 4);
    let month = modelDate.substring(4, 6)
    if (parseInt(month) > 5) {
      return parseInt(year)
    } else {
      return parseInt(year) - 1
    }
  }
  getPlanningDashboardMimicFormat(dateString) {
    var year = dateString.substring(0, 4);
    var month = dateString.substring(4, 6);
    var day = dateString.substring(6, 8);

    var date = new Date(year, month - 1, day);

    return this.monthShortLabel[date.getMonth()] + " " + day;
  }

  getRfDate() {
    let currTs = new Date().getTime();
    let rainfalldate = new Date();
    let todayRfDate = this.getDateInFormatForecast("YYYY-MM-DD", currTs);  // last 12 hours
    let todayRfDateStartTs = this.getRfMillisForDate(todayRfDate, "yyyy-MM-dd");
    if (currTs < todayRfDateStartTs) {
      rainfalldate.setDate(rainfalldate.getDate() - 1);
    }
    return rainfalldate;
  }

  fixedToDecimal(value, decimalNumber) {
    if (isNullOrUndefined(value)) {
      return '-';
    }
    return parseFloat(value).toFixed(decimalNumber);
  }

  fixedToThousandsDecimal(value, decimalNumber) {
    if (isNullOrUndefined(value)) {
      return '-';
    }
    return parseFloat((+value).toFixed(decimalNumber)).toLocaleString('en-IN', { minimumFractionDigits: 2 });
  }
  fixedFromThousandDecimal(value) {
    if (isNullOrUndefined(value)) {
      return '-';
    }
    let number = value.toString().split('.');
    let array = number[0].split(',');
    let finalNum = 0.00;
    let len = 0;
    array.forEach((data) => {
      len += data.length;
    })
    let o = 0;
    array.forEach((digits) => {
      // digits = (+digits).toFixed(0);
      let l = len - digits.length - o;
      o += digits.length;
      if (array.length > 1)
        finalNum += (digits * 10 ** l);
      else
        finalNum += +digits;
    });
    if (number[1]) {
      return parseFloat(finalNum.toString().concat('.').concat(number[1]));
    }
    else {
      return parseFloat(finalNum.toString().concat('.').concat('00'));
    }

  }
  getformattedDateString(timestamp) {
    let monthShortLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (isNaN(timestamp)) {
      return '\xa0\xa0\xa0\xa0\xa0\xa0' + '\xa0\xa0\xa0\xa0\xa0\xa0' + '00:00:00';
    }
    else {
      let numTimestamp = Number(timestamp);
      let currentDate = new Date(numTimestamp);
      let date = currentDate.getDate();
      let cd = date < 10 ? "0" + date : date;
      let hours = currentDate.getHours();
      let hh = hours < 10 ? "0" + hours : hours;
      let minutes = currentDate.getMinutes();
      let mm = minutes < 10 ? "0" + minutes : minutes;
      let seconds = currentDate.getSeconds();
      let ss = seconds < 10 ? "0" + seconds : seconds;
      let dateValue = cd + '-' + (monthShortLabel[currentDate.getMonth()]) + '-' + currentDate.getFullYear()
        + '\xa0\xa0\xa0\xa0\xa0\xa0' + hh + ':' + mm + ':' + ss;
      return dateValue;
    }
  }


  checkUndefinedOrZero(value) {
    if (isNullOrUndefined(value)) {
      return '-';
    }
    else if (parseFloat(value) >= 0) {
      return this.fixedToDecimal(value, 2);
    }
  }

  isEmptyObj(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
}
