import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrawtoolService {

  constructor() { }
  visibleShapes = [];
removedShapes = [];
enabled = false;
handlers = false;

public LINE_STYLES = [
  {color: 'rgba(33, 119, 240, 1)', weight: 3, opacity: 1.0, dashArray: ''},
  {color: 'rgba(0, 128, 0, 1)', weight: 5, opacity: 1.0, dashArray: ''},
  {color: 'rgba(255, 255, 0, 1)', weight: 6, opacity: 1.0, dashArray: ''},
  {color: 'rgba(255, 0, 0, 1)', weight: 6, opacity: 1.0, dashArray: ''},
  {color: 'rgba(255, 165, 0, 1)', weight: 10, opacity: 1.0, dashArray: ''},
  {color: 'rgba(172, 180, 205, 1)', weight: 12, opacity: 1.0, dashArray: ''},
];

public POINT_STYLES = [
  {src: 'assets/imgs/drawtool/flag.png', name: 'Flag'},
  {src: 'assets/imgs/drawtool/location-off.png', name: 'Location off'},
  {src: 'assets/imgs/drawtool/lock.png', name: 'Lock'},
  {src: 'assets/imgs/drawtool/marker.png', name: 'Marker'},
  {src: 'assets/imgs/drawtool/pin.png', name: 'Pin'},
  {src: 'assets/imgs/drawtool/push-pin.png', name: 'Push pin'}
];

public POLY_STYLES = [
  {color: 'rgba(33, 119, 240, 1)', weight: 3, opacity: 1.0, fill: true, fillColor: 'rgba(33, 119, 240, 0.6)', fillOpacity: 1.0},
  {color: 'rgba(0, 128, 0, 1)', weight: 5, opacity: 1.0, fill: true, fillColor: 'rgba(0, 128, 0, 0.6)', fillOpacity: 1.0},
  {color: 'rgba(255, 255, 0, 1)', weight: 3, opacity: 1.0, fill: true, fillColor: 'rgba(255, 255, 0, 0)', fillOpacity: 1.0},
  {color: 'rgba(255, 0, 0, 1)', weight: 5, opacity: 1.0, fill: true, fillColor: 'rgba(255, 0, 0, 0.5)', fillOpacity: 1.0},
  {color: 'rgba(255, 165, 0, 1)', weight: 2, opacity: 1.0, fill: true, fillColor: 'rgba(255, 165, 0, 0)', fillOpacity: 1.0},
  {color: 'rgba(172, 180, 205, 1)', weight: 2, opacity: 1.0, fill: true, fillColor: 'rgba(172, 180, 205, 0.5)', fillOpacity: 1.0}
];

public TEXT_STYLES = [
  {color: 'rgba(0,0,0,1)', size: 18}
];

getStyles(shape: string) {
  if (shape === 'line') {
    return this.LINE_STYLES;
  } else if (shape === 'point') {
    return this.POINT_STYLES;
  } else if (shape === 'text') {
    return this.TEXT_STYLES;
  } else {
    return this.POLY_STYLES;
  }
}
}
