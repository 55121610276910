// import { Roles } from '../enums/roles.enum';
import { Component, OnInit } from '@angular/core';
import { User, Iuser } from '../interfaces/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfoComponent } from '../user-info/user-info.component';
import { AddUserComponent } from '../add-user/add-user.component';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
// import { AppStateService } from 'src/app/services/app-state.service';
import Swal from 'sweetalert2';
import { UserManagementService } from 'src/app/services/user-management.service';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users: User[] = [];
  searchString: string = '';

  currentLoggedInUserInfo = {
    role: ''
  };

  isBasin: boolean = false;

  constructor(
    private userService: UserManagementService,
    private modalSrv: NgbModal,
    private toastrService: ToastrService,
    private route: Router
  ) { }

  ngOnInit() {
    this.setCurrentLoggedInUserInfo();
    this.getUserList();

  }

  public createUserInfo(userData) {
    var obj = {};
    if (userData === null) {
      return obj;
    }
    obj['firstName'] = userData['firstName'];
    obj['lastName'] = userData['lastName'];
    obj['email'] = userData['email'];
    obj['mobileNo'] = userData['mobileNo'];
    obj['roles'] = userData['roles'];
    obj['userId'] = userData['userId'];
    return obj;

  }

  public createUserDetails(data: any) {
    let obj: any = {};
    if (isNullOrUndefined(data)) {
      return obj;
    }
    for (let key in data) {
      if (key.indexOf('secondary') == -1 && key.indexOf('bbox') == -1) {
        obj[key] = data[key];
      }
    }
    return obj;
  }

  setCurrentLoggedInUserInfo() {
    this.currentLoggedInUserInfo["role"] = this.userService.getLoggedInUserRole();
    this.currentLoggedInUserInfo["userLocationDetails"] = this.userService.getLoggedInUserDetailsJson();
  }

  //With API
  async getUserList() {
    this.userService.getUsers().subscribe((res: any) => {
      if (!res.result.success) {
        if (res.result.status != 406) {
          Swal.fire('Failure...', res.result.content, 'error');
        }
      } else {
        this.users = res.result.content;
      }
    }, (err) => {
      console.log("err:: ", err);
    })
  }

  editUser(user) {
    const modal = this.modalSrv.open(AddUserComponent, {
      size: 'lg'
    });
    var copyUser = Object.assign({}, user);
    copyUser.userDetailsJson = copyUser.userDetailsJson;
    modal.componentInstance.user = copyUser;
    modal.result.then((result) => {
      this.getUserList();
    }, (reason) => {
    });

  }

  getUserInfo(user) {
    const modal = this.modalSrv.open(UserInfoComponent, {
      size: 'lg'
    });
    modal.componentInstance.user = user;
  }


  async deleteUser(user) {
    if (confirm("Are you sure to delete " + user.firstName)) {
      // let deletePostData = {
      //   user_id: user.username
      // }
      // this.userService.deleteUserProjectMapping(deletePostData).subscribe((res: any) => {
        // let jsonStr = JSON.stringify(res);
        // let parsedJson = JSON.parse(jsonStr);
        // if (parsedJson == "Successful") {
          this.userService.deleteUser(user).subscribe(async (res: any) => {
            if (res.result.success) {
                Swal.fire('Success...', res.result.content, 'success');
                this.getUserList();
            } else {
              if (res.result.status != 406) {
                Swal.fire('Failure...', res.result.content, 'error');
              }
            }
          }); 
        // }
      // });
    }
  }

  changeRole(user) {

  }
  getJsonParse(string) {
    if (string === null || string === "")
      return {};
    return JSON.parse(string);
  }
  public addUser() {
    const modal = this.modalSrv.open(AddUserComponent, {
      size: 'lg'
    });
    modal.result.then((result) => {
      this.getUserList();
    }, (reason) => {
    });

  }


}