import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router,Event, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SharedConstantsModule } from 'src/app/shared/shared-constants.module';

@Component({
  selector: 'app-over-view',
  templateUrl: './over-view.component.html',
  styleUrls: ['./over-view.component.scss']
})
export class OverViewComponent implements OnInit {
  metaDataObj : any = {};
  filterType : any = 1;
  targetStructure: any;
  targetPressureMains: any;
  urlParams : any;
  navigationSubscription : Subscription
  urlParamsLength: number;
  dataArray: any[];
  metaData: any[];
  constructor(private route: ActivatedRoute,
              private router : Router ,
              private utilsService : UtilsService,
              private httpDataService : HttpService,
              private sharedConstants : SharedConstantsModule) {
                this.navigationSubscription = this.router.events.subscribe((event: Event) => {
                  if (event instanceof NavigationEnd) {
                    this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
                    if (this.urlParams['locType'] == this.sharedConstants.LOC_CONSTANT[0]) {
                      this.filterType = 1;
                    } else if (this.urlParams['locType'] == this.sharedConstants.LOC_CONSTANT[1]) {
                      this.filterType = 2;
                    } else if (this.urlParams['locType'] == this.sharedConstants.LOC_CONSTANT[2]) {
                      this.filterType = 3;
                    }
                    this.initializeOnStateLoad();
                  }
                  if (event instanceof NavigationError) {
                    console.log(event.error); // Hide loading indicator  // Present error to user
                  }
                });
               }

  ngOnInit() {
    this.initializeOnStateLoad();
  }

  ngOnDestroy(){
    this.navigationSubscription.unsubscribe();
  }

  initializeOnStateLoad(){
    this.getMetaData();
  }
  
  navigateToGIS(){
    this.router.navigate(['tsripms','geoportal','gis']);
  }
  getMetaData() {
    this.httpDataService.getMetaData(this.sharedConstants.projectUUID).subscribe((response: any) => {
        
      this.metaData = [];
      this.dataArray =[];
   if (this.urlParams['locType'] == this.sharedConstants.LOC_CONSTANT[0]) {

      Object.keys(response).forEach((key: any) => {
        if (key == 'Sita Rama LIS') {
          let data = response[key];
          let item = {};
          // const index = key.search(/[0-9]/g);
          // if (index > -1) {
          //   const packageNo = key.slice(index);
          //   key = key.split(packageNo).join('');
          //   key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          //   key += ' ' + packageNo;
          // }
          item['name'] = key;
          item['len'] = data['len'] ? this.utilsService.fixedToDecimal(data['len'],2) : '-';
          item['ddcanal'] = data['ddcanal'] ? this.utilsService.fixedToDecimal(data['ddcanal'],2) : '-';
          item['pmotors'] = data['pmotors'] ? data['pmotors'] : '-';
          item['pmhouse'] = data['pmhouse'] ? data['pmhouse'] : '-';
          item['ddofepump'] = data['ddofepump'] ? this.utilsService.fixedToDecimal(data['ddofepump'],2) : '-',
          item['nostru'] = data['nostru'] ? data['nostru'] : '-';
          item['prelen'] = data['prelen'] ? data['prelen'] : '-';
          item['prerows'] = data['prerows'] ? data['prerows'] : '-';

          item['lareq'] = data['lareq'] ? this.utilsService.fixedToThousandsDecimal(data['lareq'],2) : '-';
          item['laacq'] = data['laacq'] ? this.utilsService.fixedToThousandsDecimal(data['laacq'],2) : '-';
          item['blcla'] = data['blcla'] ? this.utilsService.fixedToThousandsDecimal(data['blcla'],2) : '-';

          item['agena'] = data['agena'] ? data['agena'] : '-';
          item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';
          item['revsanamt'] = data['revsanamt'] ?  this.utilsService.fixedToThousandsDecimal(data['revsanamt'],2) : '-';
          item['amtspent'] = data['amtspent'] ? this.utilsService.fixedToThousandsDecimal(data['amtspent'],2) : '-';
          item['billpend'] = data['billpend'] ? this.utilsService.fixedToThousandsDecimal(data['billpend'],2): '-';
          item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';
          this.metaData.push(item);
        }
        else{
          let data = response[key];
          let item = {};
          const index = key.search(/[0-9]/g);
          if (index > -1) {
            const packageNo = key.slice(index);
            key = key.split(packageNo).join('');
            key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
            key += ' ' + packageNo;
          }
          item['name'] = key;
          item['len'] = data['len'] ? this.utilsService.fixedToDecimal(data['len'],2) : '-';
          item['ddcanal'] = data['ddcanal'] ? this.utilsService.fixedToDecimal(data['ddcanal'],2) : '-';
          item['pmotors'] = data['pmotors'] ? data['pmotors'] : '-';
          item['pmhouse'] = data['pmhouse'] ? data['pmhouse'] : '-';
          item['ddofepump'] = data['ddofepump'] ? this.utilsService.fixedToDecimal(data['ddofepump'],2) : '-',
          item['nostru'] = data['nostru'] ? data['nostru'] : '-';
          item['prelen'] = data['prelen'] ? data['prelen'] : '-';
          item['prerows'] = data['prerows'] ? data['prerows'] : '-';

          item['lareq'] = data['lareq'] ? this.utilsService.fixedToThousandsDecimal(data['lareq'],2) : '-';
          item['laacq'] = data['laacq'] ? this.utilsService.fixedToThousandsDecimal(data['laacq'],2) : '-';
          item['blcla'] = data['blcla'] ? this.utilsService.fixedToThousandsDecimal(data['blcla'],2) : '-';

          item['agena'] = data['agena'] ? data['agena'] : '-';
          item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';
          item['revsanamt'] = data['revsanamt'] ?  this.utilsService.fixedToThousandsDecimal(data['revsanamt'],2) : '-';
          item['amtspent'] = data['amtspent'] ? this.utilsService.fixedToThousandsDecimal(data['amtspent'],2) : '-';
          item['billpend'] = data['billpend'] ? this.utilsService.fixedToThousandsDecimal(data['billpend'],2): '-';
          item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';
          this.dataArray.push(item);
        }
      })
    }
      
        else {
          this.metaData = [];
        let data = response[this.urlParams['packageName']];
        let item = {};
        let key = this.urlParams['packageName'];
        const index = key.search(/[0-9]/g);
        if (index > -1) {
          const packageNo = key.slice(index);
          key = key.split(packageNo).join('');
          key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          key += ' ' + packageNo;
        }

        item['name'] = key;
        item['len'] = data['len'] ? this.utilsService.fixedToDecimal(data['len'],2) : '-';
        item['ddcanal'] = data['ddcanal'] ? this.utilsService.fixedToDecimal(data['ddcanal'],2) : '-';
        item['pmotors'] = data['pmotors'] ? data['pmotors'] : '-';
        item['pmhouse'] = data['pmhouse'] ? data['pmhouse'] : '-';
        item['ddofepump'] = data['ddofepump'] ? this.utilsService.fixedToDecimal(data['ddofepump'],2) : '-',
        item['nostru'] = data['nostru'] ? data['nostru'] : '-';
        item['prelen'] = data['prelen'] ? data['prelen'] : '-';
        item['prerows'] = data['prerows'] ? data['prerows'] : '-';

        item['lareq'] = data['lareq'] ? this.utilsService.fixedToThousandsDecimal(data['lareq'],2) : '-';
        item['laacq'] = data['laacq'] ? this.utilsService.fixedToThousandsDecimal(data['laacq'],2) : '-';
        item['blcla'] = data['blcla'] ? this.utilsService.fixedToThousandsDecimal(data['blcla'],2) : '-';

        item['agena'] = data['agena'] ? data['agena'] : '-';
        item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';
        item['revsanamt'] = data['revsanamt'] ?  this.utilsService.fixedToThousandsDecimal(data['revsanamt'],2) : '-';
        item['amtspent'] = data['amtspent'] ? this.utilsService.fixedToThousandsDecimal(data['amtspent'],2) : '-';
        item['billpend'] = data['billpend'] ? this.utilsService.fixedToThousandsDecimal(data['billpend'],2): '-';
        item['revidatecompl'] = data['revidatecompl'] ? data['revidatecompl'] : '-';

        this.metaData.push(item);

      }
  
  })
}
}
