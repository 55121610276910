import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @Input('user') user: any;

  constructor(
    private modal: NgbActiveModal
  ) { }

  ngOnInit() {
    // console.log("User:: ",this.user);
  }

  closeModal() {
    this.modal.dismiss();
  }
}
