import {
    NgModule
  } from '@angular/core';
  import {
    CommonModule
  } from '@angular/common';

  @NgModule({
    declarations: [],
    imports: [
      CommonModule
    ]
  })
  export class ConstantsModule {

  // API_URL_USER = "http://kaleshwaram.vassarlabs.com/auth/";
  API_URL_USER = "http://tsiprms.vassarlabs.com/api/ppm/usrmgmt/"
  // API_URL_USER = "http://kaleshwaram-wris.vassarlabs.com/api/ppm/usrmgmt/";
  PUBLIC_KEY = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCuDEWABVXfJ9se3bqfYC7zPnMjmivjb7XbvfIJiQEosQ48Iws6kMfAsPyu1aHiI1PSy0PvpXR08ZNyUKzTdqb3BZ7tIprSRPtGZKV9hoWQEcSL7w+Og6j1pmvlT5vxMbnDrzlbvV6EDG11I9CFULWw2w4yQD2vm9z7KSAN9eawUwIDAQAB";
  ROLEBASEDSIDETABMAP = {
    "SUPER_ADMIN" : ["usermanagement"],
    "ADMIN":["usermanagement"],
    // "VALIDATION_AGENCY" : ["approvals","edit-weightages"],
    // "STATE_NODAL_OFFICER" :["datasubmitapprovals","excelupload","exceldownload","forminput","shapefileupload"]
  };
  MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000;
  DEBUGGER = false;
  EVENTGEN_YEAR = 'yyyy';
  EVENTGEN_MONTH = 'yyyyMM';
  EVENTGEN_DAY = 'yyyyMMdd';
  KERALA_AREA = 38861.8486511009;
  mmToKm = 0.000001;
  cubicKmToTMC = 35.314666721;
  McmToTMC = 28.31684;
  BS_DATEPICKER_DATE_FORMAT = {
    YEAR: 'Yearly',
    MONTH: 'Monthly',
    DAY: 'Daily',
  }
  DATEPICKER_DATE_FORMAT = {
    YEAR: 'yyyy',
    MONTH: 'yyyyMM',
    DAY: 'yyyyMMdd',
    SEASON: 'season'
  };
}
