import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ProjectDashboardComponent } from './pages/project-dashboard/project-dashboard.component';
import { OverViewComponent } from './pages/over-view/over-view.component';
import { LoginComponent } from './login/login.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { GisMapComponent } from './pages/gis-map/gis-map.component';
import { ComponentDetailsComponent } from './pages/component-details/component-details.component';
import { GeoportalLandingComponent } from './geoportal-landing/geoportal-landing.component';
import { ReportComponent } from './pages/report/report.component';
import { ReportAlertMobileComponent } from './pages/report/report-alert-mobile/report-alert-mobile.component';
import { ReportDataValidationComponent } from './pages/report/report-data-validation/report-data-validation.component';

const routes: Routes = [
  {path: '', redirectTo: 'tsripms', pathMatch: 'full'},
  {path: 'tsripms', component: LandingPageComponent},
  {path: 'tsripms/projectDashboard', component: ProjectDashboardComponent},
  {path: 'tsripms/projectDashboard/projectDetails', component: ProjectDetailsComponent},
  {path: 'tsripms/projectDashboard/overview', component: OverViewComponent},
  {path: 'tsripms/projectDashboard/report', component: ReportComponent},
  {path: 'tsripms/login', component: LoginComponent},
  {path: 'tsripms/projectDashboard/component', component: ComponentDetailsComponent},
  {path: 'tsripms/gis',component : GisMapComponent},
  {path : 'tsripms/geoportal/gis',component : GeoportalLandingComponent},
  {path : 'tsripms/projectDashboard/reportAlert',component:ReportAlertMobileComponent},
  {path : 'tsripms/reportDataValidation' ,component:ReportDataValidationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
