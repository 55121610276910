import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceConversion'
})
export class DistanceConversionPipe implements PipeTransform {

  transform(value: any, args?: any, args1?: any): any {
    let temp, decimalLimit = (args1 || 2);
    if (value == null) {
      return;
    }
    if (args === 'M-M') {
      temp = value;
      return this.formatData(temp, decimalLimit);
    }
    if (args === 'M-KM') {
      temp = value * 0.001;
      return this.formatData(temp, decimalLimit);
    }
    if (args === 'M-FT') {
      temp = value * 3.28084;
      return this.formatData(temp, decimalLimit);
    }
    if (args === 'M-YD') {
      temp = value * 1.09361;
      return this.formatData(temp, decimalLimit);
    }
    if (args === 'M-NM') {
      temp = value * 0.000539957;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-SQM') {
      temp = value;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-SQKM') {
      temp = value * 0.000001;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-H') {
      temp = value * 0.0001;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-SQYD') {
      temp = value * 1.19599;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-SQFT') {
      temp = value * 10.7639;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-AC') {
      temp = value * 0.00024;
      return this.formatData(temp, decimalLimit);
    }

    if (args === 'SQM-SQMI') {
      temp = value * 3.86;
      return this.formatData(temp, decimalLimit);
    }


  }
  public formatData(value, decimal) {
    return value.toLocaleString(undefined, { minimumFractionDigits: decimal, maximumFractionDigits: decimal });
  }

}
