import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { HttpService } from 'src/app/services/http.service';
import { Router, ActivatedRoute, NavigationError, NavigationEnd, NavigationStart, Event } from '@angular/router';
import { ProjConstants } from 'src/app/modules/table/common/constants/proj.constant';
import { DataTableEventsService } from 'src/app/modules/table/common/services/datatable.events.service';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { ReportService } from './report.service';
import { SharedConstantsModule } from 'src/app/shared/shared-constants.module';
import { KeyValue } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  typeList: any = ['Land Acquisition', 'Financial Progress', 'Physical Progress', 'Alerts'];
  alertTableDDList: any = ['Yes', 'No'];
  alertTableDDSelection: any = {};
  packageList: any = {};
  physicalDD: any = {
  }
  componentApiMappingNew = {
    'CANAL': 'GRAVITY CANAL',
    'PUMP_HOUSE': 'PUMP HOUSE',
    'STRUCTURES': 'STRUCTURES',
    'PRESSURE_MAIN': 'PRESSURE MAIN'
  };
  componentApiMappingNewRev = {
    'GRAVITY CANAL': 'CANAL',
    'PUMP HOUSE': 'PUMP_HOUSE',
    'STRUCTURES': 'STRUCTURES',
    'PRESSURE MAIN': 'PRESSURE_MAIN'
  }
  activityNameMapping = {
    'cc_lining': 'CC Lining',
    'cement_concrete': 'Cement Concrete',
    'shortcreting': 'Shotcreting',
    'earth_work_embankmant': 'Earth Work Embankment',
    'earth_work_excavation': 'Earth Work Excavation',
    'supply': 'Supply',
    'laying_jointing': 'Laying Jointing',
    'cc_walls': 'CC Walls',
    'no_of_structures': 'No of Structures',
    'ewe': 'Earth Work Excavation',
    'cc': 'Cement Concrete',
    'transformer_yard': 'Transformer Yard',
    'control_prot': ' Control Protection',
    'scada': 'SCADA SYSTEM',
    'eot_erection': 'EOT Erection',
    'eot_supply': 'EOT Supply',
  };
  packageListRev: any = {
    'All': 'All',
    'Package 1': 'PACKAGE1',
    'Package 2': 'PACKAGE2',
    'Package 3': 'PACKAGE3',
    'Package 4': 'PACKAGE4',
    'Package 5': 'PACKAGE5',
    'Package 6': 'PACKAGE6',
    'Package 7': 'PACKAGE7',
    'Package 8': 'PACKAGE8',
  };
  packageListMapping: any = {
    ALL: 'All',
    PACKAGE1: 'Package 1',
    PACKAGE2: 'Package 2',
    PACKAGE3: 'Package 3',
    PACKAGE4: 'Package 4',
    PACKAGE5: 'Package 5',
    PACKAGE6: 'Package 6',
    PACKAGE7: 'Package 7',
    PACKAGE8: 'Package 8',
  }
  typeListChartMapping = {
    'Land Acquisition': 'land acquisition',
    'Financial Progress': 'financial',
    'Physical Progress': 'physical',
    'Alerts': 'alerts'
  };
  selectTypeListFromURLMapping = {
    'land acquisition': 'Land Acquisition',
    'financial': 'Financial Progress',
    'physical': 'Physical Progress',
    'alerts': 'Alerts'
  }
  selectedFilterParams: any = {};
  toDate: any;
  fromDate: any;
  tableConst: any = {};
  tableSpinner: boolean = false;
  tableData: any = [];
  dataArray: any = [];
  urlParams: any = {};
  hie: any;
  child: any;
  parent: any;
  location: any
  breadcrumb: any = [];
  subsciptions: Subscription[] = [];
  navigationSubscription: Subscription;
  totalContractual: any = 0;
  totalSpentData: any = 0;
  totalStateSpent: any = 0;
  totalCorpSpent: any = 0;
  totalPendingBal: any = 0;
  alertTableHeader: any;
  minDate: any;
  maxDate: any;
  downloadOptions: any = ['pdf', 'xlsx'];
  searchText: string;
  searchTextHolder: string;
  filteredRecords: any = []
  totalDataCopy: any = [];
  uuid: any;
  actionHeaderSelection: boolean = false;
  headerMetaData: any = {};
  enddatereports ;
  startdatereports ;
  columnConst = {
    ['PROJECT']: {
      1: {
        'reqdF': 'Forest (Acres)',
        'reqdROFR': 'ROFR (Acres)',
        'reqdTF': 'Total Forest (Acres)',
        'reqdRL': 'Revenue Land (Acres)',
        'reqd': 'Total Required(Acres)'
      },
      3: {
        'acqdF': 'Forest (Acres)',
        'acqdROFR': 'ROFR (Acres)',
        'acqdTF': 'Total Forest (Acres)',
        'acqdRL': 'Revenue Land (Acres)',
        'acqd': 'Total Acquired (Acres)'
      },
    },
    ['PACKAGE']: {
      3: {
        'reqdF': 'Forest (Acres)',
        'reqdROFR': 'ROFR (Acres)',
        'reqdTF': 'Total Forest (Acres)',
        'reqdRL': 'Revenue Land (Acres)',
        'reqd': 'Total Required(Acres)'
      },
      5: {
        'acqdF': 'Forest (Acres)',
        'acqdROFR': 'ROFR (Acres)',
        'acqdTF': 'Total Forest (Acres)',
        'acqdRL': 'Revenue Land (Acres)',
        'acqd': 'Total Acquired (Acres)'
      },
      6: {
        'balSDR': 'SDR (Acres)',
        'balPN': 'Preliminary Notification (Acres)',
        'balPD': 'Publication of Declaration (Acres)',
        'balDN': 'Draft Notification (Acres)',
        'balPV': 'Personal Verification (Acres)',
        'balAward': 'Award (Acres)',
        'bal': 'Total Balance (Acres)',
        'balTG': 'Token Generated (Crs)'
      }
    }
  }
  constructor(private utilsService: UtilsService, private httpDataService: HttpService,
    private router: Router, private route: ActivatedRoute, private tableEventsHandler: DataTableEventsService,
    private modalSrv: NgbModal, private reportSrv: ReportService, private constants: SharedConstantsModule,private toastr:ToastrService ) {
    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        this.initializeOnStateLoad();
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Hide loading indicator  // Present error to user
      }
    });
  }

  ngOnInit() {
    this.subscribeEvents();
    this.initializeOnStateLoad();
  }

  initializeOnStateLoad() {
    this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
    this.getToFromDates();
    this.selectedFilterParams['from'] = this.urlParams['sDate'] ? this.getDateFromParams(this.urlParams['sDate']) : this.fromDate;
    this.selectedFilterParams['to'] = this.urlParams['eDate'] ? this.getDateFromParams(this.urlParams['eDate']) : this.toDate;
    const hieType = this.urlParams['chartType'];
    this.hie = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[hieType]];
    this.parent = this.urlParams['parent'] ? this.urlParams['parent'] : 'SRLIP';
    this.child = this.urlParams['child'] ? this.urlParams['child'] : this.hie[1];
    this.location = this.urlParams['location'] ? this.urlParams['location'] : 'SLRIP';

    this.enddatereports = this.selectedFilterParams['to'];
    this.startdatereports = this.selectedFilterParams['from'];

    this.selectedFilterParams['package'] = this.urlParams['locType'] != this.reportSrv.locationList[2] ? this.urlParams['packageName'] ? this.urlParams['packageName'] : 'ALL' : this.urlParams['componentName'];
    this.selectedFilterParams['type'] = this.selectTypeListFromURLMapping[hieType];
    let loc = this.location.split('&');
    if ((this.urlParams['packageName'] && (this.urlParams['packageName'] != this.packageListRev[loc[1]])) ||
      !isNullOrUndefined(this.urlParams['componentName'])) {
      if (this.urlParams['locType'] != this.reportSrv.locationList[2]) {
        this.urlParams['parent'] = this.urlParams['parent'] + '&' + this.child;
        this.urlParams['child'] = this.hie.indexOf(this.child) == this.hie.length - 1 ? this.hie[this.hie.indexOf(this.child)] : this.hie[this.hie.indexOf(this.child) + 1];
        this.urlParams['location'] = loc[0] + '&' + this.packageListMapping[this.urlParams['packageName']];
        this.router.navigate(['tsripms', 'projectDashboard', 'report', this.urlParams]);
      }
      else if (this.urlParams['chartType'] == 'physical' &&
        this.child.toUpperCase() != this.urlParams['locType']) {
        this.getUrlParamsForPhysical();
        this.router.navigate(['tsripms', 'projectDashboard', 'report', this.urlParams]);
      }
    }
    if (this.urlParams.chartType == 'financial') {
      this.getFinancialTable();
    }
    else if (this.urlParams.chartType == 'land acquisition') {
      this.getLATable();
    }
    else if (this.urlParams['chartType'] == 'alerts') {
      this.getAlertTable();
    }
    else if (this.urlParams['chartType'] == 'physical') {
      this.getPhysicalTable();
    }
    this.generateBreadCrumb();
  }

  getUrlParamsForPhysical() {
    this.urlParams['parent'] = this.urlParams['parent'] + '&' + this.urlParams['child'];
    this.urlParams['child'] = this.hie.indexOf(this.child) == this.hie.length - 1 ? this.hie[this.hie.indexOf(this.child) - 1] : this.hie[this.hie.indexOf(this.child) + 1];
    this.urlParams['location'] = this.urlParams['location'] + '&' + this.urlParams['componentName'];
  }


  unsubscribeEvents() {
    this.subsciptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  getDateString(obj) {
    let monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let index = obj.month;
    let month = monthList[index - 1];
    return month + ' ' + obj.day + ', ' + obj.year;
  }

  getDateObj(date) {
    let year = date.split('-')[0];
    let month = date.split('-')[1];
    let day = date.split('-')[2];
    let obj: any;
    obj = {
      year: Number(year),
      month: Number(month),
      day: Number(day)
    }
    return obj;
  }

  getToFromDates() {
    // let timeConstant = 31536000000;
    // let initDate = new Date().getTime();
    // this.minDate = this.utilsService.getDateInFormatForecast("YYYY-MM-DD",(initDate-(timeConstant)));
    // this.minDate = this.getDateObj(this.minDate);
    // this.maxDate = this.utilsService.getDateInFormatForecast("YYYY-MM-DD", (initDate));
    // this.maxDate = this.getDateObj(this.maxDate);
    // let initialDate = this.utilsService.getDateInFormatForecast("YYYY-MM-DD",(initDate - (86400000 * 1)));  // last 24 hours
    let current1 = new Date();
    current1.setDate(1);
    current1.setMonth(0);
    current1.setFullYear(2021);
    let timestamp1 = current1.getTime();
    let timestamp2 = new Date().getTime();
    let from = this.utilsService.getDateInFormatForecast("YYYY-MM-DD", timestamp1);
    // let to = this.utilsService.getDateInFormatForecast("YYYY-MM-DD", (timestamp2 - (86400000 * 1)));  //last 24 hrs
    let to = this.utilsService.getDateInFormatForecast("YYYY-MM-DD", 1614969000000);  // 6 March
    this.fromDate = this.getDateObj(from);
    this.toDate = this.getDateObj(to);

  }
  getDateForParams(type?: any) {
    if (type.toUpperCase() == 'TO') {
      let month = this.selectedFilterParams['to']['month'] < 9 ? '0' + this.selectedFilterParams['to']['month'].toString() : this.selectedFilterParams['to']['month'].toString();
      let date = this.selectedFilterParams['to']['day'] < 9 ? '0' + this.selectedFilterParams['to']['day'].toString() : this.selectedFilterParams['to']['day'].toString();
      return this.selectedFilterParams['to']['year'] + month + date;
    }
    else if (type.toUpperCase() == 'FROM') {
      let month = this.selectedFilterParams['from']['month'] < 9 ? '0' + this.selectedFilterParams['from']['month'].toString() : this.selectedFilterParams['from']['month'].toString();
      let date = this.selectedFilterParams['from']['day'] < 9 ? '0' + this.selectedFilterParams['from']['day'].toString() : this.selectedFilterParams['from']['day'].toString();
      return this.selectedFilterParams['from']['year'] + month + date;
    }
  }
  getDateFromParams(date) {
    let year = date.slice(0, 4);
    let month = date.slice(4, 6);
    let day = date.slice(6);
    let obj: any;
    obj = {
      year: Number(year),
      month: Number(month),
      day: Number(day)
    }
    return obj;
  }
  getHeader() {
    return 'Report Dashboard For ' + this.selectedFilterParams['type'] + ' From ' + this.getDateString(this.selectedFilterParams['from']) +
      ' To ' + this.getDateString(this.selectedFilterParams['to']) + ' (' + this.selectedFilterParams['package'].charAt(0).toUpperCase() + this.selectedFilterParams['package'].substring(1).toLowerCase() + ')';
  }
  getFinancialTable() {
    this.tableConst = {};
    this.tableSpinner = true;
    this.headerMetaData = this.getHeader();
    let totalRow = {
      contractualSanctioned: 0,
      revisedSanctionedAmount: 0,
      amountSpent: 0,
      corpSpent: 0,
      stateSpent: 0,
      pendingBalanceData: 0,
      billsSpending: 0
      // spentData : 0,
      // stateSpentData : 0,
      // corpSpentData : 0,
      // pendingBalanceData : 0
    }

    this.httpDataService.getFinancialLevelData().subscribe((res) => {
      if (isNullOrUndefined(this.urlParams['packageName'])) {
        this.dataArray = [];
        this.tableData = [];
        // this.totalContractual = 0;
        // this.totalSpentData = 0;
        // this.totalStateSpent = 0;
        // this.totalCorpSpent = 0;
        // this.totalPendingBal = 0;
        this.packageList = {};
        this.packageList['ALL'] = 'All';
        Object.keys(res).forEach((key: any) => {
          if (key != 'SITA-RAMA-PPM') {
            let data = res[key];
            let item = {};
            this.packageList[key] = this.packageListMapping[key];
            const index = key.search(/[0-9]/g);
            if (index > -1) {
              const packageNo = key.slice(index);
              key = key.split(packageNo).join('');
              key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
              key += ' ' + packageNo;
            }
            item['name'] = key;
            item['agencyName'] = data['agencyName'];
            item['revisedCompletedDate'] = data['revisedCompletedDate'];
            // item['contractualSanctioned'] = data['revisedSanctionedAmount'];
            item['revisedSanctionedAmount'] = this.utilsService.fixedToThousandsDecimal(data['revisedSanctionedAmount'], 2);
            item['amountSpent'] = this.utilsService.fixedToThousandsDecimal(data['amountSpent'], 2);
            item['stateSpent'] = this.utilsService.fixedToThousandsDecimal(data['stateSpent'], 2);
            item['corpSpent'] = this.utilsService.fixedToThousandsDecimal(data['corpSpent'], 2);
            item['billsSpending'] = this.utilsService.fixedToThousandsDecimal(data['billsSpending'], 2);
            this.tableData.push(item);
            totalRow['revisedSanctionedAmount'] += +this.utilsService.fixedFromThousandDecimal(data['revisedSanctionedAmount']);
            totalRow['amountSpent'] += +this.utilsService.fixedFromThousandDecimal(data['amountSpent']);
            totalRow['corpSpent'] += +this.utilsService.fixedFromThousandDecimal(data['corpSpent']);
            totalRow['stateSpent'] += +this.utilsService.fixedFromThousandDecimal(item['stateSpent']);
            totalRow['billsSpending'] += +this.utilsService.fixedFromThousandDecimal(item['billsSpending']);
          }
        })

        let totalItem = {};
        totalItem['name'] = 'TOTAL';
        totalItem['entityId'] = -1
        totalItem['revisedSanctionedAmount'] = this.utilsService.fixedToThousandsDecimal(totalRow['revisedSanctionedAmount'], 2);
        totalItem['amountSpent'] = this.utilsService.fixedToThousandsDecimal(totalRow['amountSpent'], 2);
        totalItem['stateSpent'] = this.utilsService.fixedToThousandsDecimal(totalRow['stateSpent'], 2);
        totalItem['corpSpent'] = this.utilsService.fixedToThousandsDecimal(totalRow['corpSpent'], 2);
        totalItem['billsSpending'] = this.utilsService.fixedToThousandsDecimal(totalRow['billsSpending'], 2);
        this.tableData.push(totalItem);

        this.tableData.sort((t1, t2) => {
          const name1 = t1.name.toLowerCase();
          const name2 = t2.name.toLowerCase();
          if (name1 > name2) { return 1; }
          if (name1 < name2) { return -1; }
          return 0;
        });
        this.tableData.forEach((data: any) => {
          this.dataArray.push(data);
        });
      }
      else {
        this.dataArray = [];
        this.packageList = {};
        let data = res[this.urlParams['packageName']];
        let item = {};
        const index = this.urlParams['packageName'].search(/[0-9]/g);
        let key = this.urlParams['packageName'];
        if (index > -1) {
          const packageNo = this.urlParams['packageName'].slice(index);
          key = this.urlParams['packageName'].split(packageNo).join('');
          key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          key += ' ' + packageNo;
        }
        this.packageList[this.urlParams['packageName']] = key;
        item['name'] = key;
        item['agencyName'] = data['agencyName'];
        item['revisedCompletedDate'] = this.utilsService.fixedToThousandsDecimal(data['revisedSanctionedAmount'], 2);
        item['revisedSanctionedAmount'] = this.utilsService.fixedToThousandsDecimal(data['revisedSanctionedAmount'], 2);
        item['amountSpent'] = this.utilsService.fixedToThousandsDecimal(data['amountSpent'], 2);
        item['stateSpent'] = this.utilsService.fixedToThousandsDecimal(data['stateSpent'], 2);
        item['corpSpent'] = this.utilsService.fixedToThousandsDecimal(data['corpSpent'], 2);
        item['billsSpending'] = data['billsSpending'];
        // this.tableData2.push(item);
        this.dataArray.push(item);
        this.tableSpinner = false;
      }

      this.tableSpinner = false;
      this.tableConst = {
        tableInfo: {
          type: 'dataTable',
          title: '',
        },
        css: {
          tableClass: 'table table-bordered table-striped align-items-center',
          theadClass: 'thead-dark text-center'
        },
        sortOptions: {
          sortIndex: -1,
          sortType: 'asc'
        },
        searchFilter: true,
        breadcrumbCons: {
          show: true,
          queryparams: []
        },
        downloadButton: {
          display: true,
          text: 'Download',
          align: 'right',       //where to align the element within div i.e left, center or right
          order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
        },
        downloadOptions: [{
          showDownload: true,
          downloadType: 'xlsx',
          downloadFileName: 'Financial Report',
          downloadAll: true,  //pass false if only current page data is to be downloaded
          metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
        },
        {
          showDownload: true,
          downloadType: 'pdf',
          downloadFileName: 'Financial Report',
          downloadAll: true,  //pass false if only current page data is to be downloaded
          metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
        }
        ],
        paginatorOptions: {
          visible: false,
          limit: 15,
          maxSize: 5,     // Max pages to display on the link
          // directionLinks: 'false',
          // autoHide: 'false',
          // previousLabel: 'prev',
          // nextLabel: 'nex',
          dropdownOptions: [{
            value: 15,
            display: '15'
          },
          {
            value: 25,
            display: '25'
          },
          {
            value: 50,
            display: '50'
          },
          {
            value: -1,
            display: 'All'
          }]
        },
        tableHeaderFixerInfo: {
          headerFix: true,
          colFix: false,
          noOfCol: 2
        },
        totalRow: {
          name: 'total',
          jsonKey: '-1',
          rowspan: 4,
          colspan: 2,
          position: 'bottom',
          class: 'total-row'
        },
        indexCons: {
          show: true,
          class: 'tc-bg-01'
        },
        columns: [
          {
            name: 'Package',
            jsonKey: {
              path: 'name'
            },
            dataType: 'LOCATION',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
          {
            name: 'Agency Name',
            jsonKey: {
              path: 'agencyName'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
          {
            name: 'Revised Contract Completion Date',
            jsonKey: {
              path: 'revisedCompletedDate'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
          {
            name: 'Sanctioned Amount(cr)',
            jsonKey: {
              path: 'revisedSanctionedAmount'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
          {
            name: 'Amount Spent(cr)',
            jsonKey: {
              path: 'amountSpent'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          }, {
            name: 'Govt. Spent(cr)',
            jsonKey: {
              path: 'stateSpent'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          }, {
            name: 'Corporation Spent(cr)',
            jsonKey: {
              path: 'corpSpent'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
          {
            name: 'Bills Pending (cr)',
            jsonKey: {
              path: 'billsSpending'
            },
            dataType: 'TEXT',
            defaultValue: '-',
            class: 'tc-bg-03',
          },
        ]
      }
      this.tableConst['columns'][0].name = this.child.toUpperCase();
      this.tableConst['breadcrumbCons'].queryparams = this.breadcrumb;
      if (this.hie.indexOf(this.child) == this.hie.length - 1) {
        this.tableConst['columns'][0].dataType = 'TEXT';
      }
    })
  }

  getLATable() {
    this.tableConst = {};
    this.tableSpinner = true;
    this.headerMetaData = this.getHeader();
    this.httpDataService.getLALevelData().subscribe((res) => {
      if (isNullOrUndefined(this.urlParams['packageName'])) {
        this.dataArray = [];
        this.tableData = [];
        let totalRow = {
          reqd: 0,
          redF: 0,
          reqdROFR: 0,
          reqdTF: 0,
          reqdRL: 0,
          reqdTR: 0,
          bal: 0,
          acqd: 0,
          acqdF: 0,
          acqdROFR: 0,
          acqdTF: 0,
          acqdRL: 0,
          acqdTB: 0,
          revenueLandReq: 0
        };
        this.packageList = {};
        this.packageList['ALL'] = 'All';
        Object.keys(res).forEach((key: any) => {
          if (key != 'SITA-RAMA-PPM') {
            let data = res[key];
            let item = {};
            this.packageList[key] = this.packageListMapping[key];
            const index = key.search(/[0-9]/g);
            if (index > -1) {
              const packageNo = key.slice(index);
              key = key.split(packageNo).join('');
              key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
              key += ' ' + packageNo;
            }
            item['name'] = key;
            item['reqd'] = this.utilsService.fixedToThousandsDecimal(data['la_required'], 2);
            item['reqdF'] = '-'
            item['reqdROFR'] = '-'
            item['reqdTF'] = '-'
            item['reqdRL'] = '-'
            item['reqdTR'] = '-'

            item['bal'] = this.utilsService.fixedToThousandsDecimal(data['balance_la'], 2);

            item['acqd'] = this.utilsService.fixedToThousandsDecimal(data['la_acquired'], 2);
            item['acqdF'] = '-'
            item['acqdROFR'] = '-'
            item['acqdTF'] = '-'
            item['acqdRL'] = '-'
            item['acqdTB'] = '-';

            item['revenueLandReq'] = '-';
            // let totalRow = {
            //   reqd : 0,
            //   redF : 0,
            //   reqdROFR : 0,
            //   reqdTF : 0,
            //   reqdRL : 0,
            //   reqdTR : 0,
            //   balF : 0,
            //   balROFR : 0,
            //   balTF : 0,
            //   balRL : 0,
            //   balTA : 0,
            //   acqdF : 0,
            //   acqdROFR : 0,
            //   acqdTF : 0,
            //   acqdRL : 0,
            //   acqdTB : 0,
            //   revenueLandReq : 0
            // };
            totalRow['reqd'] += +this.utilsService.fixedFromThousandDecimal(data['la_required']);
            totalRow['acqd'] += +this.utilsService.fixedFromThousandDecimal(data['la_acquired']);
            totalRow['bal'] += +this.utilsService.fixedFromThousandDecimal(data['balance_la']);
            // totalRow['reqdF'] += item['reqdF'];
            // totalRow['reqdROFR'] += item['reqdROFR'];
            // totalRow['reqdTF'] += item['reqdTF'];
            // totalRow['reqdRL'] += item['reqdRL'];
            // totalRow['reqdTR'] += item['reqdTR'];  
            // totalRow['balF'] += item['balF'];
            // totalRow['balROFR'] += item['balROFR'];
            // totalRow['balTF'] += item['balTF'];
            // totalRow['balRL'] += item['balRL'];
            // totalRow['balTA'] += item['balTA'];
            // totalRow['acqdF'] += item['acqdF'];
            // totalRow['acqdTF'] += item['acqdTF'];
            // totalRow['acqdRL'] += item['acqdRL'];
            // totalRow['acqdTB'] += item['acqdTB'];
            // totalRow['revenueLandReq'] += item['revenueLandReq'];
            this.tableData.push(item);
          }
        })
        let totalItem = {};
        totalItem['name'] = 'TOTAL';
        totalItem['entityId'] = -1
        totalItem['reqd'] = this.utilsService.fixedToThousandsDecimal(totalRow['reqd'], 2);
        totalItem['acqd'] = this.utilsService.fixedToThousandsDecimal(totalRow['acqd'], 2);
        totalItem['bal'] = this.utilsService.fixedToThousandsDecimal(totalRow['bal'], 2);
        //     totalItem['reqdF'] = totalRow['reqdF'];
        //     totalItem['reqdROFR'] = totalRow['reqdROFR'];
        //     totalItem['reqdTF'] = totalRow['reqdTF'];
        //     totalItem['reqdRL'] = totalRow['reqdRL'];
        //     totalItem['reqdTR'] = totalRow['reqdTR'];  
        //     totalItem['balF'] = totalRow['balF'];
        //     totalItem['balROFR'] = totalRow['balROFR'];
        //     totalItem['balTF'] = totalRow['balTF'];
        //     totalItem['balRL'] = totalRow['balRL'];
        //     totalItem['balTA'] = totalRow['balTA'];
        //     totalItem['acqdF'] = totalRow['acqdF'];
        //     totalItem['acqdTF'] = totalRow['acqdTF'];
        //     totalItem['acqdRL'] = totalRow['acqdRL'];
        //     totalItem['acqdTB'] = totalRow['acqdTB'];
        //     totalItem['revenueLandReq'] = totalRow['revenueLandReq'];
        this.tableData.push(totalItem);

        this.tableData.sort((t1, t2) => {
          const name1 = t1.name.toLowerCase();
          const name2 = t2.name.toLowerCase();
          if (name1 > name2) { return 1; }
          if (name1 < name2) { return -1; }
          return 0;
        });
        this.tableData.forEach((data: any) => {
          this.dataArray.push(data);
        });
        this.tableSpinner = false;
        this.tableConst = {
          tableInfo: {
            type: 'dataTable',
            title: '',
          },
          css: {
            tableClass: 'table table-bordered table-striped align-items-center',
            theadClass: 'thead-dark text-center'
          },
          sortOptions: {
            sortIndex: -1,
            sortType: 'asc'
          },
          searchFilter: true,
          breadcrumbCons: {
            show: true,
            queryparams: []
          },
          downloadButton: {
            display: true,
            text: 'Download',
            align: 'right',       //where to align the element within div i.e left, center or right
            order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
          },
          downloadOptions: [{
            showDownload: true,
            downloadType: 'xlsx',
            downloadFileName: 'LA Report',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          },
          {
            showDownload: true,
            downloadType: 'pdf',
            downloadFileName: 'LA Report',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          }
          ],
          paginatorOptions: {
            visible: false,
            limit: 15,
            maxSize: 5,     // Max pages to display on the link
            // directionLinks: 'false',
            // autoHide: 'false',
            // previousLabel: 'prev',
            // nextLabel: 'nex',
            dropdownOptions: [{
              value: 15,
              display: '15'
            },
            {
              value: 25,
              display: '25'
            },
            {
              value: 50,
              display: '50'
            },
            {
              value: -1,
              display: 'All'
            }]
          },
          tableHeaderFixerInfo: {
            headerFix: true,
            colFix: false,
            noOfCol: 2
          },
          totalRow: {
            name: 'total',
            jsonKey: '-1',
            rowspan: 4,
            position: 'bottom',
            class: 'total-row'
          },
          indexCons: {
            show: true,
            class: 'tc-bg-01'
          },
          columns: [
            {
              name: 'Package',
              jsonKey: {
                path: 'name'
              },
              dataType: 'LOCATION',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Required(Acres)',
              class: 'tc-bg-05',
              expand: true,
              jsonKey: {
                path: 'reqd'
              },
              dataType: 'TEXT',
              defaultValue: '-',
            },
            {
              name: 'Revenue Land Requistioned(Acres)',
              jsonKey: {
                path: 'revenueLandReq'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Acquired (Acres)',
              class: 'tc-bg-05',
              expand: true,
              jsonKey: {
                path: 'acqd'
              },
              dataType: 'TEXT',
              defaultValue: '-',
            },
            {
              name: 'Balance (Acres)',
              jsonKey: {
                path: 'bal'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-04',
            }
          ]
        }
      } else {
        this.dataArray = [];
        this.tableData = [];
        let data = res[this.urlParams['packageName']];
        let item = {};
        const index = this.urlParams['packageName'].search(/[0-9]/g);
        let key = this.urlParams['packageName'];
        if (index > -1) {
          const packageNo = this.urlParams['packageName'].slice(index);
          key = this.urlParams['packageName'].split(packageNo).join('');
          key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          key += ' ' + packageNo;
        }
        this.packageList = {};
        this.packageList[this.urlParams['packageName']] = key;
        item['name'] = key;
        item['district'] = '-';
        item['mandal'] = '-';
        item['reqd'] = this.utilsService.fixedToThousandsDecimal(data['la_required'], 2);
        item['reqdF'] = '-'
        item['reqdROFR'] = '-'
        item['reqdTF'] = '-'
        item['reqdRL'] = '-'
        item['reqdTR'] = '-'

        item['bal'] = this.utilsService.fixedToThousandsDecimal(data['balance_la'], 2)
        item['balSDR'] = '-'
        item['balPN'] = '-'
        item['balPD'] = '-'
        item['balDN'] = '-'
        item['balPV'] = '-';
        item['balAward'] = '-';
        item['balTB'] = '-';
        item['balTG'] = '-';
        item['acqd'] = this.utilsService.fixedToThousandsDecimal(data['la_acquired'], 2)
        item['acqdF'] = '-'
        item['acqdROFR'] = '-'
        item['acqdTF'] = '-'
        item['acqdRL'] = '-'
        item['acqdTA'] = '-';

        item['revenueLandReq'] = '-';


        this.dataArray.push(item);
        this.tableSpinner = false;
        this.tableConst = {
          tableInfo: {
            type: 'dataTable',
            title: '',
          },
          css: {
            tableClass: 'table table-bordered table-striped align-items-center',
            theadClass: 'thead-dark text-center'
          },
          sortOptions: {
            sortIndex: -1,
            sortType: 'asc'
          },
          searchFilter: true,
          breadcrumbCons: {
            show: true,
            queryparams: []
          },
          downloadButton: {
            display: true,
            text: 'Download',
            align: 'right',       //where to align the element within div i.e left, center or right
            order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
          },
          downloadOptions: [{
            showDownload: true,
            downloadType: 'xlsx',
            downloadFileName: 'LA Details',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          },
          {
            showDownload: true,
            downloadType: 'pdf',
            downloadFileName: 'LA Details',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          }
          ],
          paginatorOptions: {
            visible: false,
            limit: 15,
            maxSize: 5,     // Max pages to display on the link
            // directionLinks: 'false',
            // autoHide: 'false',
            // previousLabel: 'prev',
            // nextLabel: 'nex',
            dropdownOptions: [{
              value: 15,
              display: '15'
            },
            {
              value: 25,
              display: '25'
            },
            {
              value: 50,
              display: '50'
            },
            {
              value: -1,
              display: 'All'
            }]
          },
          tableHeaderFixerInfo: {
            headerFix: true,
            colFix: false,
            noOfCol: 2
          },
          totalRow: {
            name: 'total',
            jsonKey: '-1',
            rowspan: 4,
            position: 'bottom',
            class: 'total-row'
          },
          indexCons: {
            show: true,
            class: 'tc-bg-01'
          },
          columns: [
            {
              name: 'Package',
              jsonKey: {
                path: 'name'
              },
              dataType: 'LOCATION',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'District',
              jsonKey: {
                path: 'district'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Mandal',
              jsonKey: {
                path: 'mandal'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Required(Acres)',
              class: 'tc-bg-05',
              expand: true,
              jsonKey: {
                path: 'reqd'
              },
              dataType: 'TEXT',
              defaultValue: '-',
            },
            {
              name: 'Revenue Land Requistioned(Acres)',
              jsonKey: {
                path: 'revenueLandReq'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Acquired (Acres)',
              class: 'tc-bg-05',
              expand: true,
              jsonKey: {
                path: 'acqd'
              },
              dataType: 'TEXT',
              defaultValue: '-',

            },
            {
              name: 'LA Balance for Revenue Land (Acres)',
              class: 'tc-bg-05',
              expand: true,
              jsonKey: {
                path: 'bal'
              },
              dataType: 'TEXT',
              defaultValue: '-',
            },
          ]
        }
      }

      this.tableConst['columns'][0].name = this.child.toUpperCase();
      this.tableConst['breadcrumbCons'].queryparams = this.breadcrumb;
      if (this.hie.indexOf(this.child) == this.hie.length - 1) {
        this.tableConst['columns'][0].dataType = 'TEXT';
      }
    })
  }
  getAlertTable() {
    this.tableConst = {};
    this.dataArray = [];
    this.totalDataCopy = [];
    this.tableSpinner = true;
    this.packageList = {};
    const params = this.httpDataService.getAlertsParams(this.urlParams);
    this.httpDataService.getAlertsData(params).subscribe((res) => {
      if(!this.utilsService.isEmptyObj(res)){
    if (isNullOrUndefined(this.urlParams['packageName'])) {
      this.dataArray = [];
      this.tableData = [];
      let totalRow = {
        issues: 0,
        pending: 0,
        progress: 0,
        resolved: 0
      }
      this.packageList = {};
      this.packageList['ALL'] = 'All';
      Object.keys(res).forEach((key: any) => {
        if (key != 'SITA-RAMA-PPM') {
          let data = res[key];
          this.packageList[key] = this.packageListMapping[key];
          const index = key.search(/[0-9]/g);
          if (index > -1) {
            const packageNo = key.slice(index);
            key = key.split(packageNo).join('');
            key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
            key += ' ' + packageNo;
          }
          let total = {
            resolved: 0,
            pending: 0,
            progress: 0
          }
          let item = {};
          data.forEach((response: any) => {
            if (response['status'] == 'PENDING') {
              total['pending']++;
            }
            else if (response['status'] == 'UNRESOLVED') {
              total['resolved']++;
            }
            else if (response['status'] == 'PROGRESS') {
              total['progress']++;
            }
          })
          item['name'] = key;
          item['noIssues'] = total['progress'] + total['pending'] + total['resolved'];
          item['noIssuesPending'] = total['pending'] ? total['pending'] : '-';
          item['noIssuesProgress'] = total['resolved'] ? total['resolved'] : '-';
          item['noIssuesResolved'] = total['progress'] ? total['progress'] : '-';
          totalRow['issues'] += item['noIssues'];
          totalRow['pending'] += (item['noIssuesPending'] == '-' ? 0 : item['noIssuesPending']);
          totalRow['progress'] += (item['noIssuesProgress'] == '-' ? 0 : item['noIssuesProgress']);
          totalRow['resolved'] += (item['noIssuesResolved'] == '-' ? 0 : item['noIssuesResolved']);
          this.tableData.push(item);
        }
      });
      let item = {};
      item['name'] = 'TOTAL';
      item['noIssues'] = totalRow['issues'] ? totalRow['issues'] : '-' ;
      item['noIssuesPending'] = totalRow['pending'] ? totalRow['pending'] : '-' ;
      item['noIssuesProgress'] = totalRow['progress'] ? totalRow['progress'] : '-'; 
      item['noIssuesResolved'] = totalRow['resolved'] ? totalRow['resolved'] : '-';
      this.tableData.push(item);
      this.tableData.sort((t1, t2) => {
        const name1 = t1.name.toLowerCase();
        const name2 = t2.name.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      this.tableData.forEach((data: any) => {
        this.dataArray.push(data);
        this.totalDataCopy.push(data);
      });
      this.tableSpinner = false;
    }
    else {
      this.dataArray = [];
      let i = 0;
      this.packageList = {};
      let data = res[this.urlParams['packageName']];
      console.log(data);
      data.forEach((response: any) => {
        let item = {};
        const index = this.urlParams['packageName'].search(/[0-9]/g);
        let key = this.urlParams['packageName'];
        this.packageList[key] = this.packageListMapping[key];
        if (index > -1) {
          const packageNo = this.urlParams['packageName'].slice(index);
          key = this.urlParams['packageName'].split(packageNo).join('');
          key = key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
          key += ' ' + packageNo;
        }
        item['name'] = key;
        item['id'] = response['esid'] ? response['esid'] : '-';
        this.alertTableDDSelection[++i] = '';
        item['loc'] = response['en'] ? this.utilsService.splitLocationString(response['en']) : '-';
        item['type'] = response['etype'] ? response['etype'].split(' ')[0] : '-';
        item['category'] = response['estype'] ? response['estype'] : '-';
        item['raisedOn'] = response['eraisd'] ? this.utilsService.getDateInDDMMYYYYFormat(response['eraisd'].toString()) : '-';
        item['initiatedBy'] = response['uname'] ? response['uname'] : '-';
        item['referredTo'] = response['rto'] ? response['rname'].concat(' ').concat(response['rto']) : '-';
        item['status'] = response['status'] ? response['status'] : '-';
        item['resolvedOn'] = response['resd'] ? this.utilsService.getDateInDDMMYYYYFormat(response['resd'].toString()) : '-';
        item['resolvedBy'] = response['irby'] ? response['irby'] : '-';
        item['remarks'] = response['erem'] ? response['erem'] : '-';
        this.dataArray.push(item);
        this.tableSpinner = false;
        this.totalDataCopy.push(item);
      })
    }
  }
  else{
    this.tableSpinner = false;
    this.dataArray = [];
    this.tableData = [];
  }
  });
  }

  getPhysicalTable() {
    this.tableConst = {};
    this.tableSpinner = true;
    this.headerMetaData = this.getHeader();
    let params: any = this.urlParams['locType'] != this.constants.LOC_CONSTANT[2] ? this.httpDataService.getProjectComponentLevelParams(this.getDateForParams('TO'), this.urlParams['locType'], this.urlParams['uuid']) :
      this.httpDataService.getActivityLevelParams(this.getDateForParams('TO'), this.componentApiMappingNewRev[this.urlParams['componentName']], this.urlParams['packageName']);
    if (this.urlParams['locType'] != this.reportSrv.locationList[2]) {
      this.httpDataService.getProjectComponentLevelData(params).subscribe((response) => {
        this.dataArray = [];
        this.tableData = [];
        this.physicalDD = {};
        if (this.urlParams['locType'] == this.reportSrv.locationList[0]) {
          this.physicalDD = {};
          let res = response.cData;
          this.physicalDD['ALL'] = 'All';
          Object.keys(res).forEach((key) => {
            let innerData = res[key];
            let name = this.constants.tablePackageNameMappingDup[this.constants.packageFullNameUUID[key]];
            this.tableData[name] = {};
            Object.keys(innerData).forEach((innerkey) => {
              if (innerkey == 'cumulative') {
                this.tableData[name]['total_cummulative'] = innerData[innerkey].per;
              } else if (innerkey == 'fortnight') {
                this.tableData[name]['fortnight'] = innerData[innerkey].per;
              } else if (innerkey == 'current') {
                this.tableData[name]['yesterday'] = innerData[innerkey].per;
              } else if (innerkey == 'current_month') {
                this.tableData[name]['cummulative_till_yesterday'] = innerData[innerkey].per;
              }
              this.tableData[name]['cumTarget'] = 100;

            })
          })

          for (const key in this.tableData) {
            if (this.tableData.hasOwnProperty(key)) {
              const element = this.tableData[key];
              let obj = {};
              obj['name'] = key;
              obj['currMonthCompleted'] = element.cummulative_till_yesterday >= 0 ? this.utilsService.fixedToThousandsDecimal(element.cummulative_till_yesterday, 2) : '-';
              obj['ystdayCompleted'] = element.yesterday >= 0 ? this.utilsService.fixedToThousandsDecimal(element.yesterday, 2) : '-';
              obj['cummOnDateCompleted'] = element.total_cummulative >= 0 ? this.utilsService.fixedToThousandsDecimal(element.total_cummulative, 2) : '-';
              obj['cummOnDateTarget'] = element.cumTarget >= 0 ? this.utilsService.fixedToThousandsDecimal(element.cumTarget, 2) : '-';
              obj['fortnightCompleted'] = element.fortnight >= 0 ? this.utilsService.fixedToThousandsDecimal(element.fortnight, 2) : '-';
              if (obj['cummOnDateTarget'] != '-' && obj['cummOnDateCompleted'] != '-') {
                let per = obj['cummOnDateTarget'] - obj['cummOnDateCompleted'];
                obj['balQty'] = this.utilsService.fixedToThousandsDecimal(per, 2);
              } else {
                obj['balQty'] = '-';
              }
              this.tableData.push(obj);
            }
            this.tableSpinner = false;
          }
          this.tableData.sort((t1, t2) => {
            const name1 = t1.name.toLowerCase();
            const name2 = t2.name.toLowerCase();
            if (name1 > name2) { return 1; }
            if (name1 < name2) { return -1; }
            return 0;
          });
          this.tableData.forEach((data: any) => {
            if (data['name'] != 'TOTAL')
              this.physicalDD[this.packageListRev[data['name']]] = data['name'];
            this.dataArray.push(data);
          });
          this.tableConst = {
            tableInfo: {
              type: 'dataTable',
              title: '',
            },
            css: {
              tableClass: 'table table-bordered table-striped align-items-center',
              theadClass: 'thead-dark text-center'
            },
            sortOptions: {
              sortIndex: -1,
              sortType: 'asc'
            },
            searchFilter: true,
            breadcrumbCons: {
              show: true,
              queryparams: []
            },
            downloadButton: {
              display: true,
              text: 'Download',
              align: 'right',       //where to align the element within div i.e left, center or right
              order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
            },
            downloadOptions: [{
              showDownload: true,
              downloadType: 'xlsx',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            },
            {
              showDownload: true,
              downloadType: 'pdf',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            }
            ],
            paginatorOptions: {
              visible: false,
              limit: 15,
              maxSize: 5,     // Max pages to display on the link
              // directionLinks: 'false',
              // autoHide: 'false',
              // previousLabel: 'prev',
              // nextLabel: 'nex',
              dropdownOptions: [{
                value: 15,
                display: '15'
              },
              {
                value: 25,
                display: '25'
              },
              {
                value: 50,
                display: '50'
              },
              {
                value: -1,
                display: 'All'
              }]
            },
            tableHeaderFixerInfo: {
              headerFix: true,
              colFix: false,
              noOfCol: 2
            },
            indexCons: {
              show: true,
              class: 'tc-bg-01'
            },
            columns: [
              {
                name: 'Package',
                jsonKey: {
                  path: 'name'
                },
                dataType: 'LOCATION',
                defaultValue: '-',
                class: 'tc-bg-03',
              }, {
                name: 'Completed Quantity',
                class: 'tc-bg-05',
                childs: [
                  {
                    name: 'Yesterday',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'ystdayCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Fortnight',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'fortnightCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Current Month',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'currMonthCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Cumulative (as on Date)',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'cummOnDateTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'cummOnDateCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                ]
              },
              {
                name: 'Balance Quantity %',
                jsonKey: {
                  path: 'balQty'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              }]
          }

        }
        else if (this.urlParams['locType'] == this.reportSrv.locationList[1]) {
          this.tableData = {};
          this.dataArray = [];
          this.physicalDD = {};
          let res = response.cData;
          Object.keys(res).forEach((key) => {
            let innerData = res[key];
            let name = this.componentApiMappingNew[key];
            this.tableData[name] = {};
            Object.keys(innerData).forEach((innerkey) => {
              if (innerkey == 'cumulative') {
                this.tableData[name]['total_cummulative'] = innerData[innerkey].per >= 0 ? innerData[innerkey].per : '-';
              } else if (innerkey == 'fortnight') {
                this.tableData[name]['fortnight'] = innerData[innerkey].per >= 0 ? innerData[innerkey].per : '-';
              } else if (innerkey == 'current') {
                this.tableData[name]['yesterday'] = innerData[innerkey].per >= 0 ? innerData[innerkey].per : '-';
              } else if (innerkey == 'current_month') {
                this.tableData[name]['cummulative_till_yesterday'] = innerData[innerkey].per >= 0 ? innerData[innerkey].per : '-';
              }
              this.tableData[name]['cumTarget'] = 100;
            })
          });
          this.tableData = this.reportSrv.sortComponentTableResponse(this.tableData, this.urlParams);
          for (const key in this.tableData) {
            if (this.tableData.hasOwnProperty(key)) {
              const element = this.tableData[key];
              this.physicalDD[key] = key;
              let obj = {};
              obj['name'] = key;
              obj['currMonthCompleted'] = element.cummulative_till_yesterday >= 0 ? this.utilsService.fixedToThousandsDecimal((element.cummulative_till_yesterday), 2) : '-';
              obj['ystdayCompleted'] = element.yesterday >= 0 ? this.utilsService.fixedToThousandsDecimal((element.yesterday), 2) : '-';
              obj['cummOnDateCompleted'] = element.total_cummulative >= 0 ? this.utilsService.fixedToThousandsDecimal((element.total_cummulative), 2) : '-';
              obj['cummOnDateTarget'] = element.cumTarget >= 0 ? this.utilsService.fixedToThousandsDecimal((element.cumTarget), 2) : '-';
              obj['fortnightCompleted'] = element.fortnight >= 0 ? this.utilsService.fixedToThousandsDecimal((element.fortnight), 2) : '-';
              if (obj['cummOnDateTarget'] != '-' && obj['cummOnDateCompleted'] != '-') {
                let per = +this.utilsService.fixedFromThousandDecimal(obj['cummOnDateTarget']) - +this.utilsService.fixedFromThousandDecimal(obj['cummOnDateCompleted']);
                obj['balQty'] = this.utilsService.fixedToThousandsDecimal(per, 2);
              } else {
                obj['balQty'] = '-';
              }
              this.dataArray.push(obj);
              this.tableSpinner = false;
            }
          }
          this.tableSpinner = false;

          this.tableConst = {
            tableInfo: {
              type: 'dataTable',
              title: '',
            },
            css: {
              tableClass: 'table table-bordered table-striped align-items-center',
              theadClass: 'thead-dark text-center'
            },
            sortOptions: {
              sortIndex: -1,
              sortType: 'asc'
            },
            searchFilter: true,
            breadcrumbCons: {
              show: true,
              queryparams: []
            },
            downloadButton: {
              display: true,
              text: 'Download',
              align: 'right',       //where to align the element within div i.e left, center or right
              order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
            },
            downloadOptions: [{
              showDownload: true,
              downloadType: 'xlsx',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            },
            {
              showDownload: true,
              downloadType: 'pdf',
              downloadFileName: 'Physical Report',
              downloadAll: true,  //pass false if only current page data is to be downloaded
              metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
            }
            ],
            paginatorOptions: {
              visible: false,
              limit: 15,
              maxSize: 5,     // Max pages to display on the link
              // directionLinks: 'false',
              // autoHide: 'false',
              // previousLabel: 'prev',
              // nextLabel: 'nex',
              dropdownOptions: [{
                value: 15,
                display: '15'
              },
              {
                value: 25,
                display: '25'
              },
              {
                value: 50,
                display: '50'
              },
              {
                value: -1,
                display: 'All'
              }]
            },
            tableHeaderFixerInfo: {
              headerFix: true,
              colFix: false,
              noOfCol: 2
            },
            indexCons: {
              show: true,
              class: 'tc-bg-01'
            },
            columns: [
              {
                name: 'Component',
                jsonKey: {
                  path: 'name'
                },
                dataType: 'LOCATION',
                defaultValue: '-',
                class: 'tc-bg-03',
              }, {
                name: 'Completed Quantity',
                class: 'tc-bg-05',
                childs: [
                  {
                    name: 'Yesterday',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'ystdayCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Fortnight',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'fortnightCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Current Month',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'currMonthCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                  {
                    name: 'Cumulative (as on Date)',
                    class: 'tc-bg-05',
                    childs: [
                      {
                        name: 'Target',
                        jsonKey: {
                          path: 'cummOnDateTarget'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      },
                      {
                        name: 'Completed',
                        jsonKey: {
                          path: 'cummOnDateCompleted'
                        },
                        dataType: 'TEXT',
                        defaultValue: '-',
                        class: 'tc-bg-04'

                      }
                    ]
                  },
                ]
              },
              {
                name: 'Balance Quantity %',
                jsonKey: {
                  path: 'balQty'
                },
                dataType: 'TEXT',
                defaultValue: '-',
                class: 'tc-bg-03',
              }]
          }
        }
        this.tableConst['columns'][0].name = this.child.toUpperCase();
        this.tableConst['breadcrumbCons'].queryparams = this.breadcrumb;
        if (this.hie.indexOf(this.child) == this.hie.length - 1) {
          this.tableConst['columns'][0].dataType = 'TEXT';
        }
      });
    }
    else {
      this.httpDataService.getActivityLevelData(params).subscribe((res) => {
        this.tableData = {};
        this.dataArray = [];
        this.physicalDD = {};
        this.physicalDD[this.urlParams.componentName] = this.urlParams.componentName;
        if (this.urlParams['componentName'] == 'GRAVITY CANAL') {
          res = this.reportSrv.sortGravityCanalResponse(res);
        }
        Object.keys(res).forEach((key: any) => {
          let innerData = res[key];
          Object.keys(innerData).forEach((innerKey: any) => {
            this.tableData = {};
            if (innerKey == 'nos' && this.urlParams['componentName'] == 'PUMP HOUSE') {
              this.tableData['name'] = this.activityNameMapping[key];
              if (!isNullOrUndefined(this.tableData['name'])) {
                this.tableData['unit'] = innerKey == 'nos' ? '-' : innerKey;
                this.tableData['ystdayCompleted'] = innerData[innerKey].current_percentage ? 'Yes' : 'No';
                this.tableData['ystdayTarget'] = innerData[innerKey].current_target ? 'Yes' : 'No';
                this.tableData['fortnightCompleted'] = innerData[innerKey].fortnight_percentage ? 'Yes' : 'No';
                this.tableData['fortnightTarget'] = innerData[innerKey].fortnight_target ? 'Yes' : 'No';
                this.tableData['currMonthCompleted'] = innerData[innerKey].current_month_percentage ? 'Yes' : 'No';
                this.tableData['currMonthTarget'] = innerData[innerKey].current_month_target ? 'Yes' : 'No';
                this.tableData['cummOnDateTarget'] = innerData[innerKey].cumulative_target ? 'Yes' : 'No';
                this.tableData['cummOnDateCompleted'] = innerData[innerKey].cumulative_percentage ? 'Yes' : 'No';
                this.tableData['balQty'] = '-'
                this.tableData['balQtyPer'] = '-'
                this.dataArray.push(this.tableData);
              }
            }
            else if (innerKey == 'km' || innerKey == 'cum' || innerKey == 'sqm' || innerKey == 'nos') {
              this.tableData['name'] = this.activityNameMapping[key];
              if (!isNullOrUndefined(this.tableData['name'])) {
                this.tableData['unit'] = innerKey;
                this.tableData['ystdayCompleted'] = innerData[innerKey].current_comleted >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].current_comleted, 2) : '-';
                this.tableData['ystdayTarget'] = innerData[innerKey].current_target >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].current_target, 2) : '-';
                this.tableData['fortnightTarget'] = innerData[innerKey].fortnight_target >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].fortnight_target, 2) : '-';
                this.tableData['fortnightCompleted'] = innerData[innerKey].fortnight_comleted >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].fortnight_comleted, 2) : '-';
                this.tableData['currMonthTarget'] = innerData[innerKey].current_month_target >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].current_month_target, 2) : '-';
                this.tableData['currMonthCompleted'] = innerData[innerKey].current_month_comleted >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].current_month_comleted, 2) : '-';
                this.tableData['cummOnDateTarget'] = innerData[innerKey].cumulative_target >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].cumulative_target, 2) : '-';
                this.tableData['cummOnDateCompleted'] = innerData[innerKey].cumulative_comleted >= 0 ? this.utilsService.fixedToThousandsDecimal(innerData[innerKey].cumulative_comleted, 2) : '-';
                if (this.tableData['cummOnDateTarget'] != '-' && this.tableData['cummOnDateCompleted'] != '-') {
                  let per = +this.utilsService.fixedFromThousandDecimal(this.tableData['cummOnDateTarget']) - +this.utilsService.fixedFromThousandDecimal(this.tableData['cummOnDateCompleted']);
                  this.tableData['balQty'] = this.utilsService.fixedToThousandsDecimal(per, 2);
                  this.tableData['balQtyPer'] = per ? this.utilsService.fixedToThousandsDecimal((per / this.tableData['cummOnDateTarget'] * 100), 2) : this.tableData['balQty'];
                } else {
                  this.tableData['balQty'] = '-';
                  this.tableData['balQtyPer'] = '-';
                }
                this.dataArray.push(this.tableData);
              }
              this.tableSpinner = false;
            }
          })
        })
        this.tableSpinner = false;
        this.tableConst = {
          tableInfo: {
            type: 'dataTable',
            title: '',
          },
          css: {
            tableClass: 'table table-bordered table-striped align-items-center',
            theadClass: 'thead-dark text-center'
          },
          sortOptions: {
            sortIndex: -1,
            sortType: 'asc'
          },
          searchFilter: true,
          breadcrumbCons: {
            show: true,
            queryparams: []
          },
          downloadButton: {
            display: true,
            text: 'Download',
            align: 'right',       //where to align the element within div i.e left, center or right
            order: 'order-md-3'  //Order of the element from left. defined in DataTableModule.toolBarElementOrder
          },
          downloadOptions: [{
            showDownload: true,
            downloadType: 'xlsx',
            downloadFileName: 'Physical Report',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          },
          {
            showDownload: true,
            downloadType: 'pdf',
            downloadFileName: 'Physical Report',
            downloadAll: true,  //pass false if only current page data is to be downloaded
            metaData: true //pass true if meta data needs to be added in excel (Option only for excel and all data needs to be downloaded)
          }
          ],
          paginatorOptions: {
            visible: false,
            limit: 15,
            maxSize: 5,     // Max pages to display on the link
            // directionLinks: 'false',
            // autoHide: 'false',
            // previousLabel: 'prev',
            // nextLabel: 'nex',
            dropdownOptions: [{
              value: 15,
              display: '15'
            },
            {
              value: 25,
              display: '25'
            },
            {
              value: 50,
              display: '50'
            },
            {
              value: -1,
              display: 'All'
            }]
          },
          tableHeaderFixerInfo: {
            headerFix: true,
            colFix: false,
            noOfCol: 2
          },
          indexCons: {
            show: true,
            class: 'tc-bg-01'
          },
          columns: [
            {
              name: 'Activity',
              jsonKey: {
                path: 'name'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            }, 
            {
              name: 'Unit',
              jsonKey: {
                path: 'unit'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Completed Quantity',
              class: 'tc-bg-05',
              childs: [
                {
                  name: 'Yesterday',
                  class: 'tc-bg-05',
                  childs: [
                    {
                      name: 'Target',
                      jsonKey: {
                        path: 'ystdayTarget'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    },
                    {
                      name: 'Completed',
                      jsonKey: {
                        path: 'ystdayCompleted'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    }
                  ]
                },
                {
                  name: 'Fortnight',
                  class: 'tc-bg-05',
                  childs: [
                    {
                      name: 'Target',
                      jsonKey: {
                        path: 'fortnightTarget'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    },
                    {
                      name: 'Completed',
                      jsonKey: {
                        path: 'fortnightCompleted'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    }
                  ]
                },
                {
                  name: 'Current Month',
                  class: 'tc-bg-05',
                  childs: [
                    {
                      name: 'Target',
                      jsonKey: {
                        path: 'currMonthTarget'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    },
                    {
                      name: 'Completed',
                      jsonKey: {
                        path: 'currMonthCompleted'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    }
                  ]
                },
                {
                  name: 'Cumulative (as on Date)',
                  class: 'tc-bg-05',
                  childs: [
                    {
                      name: 'Target',
                      jsonKey: {
                        path: 'cummOnDateTarget'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    },
                    {
                      name: 'Completed',
                      jsonKey: {
                        path: 'cummOnDateCompleted'
                      },
                      dataType: 'TEXT',
                      defaultValue: '-',
                      class: 'tc-bg-04'

                    }
                  ]
                },
              ]
            },
            {
              name: 'Balance Quantity',
              jsonKey: {
                path: 'balQty'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
            {
              name: 'Balance Quantity %',
              jsonKey: {
                path: 'balQtyPer'
              },
              dataType: 'TEXT',
              defaultValue: '-',
              class: 'tc-bg-03',
            },
          ]
        }

        this.tableConst['columns'][0].name = this.child.toUpperCase();
        this.tableConst['breadcrumbCons'].queryparams = this.breadcrumb;
        if (this.hie.indexOf(this.child) == this.hie.length - 1) {
          this.tableConst['columns'][0].dataType = 'TEXT';
        }
      });
    }
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  generateBreadCrumb() {
    this.breadcrumb = [];
    let allStates = this.parent.split('&');
    allStates.push(this.child);
    let allLocations = this.location.split('&');
    // let alluuids = this.uuid.split('&');
    for (let i = 1; i < allStates.length; i++) {
      let parent = allStates.slice(0, i).join('&');
      let location = allLocations.slice(0, i).join('&');
      let name = allLocations[i - 1].split('##')[0];
      // let uuid = alluuids.slice(0, i).join('&');
      let obj = {
        'child': allStates[i],
        'parent': parent,
        'location': location,
        'name': name,
        // 'uuid' : uuid
      }
      this.breadcrumb.push(obj);
    }
  }

  tableRowClick(data?: any) {
    if (data != undefined && data['values'] != undefined && data['values'].length >= 1
      && data['values'][0] != undefined) {
      this.parent = this.urlParams['parent'] + '&' + this.child; // SRLIP 
      this.child = this.hie[this.hie.indexOf(this.child) + 1];
      this.location = this.urlParams['location'] + '&' + data['values'][0];
      let params = {
        parent: this.parent,
        child: this.child,
        location: this.location,
        locType: this.reportSrv.getChildLocType(this.urlParams['locType'], this.urlParams['chartType']),
        // uuid : this.uuid,
        projectName: this.urlParams['projectName'],
        chartType: this.urlParams['chartType'],
        packageName: this.packageListRev[data['values'][0]],
      }
      params['packageName'] = params['locType'] == this.reportSrv.locationList[2] ? this.urlParams['packageName'] : this.packageListRev[data['values'][0]];
      if (params['locType'] == this.reportSrv.locationList[2]) {
        params['componentName'] = data['values'][0];
      }
      params['uuid'] = params['locType'] == this.reportSrv.locationList[0] ? this.constants.UUID_MAPPING['PROJECT'] :
        this.constants.UUID_MAPPING[params['packageName']];
      params['sDate'] = this.urlParams['sDate'];
      params['eDate'] = this.urlParams['eDate'];
      this.router.navigate(['tsripms', 'projectDashboard', 'report', params]);
    }
    else {
      console.log("Incorrect parameters of the clicked column. Cannot drill down into the location.")
    }
  }


  rowClick(data?: any) {
    if (data != 'TOTAL') {
      this.parent = this.urlParams['parent'] + '&' + this.child; // SRLIP 
      this.child = this.hie[this.hie.indexOf(this.child) + 1];
      this.location = this.urlParams['location'] + '&' + data;
      // this.uuid = this.urlParams['uuid'] + '&' + data['values'][1];
      let params = {
        parent: this.parent,
        child: this.child,
        location: this.location,
        locType: this.reportSrv.getChildLocType(this.urlParams['locType'], this.urlParams['chartType']),
        // uuid : this.uuid,
        projectName: this.urlParams['projectName'],
        chartType: this.urlParams['chartType'],
        packageName: this.packageListRev[data],
      }
      params['uuid'] = params['locType'] == this.reportSrv.locationList[0] ? this.constants.UUID_MAPPING['PROJECT'] :
        this.constants.UUID_MAPPING[params['packageName']];
      params['sDate'] = this.urlParams['sDate'];
      params['eDate'] = this.urlParams['eDate'];
      this.router.navigate(['tsripms', 'projectDashboard', 'report', params]);
    }
  }
  headerExpansionCalled(data) {
    let constClone = JSON.parse(JSON.stringify(this.tableConst));
    this.tableConst = {};
    if (Object.keys(data).length > 0) {
      if (constClone.columns[data['index']]) {
        constClone.columns[data['index']].isExpanded = data['type'];
      }
      if (data['type']) {
        if (constClone.columns && constClone.columns[data['index']] && constClone.columns[data['index']] && this.columnConst[this.urlParams['locType']][data['index']]) {
          constClone.columns[data['index']].childs = this.createChildColArray(this.columnConst[this.urlParams['locType']][data['index']]);
        }
      } else {
        if (constClone.columns && constClone.columns[data['index']] && constClone.columns[data['index']]) {
          delete constClone.columns[data['index']].childs;
        }
      }
    }
    this.tableConst = constClone;
  }

  createChildColArray(data) {
    let colArray: any = [];
    Object.keys(data).forEach((res: any) => {
      let obj = {
        name: data[res],
        jsonKey: {
          path: res
        },
        dataType: 'TEXT',
        defaultValue: '-',
        class: 'tc-bg-03',
      }
      colArray.push(obj);
    });
    return colArray;
  }
  subscribeEvents() {
    let breadcrumbSub = this.tableEventsHandler.breadcrumbClicked.subscribe(s => {
      this.tablebreadcrumbClick(s);
    });
    let headerExpansionSub = this.tableEventsHandler.tableHeaderExpansion.subscribe(data => {
      this.headerExpansionCalled(data);
    });
    this.subsciptions.push(breadcrumbSub);
    this.subsciptions.push(headerExpansionSub);
  }

  tablebreadcrumbClick(data: any) {
    let state = { ...data };
    delete state.name;
    let params = {
      parent: state.parent,
      child: state.child,
      location: state.location,
      locType: this.reportSrv.getParentLocType(state.child),
      // uuid : state.uuid,
      projectName: this.urlParams['projectName'],
      chartType: this.urlParams['chartType'],
    }
    if (data.location == 'SRLIP') {
      let chart = this.urlParams['chartType'];
      this.selectedFilterParams['type'] = this.selectTypeListFromURLMapping[chart];
      this.selectedFilterParams['package'] = 'ALL';
      this.getToFromDates();
      this.selectedFilterParams['from'] = this.fromDate;
      this.selectedFilterParams['to'] = this.toDate;
      params['sDate'] = this.getDateForParams('FROM');
      params['eDate'] = this.getDateForParams('TO');
    }
    if (params['locType'] != this.reportSrv.locationList[0]) {
      params['packageName'] = this.urlParams['packageName'];
      // params['sDate'] = this.urlParams['sDate'];
      // params['eDate'] = this.urlParams['eDate'];
    }
    params['uuid'] = params['locType'] == this.reportSrv.locationList[0] ? this.constants.UUID_MAPPING['PROJECT'] :
      this.constants.UUID_MAPPING[this.urlParams['packageName']];
    params['sDate'] = this.urlParams['sDate'];
    params['eDate'] = this.urlParams['eDate'];
    this.router.navigate(['tsripms', 'projectDashboard', 'report', params]);

  }

  onTypeChange() {

  }

  onPackageChange() {
  }

  onSubmit() {
    this.alertTableDDSelection = {};
    let sDate, eDate;
    if (this.urlParams['chartType'] != this.typeListChartMapping[this.selectedFilterParams['type']]) {
      sDate = 20210101;
      eDate = 20210306;
      let chart = this.typeListChartMapping[this.selectedFilterParams['type']];
      let params = {};
      params['location'] = 'SRLIP';
      params['locType'] = this.reportSrv.locationList[0];
      params['projectName'] = 'sitaRama';
      params['chartType'] = chart;
      params['parent'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][0];
      params['child'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][1];
      params['uuid'] = this.constants.UUID_MAPPING['PROJECT'];
      params['sDate'] = sDate ? sDate : this.getDateForParams('FROM');
      params['eDate'] = eDate ? eDate : this.getDateForParams('TO');
      this.router.navigate(['tsripms', 'projectDashboard', 'report', params]);
    }
    else {
      this.urlParams['sDate'] = this.getDateForParams('FROM');
      this.urlParams['eDate'] = this.getDateForParams('TO');
      if (this.selectedFilterParams['package'] != 'ALL') {
        this.urlParams['locType'] = this.reportSrv.getChildLocType(this.urlParams['locType'], this.urlParams['chartType']);
      }
      this.urlParams['chartType'] = this.typeListChartMapping[this.selectedFilterParams['type']];
      if (this.urlParams['locType'] == this.reportSrv.locationList[2]) {
        this.urlParams['componentName'] = this.selectedFilterParams['package'];
      }
      if (this.selectedFilterParams['package'] != 'ALL' && this.urlParams['locType'] != this.reportSrv.locationList[0]) {
        this.urlParams['packageName'] = this.urlParams['locType'] != this.reportSrv.locationList[2] ? this.selectedFilterParams['package'] : this.urlParams['packageName'];
      }
      this.urlParams['uuid'] = this.urlParams['locType'] == this.reportSrv.locationList[0] ? this.constants.UUID_MAPPING['PROJECT'] :
        this.constants.UUID_MAPPING[this.urlParams['packageName']];
      if (this.urlParams['chartType'] == 'physical' && this.urlParams['locType'] == this.reportSrv.locationList[2]) {
        if (this.urlParams['packageName'] != this.urlParams['componentName']) {
          this.router.navigate(['tsripms', 'projectDashboard', 'report', this.urlParams]);
        }
      }
      else {
        this.router.navigate(['tsripms', 'projectDashboard', 'report', this.urlParams]);
      }
    }
  }

  onReset() {
    let chart = this.urlParams['chartType'];
    this.selectedFilterParams['type'] = this.selectTypeListFromURLMapping[chart];
    this.selectedFilterParams['package'] = 'ALL';
    this.getToFromDates();
    this.selectedFilterParams['from'] = this.fromDate;
    this.selectedFilterParams['to'] = this.toDate;
    let params = {};
    params['location'] = 'SRLIP';
    params['projectName'] = 'sitaRama';
    params['chartType'] = chart;
    params['locType'] = this.reportSrv.locationList[0];
    params['parent'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][0];
    params['child'] = ProjConstants.LOCATION_HIE[ProjConstants.CHART_LOC_MAPPING[chart]][1];
    params['uuid'] = this.constants.UUID_MAPPING['PROJECT'];
    params['sDate'] = 20210101;
    params['eDate'] = 20210306;
    this.router.navigate(['tsripms', 'projectDashboard', 'report', params]);
  }
  callReportModalComponent(event, remarks: any) {
    let value = document.body.classList.value;
    let styling;
    if (value == 'body-bg-dark') {
      styling = {
        backdrop: true,
        centered: true,
        windowClass: "chart-dark-modal"
      }
    } else {
      styling = {
        backdrop: true,
        centered: true,
        windowClass: "chart-light-modal"
      }
    }
    const fullScreenModal = this.modalSrv.open(ReportModalComponent, styling);
    fullScreenModal.componentInstance.title = event == 'REMARKS' ? 'Remarks History' : 'Action';
    fullScreenModal.componentInstance.selectionType = event;
    fullScreenModal.componentInstance.urlParams = this.urlParams;
    if (remarks) {
      fullScreenModal.componentInstance.remarks = remarks;
    }
  }
  export(option) {
    if (option == 'pdf') {
      // this.reportSrv.downloadAlertsReportPdf(this.getHeader(), this.urlParams['locType'], this.dataArray);
      if(this.dataArray.length === 0){
        this.toastr.error("","No Data to download", {timeOut: 2000, positionClass: 'toast-top-right'});        
      }
      else{
        this.reportSrv.downloadAlertsReportPdf(this.getHeader(), this.urlParams['locType'], this.dataArray);
     }
    }
    else if (option == 'xlsx') {
      if(this.dataArray.length === 0){
        this.toastr.error("","No Data to download", {timeOut: 2000, positionClass: 'toast-top-right'});        
      }
      else{
      this.reportSrv.exportAlertsReportExcel();
      }
    }
  }
  filterRecords() {
    this.searchTextHolder = this.searchText.toLowerCase().trim();
    this.filteredRecords = [];
    if (!isNullOrUndefined(this.searchTextHolder) && this.searchTextHolder != '') {
      // this.filteredRecords = [];
      for (let i = 0; i < this.dataArray.length; i++) {
        let flag = false;
        let outerRecord = this.dataArray[i];
        for (let key in outerRecord) {
          if (outerRecord[key].toString().toLowerCase().indexOf(this.searchTextHolder) != -1) {
            flag = true;
            break;
          }
        }
        if (flag && isNullOrUndefined(this.filteredRecords.find(obj => obj.name == outerRecord.name))) {
          this.filteredRecords.push(outerRecord);
        }
      }
    } else {
      this.filteredRecords = this.totalDataCopy;
      this.dataArray = this.totalDataCopy;
    }
    this.dataArray = this.filteredRecords;
  }
  onSelect() {
    this.actionHeaderSelection = true;
  }
}
