import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { TablecomponentsModule } from '../common/tablecomponents/tablecomponents.module';
import { tableHeaderFixerDirective } from '../common/directives/table-header-fixer.directive';
import { masterConf } from './data-table-master.config';
import { DataTableEventsService } from '../common/services/datatable.events.service';

@NgModule({
  declarations: [
    DataTableComponent,
    tableHeaderFixerDirective
  ],
  imports: [
    CommonModule,
    TablecomponentsModule
  ],
  providers: [DataTableEventsService],
  exports: [DataTableComponent, tableHeaderFixerDirective]
})
export class DataTableModule {

  getTableMasterConfiguration() {
    return masterConf;
  }
  /**
     * data: Table data.Array<Object>
     * keyPath: initially empty
     * columnConfig: contains column to show and their configuration. e.g {show['a','b'], properties:{'a':{...}}}
     * @param data 
     * @param keyPath
     * @param columnConfig 
     * @param colFilter 
     */
  getTableHeaderConfiguration(data: any, keyPath: string, columnConfig: Object, colFilter: boolean) {
    if (data == undefined) return;

    if (data instanceof Array)
      data = this.getHeaderSkeleton(data, {});

    return this.prepareHeader(data, keyPath, columnConfig, colFilter);
  }

  prepareHeader(data: any, keyPath: string, columnConfig: Object, colFilter: boolean) {
    if (data == undefined) return;

    let colList = [];
    if (data instanceof Array)
      data = data[0];

    for (let key in data) {
      let col = {};
      col['name'] = (columnConfig != undefined && columnConfig['properties'] != undefined && columnConfig['properties'][key] && columnConfig['properties'][key]['displayAs'] != undefined) ? columnConfig['properties'][key]['displayAs'] : key;
      if (data[key] instanceof Object) {
        col['childs'] = this.prepareHeader(data[key], (keyPath + ((keyPath.length > 0) ? "." : "") + key), columnConfig, colFilter);
      } else {
        if (colFilter && (columnConfig == undefined || columnConfig['show'].length == 0 || columnConfig['show'].indexOf(key) == -1)) continue;
        col['dataType'] = (columnConfig != undefined && columnConfig['properties'] != undefined && columnConfig['properties'][key] != undefined && columnConfig['properties'][key]['type'] != undefined) ? columnConfig['properties'][key]['type'] : (typeof data[key] === 'number') ? "NUMBER" : "TEXT";
        col['defaultValue'] = "-";
        col['jsonKey'] = {}
        col['jsonKey']['path'] = (keyPath + ((keyPath.length > 0) ? "." : "") + key)
        /**
         * TODO: fix the uuid field, it should be consistent. If possible move this field out of this method.
         */
        if (columnConfig['properties'] != undefined && columnConfig['properties'][key] != undefined
          && columnConfig['properties'][key]['append'] != undefined)
          col['jsonKey']['path'] = col['jsonKey']['path'] + "##" + ((keyPath + ((keyPath.length > 0) ? "." : "") + columnConfig['properties'][key]['append']))
      }

      colList.push(col);
    }

    return colList;
  }

  /**
   * Returns the skeleton of the header. i.e all the key - value pairs in the data.
   * It iterates through entire dataset and ensures that none of the pairs are missed.
   */
  getHeaderSkeleton(data: any, objectResult: Object) {
    if (data == undefined) return data;

    let result = {};

    if (data instanceof Array) {
      data.forEach(element => {
        for (let key in element) {
          result[key] = element[key];
          if (element[key] != undefined) {
            if (element[key] instanceof Object) {
              result[key] = this.getHeaderSkeleton(element[key], result[key]);
            }
          }
        }
      });
    } else {
      for (let date in data) {
        objectResult[date] = data[date];
        if (data[date] != undefined) {

          for (let key in data[date]) {
            objectResult[date][key] = data[date][key];
          }
        }
      }
      return objectResult;
    }

    return result;
  }

}
