import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor(private spinnerService: NgxUiLoaderService) { }
  @Input() id: string;
  @Input() show: boolean;
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges){
    (changes['show'] && changes['show'].currentValue)?this.startLoader():this.stopLoader();
  }
  startLoader(): void{
    this.spinnerService.startLoader(this.id);
  }
  stopLoader(): void{
    try{
      this.spinnerService.stopLoader(this.id)
    }catch(e){
    }
  }


}



