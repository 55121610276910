import { Injectable } from '@angular/core';
import * as JsEncryptModule from 'jsencrypt';

@Injectable({
  providedIn: 'root'
})
export class EncdecService {

  constructor() { }
 rsaenc(key : String,value : String){
    var jencrypt = new JsEncryptModule.JSEncrypt();
    var text = value.trim();
    jencrypt.setPublicKey(key);
    var cp = jencrypt.encrypt(text);
    return cp;
  }
}