import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Router, Event } from '@angular/router';
import { SplitComponent, SplitAreaDirective } from 'angular-split';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-geoportal-landing',
  templateUrl: './geoportal-landing.component.html',
  styleUrls: ['./geoportal-landing.component.scss']
})
export class GeoportalLandingComponent implements OnInit {
  // View Child for the split directive
  @ViewChild('split') split: SplitComponent;
  @ViewChild('area1') area1: SplitAreaDirective;
  @ViewChild('area2') area2: SplitAreaDirective;

  @ViewChild('splitEl', null) splitEl: SplitComponent;
  direction = 'horizontal';
  sizes = {
    pixel: {
      area1: 120,
      area2: '*',
      area3: 160,
    },
    percent: {
      area1: 70,
      area2: 30,
      area3: 500,
    },
  };
  showDataTab: boolean = true;
  showDataButton: boolean = false;
  navigationSubscription: any;
  urlParams: any = {};
  application: string;
  areaOfFocus: string;
  area1Size: any;
  area2Size: any;
  closeTab: any;
  unitType: string;
  area2Max: number;
  area2Min: number;
  iconColorStatus: any;
  constructor(private router: Router, private cookie: CookieService, private route: ActivatedRoute, private utilsService: UtilsService) {
    let innerWidth = window.innerWidth;
    localStorage.setItem("innerWidth", innerWidth.toString());
    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      window.onresize = (e) => {
        let innerWidth = window.innerWidth;
        localStorage.setItem("innerWidth", innerWidth.toString());
        if (innerWidth <= 1300) {
          this.unitType = "percent";
          this.area2Max = 30;
          this.area2Min = 25;
          if (innerWidth <= 769) {
            this.closeTab = true;
            localStorage.setItem("closeTab", this.closeTab);
          } else {
            this.closeTab = false;
            this.area1Size = 70;
            this.area2Size = 30;
            this.showDataTab = true;
            localStorage.removeItem("closeTab");
          }
        } else {
          this.closeTab = false;
          this.unitType = "pixel";
          this.area1Size = '*';
          this.area2Size = 450;
          this.area2Max = 700;
          this.area2Min = 300;
          this.showDataTab = true;
          localStorage.removeItem("closeTab");
        }
        this.changeView();
      }

      if (event instanceof NavigationEnd) {
        this.initializeOnStateLoad();
        // this.changeView();
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Hide loading indicator  // Present error to user
      }
    });
  }
  changeView() {
    this.closeTab = localStorage.getItem("closeTab");
    if (this.closeTab == 'true') {
      this.showDataTab = false;
      this.showDataButton = true;
    }
  }
  ngOnInit() {
    let innerWid = localStorage.getItem("innerWidth");
    let innerWiNumber = Number(innerWid);
    if (innerWiNumber <= 1300) {
      this.unitType = "percent";
      this.area2Max = 40;
      this.area2Min = 25;
      if (innerWiNumber <= 769) {
        this.closeTab = true;
        this.showDataTab = false;
        localStorage.setItem("closeTab", this.closeTab);
      } else {
        this.closeTab = false;
        this.area1Size = 70;
        this.area2Size = 30;
        this.showDataTab = true;
        localStorage.removeItem("closeTab");
      }
    } else {
      this.closeTab = false;
      this.unitType = "pixel";
      this.area1Size = '*';
      this.area2Size = 450;
      this.area2Max = 700;
      this.area2Min = 300;
      this.showDataTab = true;
      localStorage.removeItem("closeTab");
    }
    this.changeView();
  }
  initializeOnStateLoad() {
    let innerWid = localStorage.getItem("innerWidth");
    let innerWiNumber = Number(innerWid);
    if (innerWiNumber <= 1300) {
      this.unitType = "percent";
      this.area2Max = 40;
      this.area2Min = 25;
      if (innerWiNumber <= 769) {
        this.closeTab = true;
        localStorage.setItem("closeTab", this.closeTab);
      } else {
        this.closeTab = false;
        this.area1Size = 70;
        this.area2Size = 30;
        this.showDataTab = true;
        localStorage.removeItem("closeTab");
      }
    } else {
      this.closeTab = false;
      this.unitType = "pixel";
      this.area1Size = '*';
      this.area2Size = 450;
      this.area2Max = 700;
      this.area2Min = 300;
      this.showDataTab = true;
      localStorage.removeItem("closeTab");
    }
    this.changeView();

    // if (this.cookie.get('loggedIn') == 'true') {
    //   this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
    //   this.application = this.urlParams.application ? this.urlParams.application : 'allSystem';
    //   if (!this.urlParams.application) {
    //     this.router.navigate(['kaleshwaram', 'geoportal', {
    //       application: "allSystem", viewType: "gis-view",
    //       component: 'gisview', dashboardType: 'gisview', locId: 'rsvrb-sriram-project', tabType: 'allSystem'
    //     }]);
    //   }
    //   this.areaOfFocus = this.urlParams.locName ? this.urlParams.locName : "Full Kaleshwaram System";
    //   // let flag : boolean = true;
    //   // this.showDataButton = true;
    //   // this.showDataTab = true;
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    this.splitEl.dragProgress$.subscribe(x => {
      // this.ngZone.run(() => this.x = x);
      window.dispatchEvent(new Event('resize'));
    });
  }
  revalidate() {
    window.dispatchEvent(new Event('resize'));
  }
  closeComponent(event): void {
    this.revalidate()
    this.showDataTab = false;
    this.showDataButton = true;
  }
  checkApplicationDatatab() {
    this.revalidate()
    let flag: boolean = true;
    this.showDataButton = true;
    return flag;
  }

  changeiconColor() {
    this.iconColorStatus = false;
  }
}
