import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import {  CookieService  } from 'ngx-cookie-service'; 
import { HttperrorhandlerService } from './httperrorhandler.service';
import { AuthloginService } from './authlogin.service';
import { ConstantsModule } from '../constants/constants.module';
import { SubmitdataService } from './submitdata.service';
import { Observable } from 'rxjs';
import { ProjectData } from 'src/app/modules/user-management/add-user/project-data';
import { User, Iuser } from '../modules/user-management/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  usersList: User[] = [];

  userdetails : any;

  public visibilityDshbrdUrl = environment.userManagementMapping.UM_API;

  constructor(private http: HttpClient,private constant:ConstantsModule,private submitdataservice: SubmitdataService, private logsrv : AuthloginService,
    private cookie : CookieService,private errorHandler : HttperrorhandlerService) { }
  urlConstants = this.constant;

  getStatusOfToken(){
    this.userdetails = this.logsrv.checkloginstatus();
    let option = this.userdetails ? this.userdetails['expirationTime'] : null;
    this.submitdataservice.getStatus(option); 
  }

  getRoles(role:String) {
    console.log("from getroles")
    this.getStatusOfToken();
    return this.http.get("assets/data/role_mapping.json");
  }

  getAuthHeader() {
    const headers = this.logsrv.getHeadersWithCsrf();
    headers['headers']['Authorization'] = this.getUserToken()
    return headers;
  }

  getUsers() {
    console.log("from get users")
    this.getStatusOfToken();

    let userId = this.getUserId();
    let token = this.getUserToken();
    //let userId = 12;
    //let token = "riya";
    let postData = {
      userId: !!userId ? userId.toString() : null,
      token
      // userId: "6",
      // token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJDR1dCX2FkbWluIiwiaXNzIjoidmFzc2FyIiwiZXhwIjoxNTkzMDA2NzQwLCJ0bXAiOjE1OTMwMDYxNDA4MjZ9.kwX-vack0qHERGIXDykXrh5gaIlKXZC94DZJ9aZu-JU"
    }
    console.log("postdata is : " , postData);
  //  return this.http.post(this.urlConstants.API_URL_USER + "users-CWMI", postData, {});
  return this.http.post<any>(this.urlConstants.API_URL_USER + 'users-CWMI', postData,this.logsrv.getHeadersWithCsrf()).pipe(
    catchError(this.errorHandler.handleError('get parent child list : ', [])))
  }

  getUsersForShareModel() {
    this.getStatusOfToken();

    let userId = '1';
    let token = this.getUserToken();
    //let userId = 12;
    //let token = "riya";
    let postData = {
      userId: !!userId ? userId.toString() : null,
      token
      // userId: "6",
      // token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJDR1dCX2FkbWluIiwiaXNzIjoidmFzc2FyIiwiZXhwIjoxNTkzMDA2NzQwLCJ0bXAiOjE1OTMwMDYxNDA4MjZ9.kwX-vack0qHERGIXDykXrh5gaIlKXZC94DZJ9aZu-JU"
    }
    console.log("postdata is : " , postData);
  //  return this.http.post(this.urlConstants.API_URL_USER + "users-CWMI", postData, {});
  return this.http.post<any>(this.urlConstants.API_URL_USER + 'users-CWMI', postData,this.logsrv.getHeadersWithCsrf()).pipe(
    catchError(this.errorHandler.handleError('get parent child list : ', [])))
  }


  getUser(userId: number) {
    console.log("from get user")
    this.getStatusOfToken();

    return this.http.get(this.urlConstants.API_URL_USER + 'user/' + userId);
    // return this.http.get("assets/data/users.json").pipe(map((res: any) => res.result.content.find(u => u.id == userId)));
  }

  deleteUser(user: Iuser) {
    this.getStatusOfToken();
    var postData = {};
    postData['username'] = user.username.toString();
    postData['token'] = this.getUserToken();
    return this.http.post<any>(this.urlConstants.API_URL_USER + 'delete-user', postData,this.logsrv.getHeadersWithCsrf()).pipe(
      catchError(this.errorHandler.handleError('get parent child list : ', [])))
  }

  updateUser(user: any) {
    this.getStatusOfToken();

    let postdata = {
      ...user,
       token: this.getUserToken(),
       approver: {
         userId: this.getUserId()
       }  
    }

    console.log("from update user ", postdata);
    return this.http.post<any>(this.urlConstants.API_URL_USER + 'edit-user', postdata,this.logsrv.getHeadersWithCsrf()).pipe(
      catchError(this.errorHandler.handleError('get parent child list : ', [])))
  }

  createUser(user: any) {
    console.log("from create user")
    this.getStatusOfToken();

    let postdata = {
      ...user,
       token: this.getUserToken(),
       approver: {
         userId: this.getUserId()
       }
    }
    console.log("post data for create user ", postdata);
    return this.http.post<any>(this.urlConstants.API_URL_USER + 'create-user-with-password-encryption', postdata,this.logsrv.getHeadersWithCsrf()).pipe(
      catchError(this.errorHandler.handleError('get parent child list : ', [])))
  }

  changePassword(passwordHash: any) {
    console.log("from changepaassower user")
    this.getStatusOfToken();
    let postdata = {
      passwordHash,
      token: this.getUserToken(),
      userId: this.getUserId(),
      username: this.getKeyFromUserDetails('login'),
    }
    // return this.http.post(this.urlConstants.API_URL_USER + 'change-encrypted-password', postdata, {
    // headers: this.getAuthHeader()
    // });
    return this.http.post<any>(this.urlConstants.API_URL_USER + 'change-encrypted-password', postdata,this.getAuthHeader()).pipe(
      catchError(this.errorHandler.handleError('get parent child list : ', [])))
    }

  public getUserToken() {
    return this.cookie.get('ACCESS_TOKEN');
  }
  public createUserInfo(userData) {
    var obj = {};
    if(userData === null){
      return obj;
    }
    obj['firstName'] = userData['firstName'];
    obj['lastName'] = userData['lastName'];
    obj['email'] = userData['email'];
    obj['mobileNo'] = userData['mobileNo'];
    obj['roles'] = userData['roles'];
    obj['userId'] = userData['userId'];
    return obj;

  }


  public getLoggedInUserRole() {
    let userInfo = this.cookie.get("USER_INFO");
    console.log(userInfo);
    
    if (!isNullOrUndefined(userInfo)) {
      let temp = JSON.parse(userInfo);
      return !isNullOrUndefined(temp.roles) && temp.roles.length > 0 ? temp.roles[0] : null;
    } else {
      return null;
    }
  }

  public getUserId() {
    let userInfo = this.cookie.get('USER_INFO');
    if (!isNullOrUndefined(userInfo)) {
      let temp = JSON.parse(userInfo);
      return !isNullOrUndefined(temp.userId) ? temp.userId : null;
    } else {
      return null;
    }
  }

  public getLoggedInUserDetailsJson() {
    let userInfo = this.cookie.get('USER_DETAILS_INFO');
    console.log(userInfo)
      return !isNullOrUndefined(userInfo) ? JSON.parse(userInfo) : null;
  }

  public getKeyFromUserDetails(key: string) {
    if (!isNullOrUndefined(key)) {
      let userDetails = this.cookie.get('userdetails');
      console.log("current user details ", userDetails);
      if (!!userDetails) {
        let temp = JSON.parse(userDetails);
        return !isNullOrUndefined(temp[key]) ? temp[key] : null;
      }else {
        return null;
      }
    }else {
      return null;
    }
  }

  getLocationList(postData) {
    this.getStatusOfToken();
    return this.http.post(this.constant.API_URL_USER+"cwmi/parentChildMap", postData);

  }

  getOfftakePointsData(postData) {
    console.log("post data for offtake pnts ", postData);
    return this.http.post(`${this.visibilityDshbrdUrl}canal/dropDownMetaData`,postData);
  }

  getDropDownValues(postData) {
    return this.http.post(`${this.visibilityDshbrdUrl}canal/getCanalData`,postData);
    // return this.http.post(` http://0728e8155329.ngrok.io/api/canal/getCanalData`,postData);
  }

  sendUserProjectMappingForCanal(postData) {
    return this.http.post(`${this.visibilityDshbrdUrl}` + 'canal/usermapping', postData);
  }

  editUserProjectMappingForCanal(postData) {
    return this.http.post(`${this.visibilityDshbrdUrl}` + 'canal/editusermapping', postData);
  }

  deleteUserProjectMapping(postData) {
    return this.http.post(`${this.visibilityDshbrdUrl}` + 'canal/deleteUserMapping', postData);
  }

  getOfftakes(postData){
    return this.http.post(`${this.visibilityDshbrdUrl}` + 'canal/getUserProjectMapping', postData);
  }
  // getStateHierarchy1(locationId : string) {
  //   this.getStatusOfToken();

  //   return this.http.get("http://cwmi.vassarlabs.com/dev/api/gec/stateHieAndAssmntData/"+ locationId);
  // }

  // getStateHierarchy(locationId: string): Promise<any> {

  //   let isBasin = false;
  //   // let locationId = this.currentLoggedInUserInfo['userLocationDetails']['locationId'];;

  //   let promise = new Promise((resolve, reject) => {
  //     this.getStateHierarchy1(locationId).subscribe((res: any) => {
  //       let response = res || {};
  //       if (response && (response['hierarchy']['BASIN'] )) {
  //         isBasin = true;
  //       }
  //       console.log("\n this.response \n ", response, isBasin);
  //       resolve(isBasin);
  //     }, err => {
  //       console.log("Error Occurred", err);
  //       reject(isBasin);
  //     });

  //   });
  //   return promise

  // }
}
