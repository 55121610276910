import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagementComponent } from './user-management.component';
import { UserListComponent } from './user-list/user-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { LoginComponent } from 'src/app/login/login.component';

const routes: Routes = [
  {
    path: 'user-management', component: UserManagementComponent, data: {permission: "USER_MANAGEMENT_PAGE"},
    children: [
      {path: '', redirectTo: 'users', pathMatch: 'full'},
      {path: 'users', component: UserListComponent},
      {path: 'add-user', component: AddUserComponent},
      {path: 'edit-user/:id', component: AddUserComponent}
    ]
  },
  { path: 'login', component: LoginComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule {}