import { Component, Input, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  @Input() layersList: any;
  @Input() map: any;
  currentComponent;
  windowSize: any;
  bottomBarOpen = false;
  measurementControl: any
  measureArea: any
  measureDistance: any;
  measureType: any
  urlParams: any;
  visibleShape: any
  constructor() { }

  ngOnInit() {
    this.windowSize = window.innerWidth;
  }
  changeComponents(component, close?) {
    this.map.dragging.enable();

    if (this.currentComponent == component) {
      this.currentComponent = null;
      this.bottomBarOpen = !this.bottomBarOpen;
    }
    else {
      this.currentComponent = component;
      this.bottomBarOpen = false;
    }
    if (!isNullOrUndefined(this.visibleShape))
      this.clearDrawToolShapes();

    if (this.currentComponent === 'Measure' && close) {
      if (close === true && this.measurementControl)
        this.measurementControl.stopMeasuring()

      this.measureArea = null;
      this.measureDistance = null;
      this.measureType = null;
    }
    if (this.currentComponent === 'Draw Tool') {
      if (!isNullOrUndefined(this.visibleShape))
        this.clearDrawToolShapes();
    }

  }
  clearDrawToolShapes() {
    for (const shape of this.visibleShape) {
      if (shape) {
        shape.shape.remove();
        if (shape.text) { shape.text.remove(); }
      }
    }
  }

  removeControl(event) {
    this.measurementControl = event.measureControl;
    this.measureType = event.measureType;
    this.measureArea = event.measureArea;
    this.measureDistance = event.measureDistance;
  }
  removeShapes(event) {
    this.visibleShape = event.visibleShapes;
  }
  revalidateMap() {

    if (this.map) {
      this.map.invalidateSize();
      this.map.fire('zoomend');
    }
  }
}
