import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './user-management.component';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { UserInfoComponent } from './user-info/user-info.component';
import { BrowserModule} from "@angular/platform-browser"
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    SidebarComponent,
    UserManagementComponent,
    UserListComponent,
    AddUserComponent,
    FilterPipe,
    UserInfoComponent,
    ShowErrorsComponent,
    ChangePasswordComponent,
  ],
  entryComponents: [
    UserInfoComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    UserManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ],
  exports: [
    FilterPipe,ShowErrorsComponent
  ]
})
export class UserManagementModule { }
