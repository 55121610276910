import { Component, OnInit, Input, TemplateRef, SimpleChanges } from '@angular/core';
import { DataTableEventsService } from '../../../services/datatable.events.service';

@Component({
  selector: 'lib-static-rows,[lib-static-rows]',
  templateUrl: './static-rows.component.html',
  styleUrls: ['./static-rows.component.scss']
})

export class StaticRowsComponent implements OnInit {

  @Input() totalCols : number;
  @Input() datarow: any;
  @Input() filteredData : any;
  @Input() showOnView: any;
  @Input() index: string;
  @Input() customTemplates: TemplateRef<any>;
  tableRef: HTMLTableElement;
  addFlag: boolean = false;
  recordLength: any;
  searchFlag: boolean = false;
  searchBox: any;
  indexArray: any=[];
 
  constructor(private tableEventsHandler : DataTableEventsService) { }

  ngOnInit() {
    this.recordLength = this.filteredData.length;
    for(let i=0;i<this.datarow.length;i++){
      let item = this.datarow[i];
      Object.keys(item).forEach((key) => {
        this.indexArray.push(key);
      })
    }
    this.indexArray.sort((a, b) => {
      return b - a;
    });
    this.tableEventsHandler.searchHolder.subscribe((data) => {
      this.searchBox = data; 
      if(this.searchBox.length>0){
        this.searchFlag = true;
      }else{
        this.searchFlag = false;
      }
    })

    // this.tableEventsHandler.togglePage.subscribe((options) => {
    //   let maxIndex = Number(this.indexArray[0]);
    //   if(maxIndex<=options['itemsPerPage'] && options['currentPage']==1){
    //     this.deleteRows();
    //     this.addRows();
    //   }
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if(this.datarow){
      if(this.showOnView){
        if(changes.filteredData.firstChange && !this.addFlag && !this.searchFlag){                    
          this.addRows();
        }else if(!changes.filteredData.firstChange && !this.addFlag && !this.searchFlag){
          this.addRows();
        }
        else if(!changes.filteredData.firstChange && this.addFlag && !this.searchFlag){
          this.deleteRows();
          this.addRows();
        }
      }else{
        if(this.addFlag){
          this.addFlag = false;
          this.deleteRows();
        }
      }
    }
  }

  addRows(){
    this.addFlag = true;
    this.tableRef = document.getElementById("tableDirective") as HTMLTableElement;
    for(let i=0;i<this.datarow.length;i++){
      let item = this.datarow[i];
      Object.keys(item).forEach((key) => {
        let newRow = this.tableRef.insertRow(Number(key));
        let x = newRow.insertCell(0);
        x.innerHTML = item[key];
        x.colSpan = this.totalCols;
        // newRow.innerHTML = item[key];
        newRow.id = "static_row";
      })
    }
  }

  deleteRows(){
    let item;
    this.tableRef = document.getElementById("tableDirective") as HTMLTableElement;
    let rowCount = this.tableRef.rows.length;      
    for(let i=rowCount-1;i>=0;i--){
      let row = this.tableRef.rows.item(i);
      if(row){
        item = row.id;
      }else{
        item ="";
      }
      if(item=='static_row'){
        this.tableRef.deleteRow(i);
      }
    }
  }
}
