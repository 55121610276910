
export const CANAL_PARENT_IDS = {
    STAGE1 : ["86d88206-7563-11e8-adc0-fa7ae01bbebc"],
    STAGE2 : ["0158fd37-38f0-4c98-bc48-041ed91e1faf", "777c0410-fce1-11ea-adc1-0242ac120002", "6724dc0e-fce1-11ea-adc1-0242ac120002"],
    canalUUID:"f48f37d9-767b-4cbd-94a6-7997f096390e"
};

export const DESIGNATIONS = {
    DESIGNATIONS : ["AEE", "DEE", "EE", "SE", "CE","AE/JE"],
};
  
export const STAGE_OPTIONS = {
    STAGES : ["ALL", "STAGE 1", "STAGE 2"],
};

export const MODULES = {
    MODULES : ["CAMS", "Reservoir", "Pump House", "River Guage", "Planning Dashboard","CM Screens"],
};