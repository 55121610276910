import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class MapConstantsModule {
  public selectedParams: BehaviorSubject<any> = new BehaviorSubject<any>({});

  setSelectedParams(value) {
    this.selectedParams.next(value);
  }

  getSelectedParams() {
    return this.selectedParams.asObservable();
  }
  gisVassarlabsStoreName = 'VASSARLABS';
  gisKaleshwaramStoreName = 'kaleshwaram';
  gisIPForGeo = 'http://geoserver.vassarlabs.com';
  gisIPForKaleshwaram = 'https://geo.vassarlabs.com';

  LAYER_TYPES = {
    'TILE_LAYER': 'TILELAYER',
    'WMS_LAYER': 'WMS',
    'VECTOR_LAYER': 'VECTOR',
    'GEOJSON_LAYER': 'GEOJSON',
    'HEATMAP_LAYER': 'HEATMAP'
  };

  LOCATIONS = {
    'TELANGANA': 'TELANGANA',
    'INDIA': 'INDIA',
    "STATE": "STATE",
    "DISTRICT": "COMMAND_DISTRICT",
    "MANDAL": "COMMAND_MANDAL",
    "Sri Ram Sagar Project": 'Sri Ram Sagar Project',
    "Lower Manair Dam": "Lower Manair Dam",
    "ALL": "ALL",
    'SRSP': 'Sri Ram Sagar Project',
    'VILLAGE': 'VILLAGE',
    'CANAL': 'CANAL',
    'RESERVOIR': 'RESERVOIR',
    'SITARAM': 'SITARAM'
  };
  VIEWS = {
    'ALL': 'ALL'
  };
  LOCATIONS_ZOOM = {
    [this.LOCATIONS['Lower Manair Dam']]: 9,
    [this.LOCATIONS['Sri Ram Sagar Project']]: 9,
    [this.LOCATIONS['TELANGANA']]: 8,
    [this.LOCATIONS['SITARAM']]: 10
  };

  LOCATIONS_LATLNG = {
    [this.LOCATIONS['Sri Ram Sagar Project']]: [18.15640114600696, 79.1093664004],
    [this.LOCATIONS['Lower Manair Dam']]: [18.048510991105418, 79.70288941238647],
    [this.LOCATIONS['TELANGANA']]: [18.087606552494625, 79.24710395866969],
    [this.LOCATIONS['SITARAM']]: [17.34244148142911, 80.67865412909187]
  };

  LOCATIONS_BBOX = {
    [this.LOCATIONS['Sri Ram Sagar Project']]: [[19.007593488870988, 81.6448974609375], [17.3034434020238, 76.57196044921876]],
    [this.LOCATIONS['Lower Manair Dam']]: [[18.864706878398053, 78.9093017578125], [18.864706878398053, 78.9093017578125]],
    [this.LOCATIONS['SITARAM']]: [[15.665354, 77.102051], [19.004997, 84.243164]]
  }
  SOURCES = {
    'GEOSERVER': 'GEOSERVER',
    'BACKEND': 'BACKEND',
  };
  LOCATIONS_ZOOM_RANGE = {
    [this.LOCATIONS.STATE]: {
      MIN: 6,
      MAX: 28
    },
    [this.LOCATIONS.DISTRICT]: {
      MIN: 6,
      MAX: 10
    },
    [this.LOCATIONS.MANDAL]: {
      MIN: 11,
      MAX: 28
    },
    [this.LOCATIONS.VILLAGE]: {
      MIN: 12,
      MAX: 28
    },
  };
  MAP_OPTIONS = {
    minZoom: this.LOCATIONS_ZOOM[this.LOCATIONS['SITARAM']],
    maxZoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE]['MAX'],
    zoomSnap: 1,
    zoom: this.LOCATIONS_ZOOM[this.LOCATIONS['SITARAM']],
    center: this.LOCATIONS_LATLNG[this.LOCATIONS['SITARAM']],
    zoomControl: false,
    zoomAnimation: true,
    dragging: true,
    maxBounds: [
      [40, 66],
      [5, 99]
    ],
    maxBoundsViscosity: 1.0
  };
  PANE_LIST = [
    {
      name: 'elev',
      zindex: '10'
    },
    {
      name: 'base',
      zindex: '390'
    },
    {
      name: 'baseI',
      zindex: '399'
    },
    {
      name: 'WA',
      zindex: '400'
    },
    {
      name: 'state',
      zindex: '401'
    },
    {
      name: 'district',
      zindex: '402'
    },
    {
      name: 'marker',
      zindex: '600'
    },
    {
      name: 'popUp',
      zindex: '602'
    },
    {
      name: 'structure',
      zindex: '700'
    },
    {
      name: 'popUpGeoJson',
      zindex: '701'
    },
    {
      name: 'mask',
      zindex: '700'
    },
    {
      name: 'max',
      zindex: '900'
    }
  ];
  BASE_LAYER_LIST = {
    [this.LAYER_TYPES.TILE_LAYER]: [
      {
        name: "Carto",
        displayName: "Carto",
        layerServiceType: "TILELAYER",
        type: 'Base',
        view: 'Admin',
        default_select: false,
        visible: false,
        url: 'http://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
        imagesrc: "assets//imgs/basemap/base-map.jpg",
        maxNativeZoom: 18,
        maxZoom: this.MAP_OPTIONS.maxZoom,
        // options: {
        //   maxNativeZoom : 18,
        //   maxZoom: this.MAP_OPTIONS.maxZoom,
        // }
      },
      {
        name: "Google Satellite",
        displayName: "Google Satellite",
        layerServiceType: "TILELAYER",
        type: 'Base',
        view: 'Admin',
        default_select: false,
        imagesrc: "assets//imgs/basemap/satellite.jpg",
        visible: true,
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
        maxNativeZoom: 18,
        maxZoom: this.MAP_OPTIONS.maxZoom,
        options: {
          // maxNativeZoom : 18,
          // maxZoom: this.MAP_OPTIONS.maxZoom,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }
      },
    ],
    // {
    //   "name": "Dark Google Satellite",
    //   "displayName": "Dark Google Satellite",
    //   "layerServiceType": "TILELAYER",
    //   "default_select": false,
    //   type : 'Base',
    //   view : 'Admin',
    //   "imagesrc": "../../assets/imgs/satellite.jpg",
    //   'visible' : false,
    //   'url': 'http://mt3.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    //   'options': {
    //       'maxZoom':22,
    //       'subdomains'  : ['mt0','mt1','mt2','mt3'],
    //     }
    //   },
    [this.LAYER_TYPES.WMS_LAYER]: [{
      name: "Sentinel 1",
      displayName: "Sentinel 1",
      layeroptions_name: "tg_sentinel1_mosaic",
      layerServiceType: "WMS",
      type: 'WMS',
      view: 'Admin',
      default_select: false,
      imagesrc: "assets//imgs/basemap/sentinel1.png",
      visible: false,
      url: 'http://159.65.4.230/geoserver/kaleshwaram/gwc/service/',
      options: {
        minZoom: 5,
        maxZoom: 25,
        pane: 'base'
      }
    },
    {
      name: "Sentinel 2 FCC",
      displayName: "Sentinel 2 FCC",
      layeroptions_name: "sentinel2_fc",
      layerServiceType: "WMS",
      type: 'WMS',
      view: 'Admin',
      default_select: false,
      imagesrc: "assets//imgs/basemap/sentinel2_fc.png",
      visible: false,
      url: 'http://159.65.4.230/geoserver/kaleshwaram/wms?',
      options: {
        minZoom: 5,
        maxZoom: 25,
        pane: 'base'
      }
    },
    {
      name: "Sentinel 2 TCC",
      displayName: "Sentinel 2 TCC",
      layeroptions_name: "sentinel2_TCC",
      layerServiceType: "WMS",
      type: 'WMS',
      view: 'Admin',
      default_select: false,
      imagesrc: "assets//imgs/basemap/sentinel2_tci.png",
      visible: false,
      url: 'http://159.65.4.230/geoserver/kaleshwaram/wms?',
      options: {
        minZoom: 5,
        maxZoom: 25,
        pane: 'base'
      }
    },
    ],
  };

  LAYERNAME_MAPPING = {
    [this.LOCATIONS.RESERVOIR]: ['Reservoir'],
    // [this.LOCATIONS.CANAL]: ['Canal'],
    // [this.LOCATIONS.DISTRIBUTARY]: ['Distributary', 'Distributary Label'],
    [this.LOCATIONS.DISTRICT]: ['District Label', 'District Boundaries']
  }

  LAYERNAMES_LIST = {
    [this.LOCATIONS.DISTRICT]: {
      "Label": "District Label",
      "Boundaries": "Telangana District Boundaries",
      "District Bound": "District Bound"
    },
    [this.LOCATIONS.MANDAL]: {
      "Label": "Mandal Label",
      "Boundaries": "Telangana Mandal Boundaries"
    },
    [this.LOCATIONS.VILLAGE]: {
      "Boundaries": "Village"
    },
    [this.LOCATIONS.ALL]: {
      'Canal': 'Sitarama Canal'
    }
  }

  COMMON_LOCATION_LAYER = {
    [this.LOCATIONS.ALL]: {
      "State": {
        name: "State",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layeroptions_name: "kaleshwaram:kwaram_forecast_ts_state",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        layeroptions_show_on_selector: false,
        layeroptions_pane: 'WA',
        layeroptions_styles: "kaleshwaram:kwaram_boundry",
        type: "WMS",
        cql_filter: null,
        group: "boundaries"
      },
      "Telangana District Name": {
        name: "District",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layeroptions_name: "kaleshwaram:corewris_kwaram_telangana_district_1.0",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.DISTRICT].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.DISTRICT].MAX,
        layeroptions_show_on_selector: false,
        layeroptions_pane: 'district',
        layeroptions_styles: "kaleshwaram:Corewris_District_Name",
        type: "WMS",
        group: "labels",
        cql_filter: null,
      },
      "Telangana Mandal Name": {
        name: "Mandal",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layeroptions_name: "kaleshwaram:corewris_kwaram_ts_mandal_1.0",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.MANDAL].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.MANDAL].MAX,
        layeroptions_show_on_selector: false,
        layeroptions_pane: 'district',
        layeroptions_styles: "kaleshwaram:Corewris_Mandal_Name",
        type: "WMS",
        group: "labels",
        cql_filter: null,
      },
      "Telangana Village Name": {
        name: "Village",
        visible: false,
        view: [this.LOCATIONS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layeroptions_name: "kaleshwaram:kwaram_telangana_village",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.VILLAGE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.VILLAGE].MAX,
        layeroptions_pane: 'district',
        layeroptions_styles: 'kaleshwaram:tsripms_village_label',
        type: "WMS",
        cql_filter: null,
        group: "labels"
      },
      "Telangana District Boundaries": {
        source: 'TELANGANA',
        displayName: "District",
        name: "STATE",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:corewris_kwaram_telangana_district_updated",
        // layeroptions_name: "VASSARLABS:corewris_kwaram_telangana_district",
        layer_name: "Telangana District Boundaries",
        layeroptions_pane: "state",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.DISTRICT].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.DISTRICT].MAX,
        group: "boundaries",
        group_type: "radio-deselect",
        layerServiceType: "VECTOR",
        cqlfilter: null,
        type: "VECTOR",
      },
      "Telangana Mandal Boundaries": {
        source: 'TELANGANA',
        displayName: "Mandal",
        name: "DISTRICT",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:corewris_kwaram_ts_mandal",
        // layeroptions_name: "VASSARLABS:corewris_kwaram_ts_mandal",
        layer_name: "Telangana Mandal Boundaries",
        layeroptions_pane: "district",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.MANDAL].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.MANDAL].MAX,
        layeroptions_max_nativeZoom: 18,
        group: "boundaries",
        group_type: "radio-deselect",
        layerServiceType: "VECTOR",
        type: "VECTOR",
      },
      "Village": {
        name: "Village",
        visible: true,
        view: [this.LOCATIONS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layeroptions_name: "kaleshwaram:kwaram_telangana_village",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.VILLAGE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.VILLAGE].MAX,
        layeroptions_pane: 'district',
        layeroptions_styles: 'kaleshwaram:CAMS_Village_Boundary',
        type: "WMS",
        cql_filter: null,
        group: "boundaries"
      },
      "Chainage": {
        layerFrom: this.SOURCES.GEOSERVER,
        name: "Chainage",
        visible: false,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:tsripms_srlip_pointsv3",
        layer_name: "reservoir_marker",
        layeroptions_pane: 'max',
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        group: "structures",
        pointToLayer: 'addChainagePointLayer',
        group_type: "radio-deselect",
        layerServiceType: this.LAYER_TYPES.GEOJSON_LAYER,
        cqlfilter: "",
        type: this.LAYER_TYPES.GEOJSON_LAYER,
        style: null,
        hasLegend: true,
        legendName: 'CHAINAGE',
      },
      "Polygon": {
        layerFrom: this.SOURCES.GEOSERVER,
        name: "Polygon",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:tsripms_srlip_linev2",
        layer_name: "reservoir_marker",
        layeroptions_pane: 'structure',
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        group: "structures",
        onEachFeature: 'addStructurePolygonLayer',
        group_type: "radio-deselect",
        layerServiceType: this.LAYER_TYPES.GEOJSON_LAYER,
        cqlfilter: "",
        type: this.LAYER_TYPES.GEOJSON_LAYER,
        style: null,
        // hasLegend: true,
        // legendName: 'STRUCTURE',
      },
      "Structure": {
        layerFrom: this.SOURCES.GEOSERVER,
        name: "Chainage",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:tsripms_srlip_pointsv3",
        layer_name: "reservoir_marker",
        layeroptions_pane: 'max',
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        group: "structures",
        pointToLayer: 'addStructurePointLayer',
        group_type: "radio-deselect",
        layerServiceType: this.LAYER_TYPES.GEOJSON_LAYER,
        cqlfilter: "",
        type: this.LAYER_TYPES.GEOJSON_LAYER,
        style: null,
        hasLegend: true,
        legendName: 'STRUCTURE',
      },
      "Issues": {
        layerFrom: this.SOURCES.BACKEND,
        name: "Issues",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layer_name: "issues",
        layeroptions_pane: 'max',
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        group: "alerts",
        onEachFeature: 'addIssueLayer',
        group_type: "radio-deselect",
        layerServiceType: this.LAYER_TYPES.GEOJSON_LAYER,
        cqlfilter: "",
        type: this.LAYER_TYPES.GEOJSON_LAYER,
      },
      "Sitarama Canal": {
        source: 'TELANGANA',
        displayName: "Sitarama Canal",
        name: "CANAL",
        visible: true,
        view: [this.VIEWS.ALL],
        parentView: [this.LOCATIONS.ALL],
        layerptions_show_on_selector: false,
        layeroptions_name: "kaleshwaram:tsripms_sitaram_maincanal_v2",
        layer_name: "Sitarama Canal",
        layeroptions_pane: "state",
        layeroptions_min_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MIN,
        layeroptions_max_zoom: this.LOCATIONS_ZOOM_RANGE[this.LOCATIONS.STATE].MAX,
        group: "canal",
        group_type: "radio-deselect",
        layerServiceType: "VECTOR",
        cqlfilter: null,
        type: "VECTOR",
      }
    }
  }

  LEGEND_DATA = {
    "CHAINAGE": {
      "title": 'CHAINAGE ',
      'levels': [
        {
          'color': '#b01e1e',
          'name': ''
        }
      ]
    },
    "STRUCTURE": {
      "title": '',
      'levels': [
        {
          'color': '#e889ff',
          'name': 'Structure'
        },
        {
          'color': '#ff3b3b',
          'name': 'Pump House'
        }
      ]
    }
  };
  LAYERKEY_SOURCE_MAP = {
    "Sentinel 1": "SENTINEL_1",
    "Sentinel 2 FCC": "SENTINEL_2",
    "Sentinel 2 TCC": "SENTINEL_2"
  }
  SOURCE_COORDINATE_MAP = {
    "SENTINEL_1": [{
      point: { x: 665, y: 352 },
      bbox: "78.39294433593751,18.665762138680716,79.00474548339845,18.838714379258032",
      size: { y: 532, x: 1782 }
    },
    {
      point: { x: 1064, y: 412 },
      bbox: "76.937255859375,18.012691932112393,81.83166503906251,19.396658607621557",
      size: { y: 532, x: 1782 }
    }
    ],
    "SENTINEL_2": [{
      point: { x: 776, y: 247 },
      bbox: "73.26232910156251,17.046281225389077,83.05114746093751,19.81839009384496",
      size: { y: 532, x: 1782 }
    }, {
      point: { x: 665, y: 352 },
      bbox: "78.39294433593751,18.665762138680716,79.00474548339845,18.838714379258032",
      size: { y: 532, x: 1782 }
    },
    {
      point: { x: 1227, y: 421 },
      bbox: "74.43786621093751,16.99375545289456,84.19372558593751,19.766703551716976",
      size: { y: 532, x: 1782 }
    }
    ]
  }

  SOURCES_LIST_WATERSPREAD = ["SENTINEL_1", "SENTINEL_2"];

  SOURCE_DEPTH_LAYER_MAP = {
    "SENTINEL_1": "Depth_Class",
    "SENTINEL_2": "Depth_Class_Sentinel2",
    "SENTINEL1": "Depth_Class",
    "SENTINEL2": "Depth_Class_Sentinel2"
  };
  BASELAYER_METADATA_LAYER = {
    "BASELAYER_METADATA": {
      name: "BASELAYER_METADATA",
      visible: false,
      layeroptions_name: "kaleshwaram:baselayer_metadata",
      layeroptions_min_zoom: 1,
      layeroptions_max_zoom: 18,
      layeroptions_show_on_selector: false,
      layeroptions_pane: 'elev',
      type: "WMS",
      cql_filter: null,
      group: null,
    }
  }
}
