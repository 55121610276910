import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule } from './data-table/data-table.module';
import { TablePipe } from './common/utils/table.pipe';

@NgModule({
  declarations: [TablePipe],
  imports: [
    CommonModule,
    DataTableModule,
    
  ],
  exports:[DataTableModule,TablePipe]
})
export class TableModule { }
