import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationError, Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from '../services/http.service';
import { UtilsService } from '../services/utils.service';
import { NgbModal, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageExpandModalComponent } from '../image-expand-modal/image-expand-modal.component';
import { SharedConstantsModule } from '../shared/shared-constants.module';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-datatab',
  templateUrl: './datatab.component.html',
  styleUrls: ['./datatab.component.scss']
})
export class DatatabComponent implements OnInit {

  @Output() closepopupover = new EventEmitter<any>();
  issuetype;
  issuelocation;
  dateraised;
  issueowner;
  resolutionstatus;
  escalationlevel;
  issuediscription;
  flag: boolean = true;
  dataTabObj: any = {};
  urlParams: any = {};
  structureObj: any = {};
  navigationSubscription: Subscription;
  issueTableObj: any = {};
  MEDIA_URL: any = environment.urlMapping['MEDIA_URL'];
  dataArray: any[];

  constructor(private utilsService: UtilsService,
    private dataService: HttpService,
    private router: Router,
    private route: ActivatedRoute, private modalService: NgbModal,
    private sharedConstants: SharedConstantsModule) {
    this.navigationSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.urlParams = this.utilsService.checkUrlParams(this.route.snapshot.params);
        this.onParamsChange();
      }
      if (event instanceof NavigationError) {
        console.log(event.error); // Hide loading indicator  // Present error to user
      }
    });
  }

  ngOnInit() {
    this.onParamsChange();
  }
  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }
  onParamsChange() {
    this.getProjectDataTabInfo();
    if (this.urlParams.click == 'mapClick')
      this.getStructureInfo();
    if (this.urlParams.click == 'alertClick') {
      this.getIssueTable();
    }
  }
  navigateBack() {
    let params = {
      locType: this.sharedConstants.LOC_CONSTANT[0],
      sDate: 20210101,
      eDate: 20210306
    }
    this.router.navigate(['tsripms', 'geoportal', 'gis', params]);
  }
  getStructureInfo() {
    this.structureObj['chainage'] = this.urlParams['chainage'] ? this.urlParams['chainage'] : '-';
    this.structureObj['structure'] = this.urlParams['structure'] ? this.urlParams['structure'] : null;
    this.structureObj['lat'] = this.urlParams['lat'];
    this.structureObj['lng'] = this.urlParams['lng'];
  }
  getProjectDataTabInfo() {
    // let postData = {}
    // if (this.urlParams['packageName']) {
    //   postData['name'] = this.urlParams['packageName'];
    // }
    // else {
    //   postData['name'] = 'SITA-RAMA-PPM';
    // }
    // this.dataService.getMetaData(postData).subscribe((response: any) => {
      // if (response.md) {
      //   this.dataTabObj['canalLength'] = response['md']['length'] ? this.utilsService.fixedToDecimal(response['md']['length'], 2) : '-';
      //   this.dataTabObj['discharge'] = response['md']['desDischargeCanal'] ? this.utilsService.fixedToDecimal(response['md']['desDischargeCanal'], 2) : '-';
      //   this.dataTabObj['noOfPumps'] = response['md']['noOfPumpMotors'] ? response['md']['noOfPumpMotors'] : '-';
      // }
      this.dataService.getMetaData(this.sharedConstants.projectUUID).subscribe((response: any) => {
      
      this.dataArray =[];
      Object.keys(response).forEach((key: any) => {
        if (key == 'Sita Rama LIS') {
          let data = response[key];
          let item = {};
          item['len'] = data['len'] ? this.utilsService.fixedToDecimal(data['len'],2) : '-';
          item['ddcanal'] = data['ddcanal'] ? this.utilsService.fixedToDecimal(data['ddcanal'],2) : '-';
          item['pmotors'] = data['pmotors'] ? data['pmotors'] : '-';
          this.dataArray.push(item);
        }
        });
    });
  }

  public close() {
    let o = {
      'close': true
    }
    this.closepopupover.emit(o);
  }

  toggleTab() {
    this.flag = !this.flag;
  }
  downloadJPG() {

  }
  openModal() {

  }
  getIssueTable() {
    this.issueTableObj = {};
    this.dataService.getAlertDetailsForDataTab(this.urlParams['eUUID']).subscribe((res: any) => {
      if (!this.utilsService.isEmptyObj(res)) {
        let obj = {
          alertId: res['esid'] ? res['esid'] : '-',
          issuetype: res['etype'] ? res['etype'] : '-',
          issuelocation: res['en'] ? this.utilsService.splitLocationString(res['en'].toString()) : '-',
          dateraised: res['eraisd'] ? this.utilsService.getDateInDDMMYYYYFormat(res['eraisd'].toString()) : '-',
          referTo: res['rname'] && res['rto'] ? res['rname'].concat(' ').concat(res['rto']) : '-',
          issueowner: res['uname'] ? res['uname'] : '-',
          resolutionstatus: res['status'] ? res['status'] : '-',
          issuediscription: res['erem'] ? res['erem'] : '-',
          issuecategory: res['estype'] ? res['estype'] : '-',
          imgPath: res['idata'] ? this.MEDIA_URL.concat(res['idata']) : '-',
          package: res['pname'] ? res['pname'] : '-'
        }
        this.issueTableObj = obj;
      }
      else {
        let obj = {
          alertId: '-',
          issuetype: '-',
          issuelocation: '-',
          dateraised: '-',
          referTo: '-',
          issueowner: '-',
          resolutionstatus: '-',
          issuediscription: '-',
          issuecategory: '-',
          imgPath: '-',
          package: '-'
        }
        this.issueTableObj = obj;
      }
    });
  }
  expandImageModal(imgPath?) {

    const modal = this.modalService.open(ImageExpandModalComponent, {
      size: 'lg'
    });
    modal.componentInstance.imgPath = imgPath;
  }
}

