import { isNullOrUndefined } from 'util';
import { IRole } from '../interfaces/role';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Iuser } from '../interfaces/user';
// import { Roles } from '../enums/roles.enum';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProjectData } from './project-data';
import { CANAL_PARENT_IDS } from '../../user-management/user_management_constants';
import { DESIGNATIONS } from '../../user-management/user_management_constants';
import { MODULES } from '../../user-management/user_management_constants';
import { STAGE_OPTIONS } from '../../user-management/user_management_constants';
import { JsonPipe } from '@angular/common';
import { from } from 'rxjs';
import { EncdecService } from 'src/app/services/encdec.service';
import { ConstantsModule } from 'src/app/constants/constants.module';
import { UserManagementService } from 'src/app/services/user-management.service';
import { AuthloginService } from 'src/app/services/authlogin.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  showStateDropdown: Boolean;
  userForm: FormGroup = this.fb.group({
    username: new FormControl(null, [Validators.required, Validators.pattern("[98765][0-9]{9}")]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, []),
    // module: new FormControl(null, [Validators.required]),
    role: new FormControl(null, [Validators.required]),
    passwordHash: new FormControl(null, [Validators.required]),
    confirmPassword: new FormControl(null, [Validators.required]),
    mail: new FormControl(null, []),
    mobile: new FormControl(null, [Validators.pattern("[98765][0-9]{9}")]),
    designation: new FormControl(null, [Validators.required]),
    // stage: new FormControl(null, []),
    // circle: new FormControl(null, []),
    // division: new FormControl(null, []),
    // offtakes: new FormControl(null, []),
    // distributary : new FormControl(null, []),
    // minor : new FormControl(null, []),
    // subMinor : new FormControl(null, []),
    // state: new FormControl('-1', [Validators.required, Validators.pattern("^((?!-1).)*$")]),
    // district: new FormControl('-1', [Validators.required, Validators.pattern("^((?!-1).)*$")]),
    countryId: new FormControl(null, []),
    countryName: new FormControl(null, []),
    stateId: new FormControl(null, []),
    stateName: new FormControl(null, []),
    locationId: new FormControl(null, []),
    locationName: new FormControl(null, []),
    locationType: new FormControl(null, []),

    // designation: new FormControl('-1', [Validators.required, Validators.pattern("^((?!-1).)*$")]),

    // secondary: new FormControl('-1', []),
  },{validator: this.sameValueAs() })

  userNameMaxLength = 524288;
  isValid:boolean = false;
  dropdownSettings1 = {
    singleSelection: false,
    text: "Select Offtake Points",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class"
  };
  dropdownSettings2 = {
    singleSelection: false,
    text: "Select Distributaries",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    classes: "myclass custom-class"
  };
  dropdownSettings3 = {
    singleSelection: false,
    text: "Select Minors",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    classes: "myclass custom-class"
  };
  dropdownSettings4 = {
    singleSelection: false,
    text: "Select Sub-Minors",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    classes: "myclass custom-class"
  };
  locationMapping = {
    SUPER_ADMIN: {
      ADMIN: {
        cType: "STATE",
        pType: "COUNTRY",
        puuid: "",
        type: "state"
      }
    },
    ADMIN: {
      ADMIN: {
        cType: "STATE",
        pType: "COUNTRY",
        puuid: "",
        type: "state"
      }
    }
  }

  isBasin: boolean = false;
  currentLoggedInUserInfo = {};
  locationList: any[] = [];
  roles: IRole[] = [];
  districts: any[] = [];
  states: any[] = [];
  showError: boolean = false;
  showDistrict: boolean = false;
  userId: number;
  context: string = 'Add';
  user: Iuser;
  designation: any[] = [];
  stage1: any[] = [];
  projectData: any[] = [];
  selectedDist: { id: number; itemName: string; }[];
  selectedMinor : { id: number; itemName: string; }[];
  stage1List: { id: number, itemName: string; }[] = [];
  stage2List: { id: number, itemName: string; }[] = [];
  offtakeList: { id: number, itemName: string; }[] = [];
  distributaryList : { id: number, itemName: string; }[] = [];
  minorList : { id: number, itemName: string; }[] = [];
  subMinorList : { id: number, itemName: string; }[] = [];
  selectedSubMinor : { id: number, itemName: string; }[] = [];
  selectedItems: any[] = [];
  stages: any = {};
  offtakePointNames: any[] = [];

  private canalParentIds: object = CANAL_PARENT_IDS;
  private designationVals: object = DESIGNATIONS;
  private moduleVals: object = MODULES;
  private stageOptions: object = STAGE_OPTIONS;
  circles: any = {};
  divisions: any={};
  distributaries: any={};
  minors: any={};
  subMinors: any={};
  distributariesNames: any=[];
  minorsNames : any =[];
  subMinorsNames : any =[];
  selectedDistValues: { id: number; itemName: string; }[]=[];
  selectedMinorValues: { id: number; itemName: string; }[]=[];
  selectedSubMinorValues : { id: number; itemName: string; }[]=[];
  moduleNames: any=[];
  userdetail:object = {};
  moduleType: any;
  roleAdmin: boolean= false;

  constructor(
    private userService: UserManagementService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modal: NgbActiveModal,
    private toastrService: ToastrService,
    private encdecservice: EncdecService,
    private constant: ConstantsModule,
    private sessionlogin : AuthloginService,
  ) { }

  ngOnInit() {
    // this.initProjectListDropDown();
    this.setCurrentLoggedInUserInfo();
    this.loadData();
    if (this.user) {
      this.userId = this.user.userId;
      if (!isNullOrUndefined(this.userId)) {
        this.context = 'Update';
        // this.getOfftakeData();
        this.loadUserData();
        this.userForm.get('passwordHash').clearValidators();
        this.userForm.get('confirmPassword').clearValidators();
        this.loadLocations("edit");
      }
    } else {
      this.setDefaultFormControls();
      this.loadLocations("add");
    }
  }


  sameValueAs() : ValidatorFn{
    return (group: FormGroup): ValidationErrors => {            
       const control1 = group.controls['passwordHash'];
       const control2 = group.controls['confirmPassword'];       
       if(control1!=null && control2!=null){
        if (control1.value !== control2.value) {          
          control2.setErrors({notEquivalent: true});
        } else {
          control2.setErrors(null);
        }
       }       
       return;
 };
}

  // onItemSelect(item: any) {    
  //   this.selectedItems.push(item);
  //   this.isFormValid();
  // }

  // OnItemDeSelect(item: any) {
  //   let index = this.selectedItems.findIndex(val => val['id'] == item['id']);
  //   if (index > -1)
  //     this.selectedItems.splice(index, 1);
  //   this.isFormValid();
  // }

  // onSelectAll(items: any) {
  //   items.forEach(element => {
  //     let index = this.selectedItems.findIndex(val => val['id'] == element['id']);
  //     if (index == -1)
  //       this.selectedItems.push(element);
  //   });
  //   this.isFormValid();
  // }

  // onDeSelectAll(items: any) {
  //   this.selectedItems = [];
  //   this.isFormValid();
  // }

  // onChange() {
  // }

 
  // isFormValid(){
  //   let  isValid = this.userForm.valid;
  //   isValid = isValid && (this.userForm.value.stage!= null);
  //   isValid = isValid && (this.userForm.value.circle!= null);
  //   isValid = isValid && (this.userForm.value.division!= null);
  //   // isValid = isValid && (this.selectedItems.length>0);
  //   this.isValid = isValid;
  // }

  submit() {
    // this.isFormValid();
  }

  getCtrl(ctrlName) {
    return !isNullOrUndefined(this.userForm.controls[ctrlName]) ? this.userForm.controls[ctrlName] : null;
  }

  hasError(ctrlName) {
    return (this.getCtrl(ctrlName).touched || this.getCtrl(ctrlName).dirty) && !isNullOrUndefined(this.getCtrl(ctrlName).errors);
  }

  setCurrentLoggedInUserInfo() {
    this.currentLoggedInUserInfo["role"] = this.userService.getLoggedInUserRole();
    this.currentLoggedInUserInfo["userLocationDetails"] = this.userService.getLoggedInUserDetailsJson();
  }

  roleChanged(role) {
    this.roleAdmin = false;
    this.showStateDropdown = false;
    if (isNullOrUndefined(this.userId)) {
      this.userForm.get('username').reset();
      this.userForm.get('username').clearValidators();
    }
    if(role == 'ADMIN'){
      this.roleAdmin = true;
      this.userForm.get('username').setValidators([Validators.required]);
      this.userNameMaxLength = 524288;
    }else{
      this.roleAdmin = false;
      this.userForm.get('username').setValidators([Validators.required, Validators.pattern("[98765][0-9]{9}")]);
      this.userNameMaxLength = 10;
    }
    if (role == 'STATE_NODAL_OFFICER') {
      this.showStateDropdown = true;
    }
  }

  setDefaultFormControls() {
    this.userForm.patchValue({
      countryId: this.currentLoggedInUserInfo['userLocationDetails']['countryId'],
      countryName: this.currentLoggedInUserInfo['userLocationDetails']['countryName']
    });
  }

  loadUserData() {

    this.userForm.patchValue({
      username: this.user.username || null,
      firstName: this.user.firstName || null,
      lastName: this.user.lastName || null,
      role: this.user.roles[0] || null,
      mail: this.user.email != 'null' ? this.user.email : null,
      passwordHash: this.user.passwordHash || null,
      mobile: this.user.mobileNo || null,
      designation: this.user.userDetailsJson && this.user.userDetailsJson.designation ? this.user.userDetailsJson.designation : null,
      // module: this.user.userDetailsJson && this.user.userDetailsJson.module ? this.user.userDetailsJson.module : null,
      stage1: null,
      stage2: null,
      countryId: this.user.userDetailsJson && this.user.userDetailsJson.countryId ? this.user.userDetailsJson.countryId : null,
      countryName: this.user.userDetailsJson && this.user.userDetailsJson.countryName ? this.user.userDetailsJson.countryName : null,
      stateId: this.user.userDetailsJson && this.user.userDetailsJson.stateId ? this.user.userDetailsJson.stateId : null,
      stateName: this.user.userDetailsJson && this.user.userDetailsJson.stateName ? this.user.userDetailsJson.stateName : null,
      locationId: this.user.userDetailsJson && this.user.userDetailsJson.locationId ? this.user.userDetailsJson.locationId : null,
      locationName: this.user.userDetailsJson && this.user.userDetailsJson.locationName ? this.user.userDetailsJson.locationName : null,
      locationType: this.user.userDetailsJson && this.user.userDetailsJson.locationType ? this.user.userDetailsJson.locationType : null,
    })
    if(this.user.roles[0]  == 'ADMIN'){
      this.userForm.get('username').setValidators([Validators.required]);
      this.userNameMaxLength = 524288;
    }else{
      this.userForm.get('username').setValidators([Validators.required, Validators.pattern("[98765][0-9]{9}")]);
      this.userNameMaxLength = 10;
    }
    if (this.user.roles[0] == 'STATE_NODAL_OFFICER') {
      this.roleChanged("STATE_NODAL_OFFICER")
    }
  }


  async loadData() {

    this.userdetail = this.sessionlogin.checkloginstatus();
    // this.moduleType = this.userdetail['module'];
    // if(this.moduleType!='-'){
    //   this.userForm.controls.module.setValue(this.moduleType);
    // }
    // Load Roles.
    let userRole = this.userService.getLoggedInUserRole();
    this.userService.getRoles(userRole).subscribe((res: any) => {
      this.roles = res.result.content[userRole] || [];
      // if(this.moduleType=='CAMS'){
      //   this.roles = res.result.content['CAMS_USER'] || [];
      // }else{
      //   this.roles = res.result.content[userRole] || [];
      // }
    }, err => console.log(err));

    this.designation = this.designationVals['DESIGNATIONS'];
    this.moduleNames = this.moduleVals['MODULES'];
    let postData={
      parentUUID : '927b67e9-a2aa-46e9-99bc-41cf8c502668',
      parentLocTypeName : 'STATE',
      childLocTypeName : 'CANAL'
    }
    // this.stages = await this.getDropDownValues(postData);
    // if(this.moduleType=='CAMS'){
    //   await this.getProjectDataForAssignment();
    // }
  }

  async loadLocations(evtSrc?: string) {
    let userRole = this.userService.getLoggedInUserRole();
    let postObj;
    if (this.isBasin) {
      postObj = this.locationMapping[this.currentLoggedInUserInfo["role"]]['BASIN'];
    } else {
      postObj = this.locationMapping[this.currentLoggedInUserInfo["role"]]['ADMIN'];
    }

    postObj.puuid = this.currentLoggedInUserInfo['userLocationDetails']['countryId'];
  }


  async setLocation(locType: String) {
    if (locType == 'STATE') {
      let index = this.locationList.findIndex(location => location['uuid'] == this.userForm.value.stateId);
      if (index > -1) {
        let selectedStateName = this.locationList[index]['name'];
        this.userForm.patchValue({
          stateName: selectedStateName,
          locationType: 'STATE',
          locationName: selectedStateName,
          locationId: this.userForm.value.stateId
        });
      }
    } else {
      let index = this.locationList.findIndex(location => location['uuid'] == this.userForm.value.locationId);
      if (this.isBasin) {
        locType = 'BASIN';
      } else {
        locType = 'DISTRICT';
      }
      if (index > -1) {
        let selectedDistrictName = this.locationList[index]['name'];
        this.userForm.patchValue({
          locationType: locType,
          locationName: selectedDistrictName,
        });
      }

    }
  }


  sortLocations(data) {
    return data.sort((a, b) => a['name'] > b['name'] ? 1 : -1);
  }

  async handleAddUser() {    
    this.showError = true;
    let roleName = this.userForm.controls['role'].value;    
    if(this.moduleType=='CAMS' && roleName=='OTHERS'){
      // this.isFormValid();
    }else{
      this.isValid = this.userForm.valid;
    }
    if (this.isValid) {
      let temp = this.userForm.value;

      let userData: Iuser = {
        username: temp.username,
        firstName: temp.firstName,
        lastName: temp.lastName || '',
        roles: temp.role,
        email: temp.mail || '',
        mobileNo: temp.mobile,
        status: 1,
        passwordHash: temp.passwordHash ? this.encdecservice.rsaenc(this.constant.PUBLIC_KEY, temp.passwordHash) : temp.passwordHash,
        userDetailsJson: {
          stateName: temp.stateName,
          module : temp.module,
          stateId: temp.stateId,
          countryId: temp.countryId,
          countryName: temp.countryName,
          locationType: temp.locationType,
          locationName: temp.locationName,
          locationId: temp.locationId,
          designation:temp.designation,
          circle:this.circles[temp.circle],
          division:this.divisions[temp.division], 
          permissions: {
            25: {
              PERMITTED: [
                {
                  entityType: temp.locationType ? (temp.locationType).toString().toUpperCase() : null,
                  entityList: [
                    temp.locationId
                  ]
                }
              ]
            }
          }
        }
      }

      if (temp.passwordHash == temp.confirmPassword) {

        let postdata = {
          ...userData,
          roles: [userData.roles],
        }
        if (this.user) {
          postdata.userId = this.user.userId;
          postdata.username = this.user.username;
          this.userService.updateUser(postdata).subscribe(async (res: any) => {
            if (res.result.success) {
              if(this.moduleType=='CAMS' && roleName=='OTHERS'){
                let sent = await this.initEditUserProjectMapping(userData);
                if (sent == true) {
                  Swal.fire('Success...', res.result.content, 'success');
                  this.modal.close();
                }
              }else{
                Swal.fire('Success...', res.result.content, 'success');
                this.modal.close();
              }
            } else {
              if (res.result.status != 406) {
                Swal.fire('Failure...', res.result.content, 'error');
              }
            }
          }, (err) => {
            Swal.fire('Failure...', err.message, 'error');
          })
          return;
        }
        this.userService.createUser(postdata).subscribe(async (res: any) => {
          if (res.result.success) {
            if(this.moduleType=='CAMS' && roleName=='OTHERS'){
              let sent = await this.initUserProjectMapping(userData);
              if (sent == true) {
                Swal.fire('Success...', res.result.content, 'success');
                this.modal.close();
              }
            }else{
              Swal.fire('Success...', res.result.content, 'success');
              this.modal.close();
            }
          } else {
            if (res.result.status != 406) {
              Swal.fire('Failure...', res.result.content, 'error');
            }
          }
        }, (err) => {
          Swal.fire('Failure...', err.message, 'error');
        })
      } else {
        this.toastrService.error("Password and Confirm Password should be same");
      }
    }
  }

  // async stageChanged(stageUUID) {
  //   let postData={
  //     parentUUID : stageUUID,
  //     parentLocTypeName : 'CANAL',
  //     childLocTypeName : 'CANAL_CIRCLE'
  //   }
  //   this.circles = await this.getDropDownValues(postData);
  //   this.divisions = [];
  //   this.distributaryList = [];
  //   this.minorList = [];
  //   this.subMinorList = [];
  //   this.userForm.controls.circle.setValue(null);
  //   this.userForm.controls.division.setValue(null);
  //   this.userForm.controls.distributary.setValue(null);
  //   this.userForm.controls.minor.setValue(null);
  //   this.userForm.controls.subMinor.setValue(null);
  //   this.isFormValid();
  // }

  // async circleChanged(circleUUID){
  //   let postData={
  //     parentUUID : circleUUID,
  //     parentLocTypeName : 'CANAL_CIRCLE',
  //     childLocTypeName : 'CANAL_DIVISION'
  //   }
  //   this.divisions = await this.getDropDownValues(postData);
  //   this.distributaryList = [];
  //   this.minorList = [];
  //   this.subMinorList = [];
  //   this.userForm.controls.division.setValue(null);
  //   this.userForm.controls.distributary.setValue(null);
  //   this.userForm.controls.minor.setValue(null);
  //   this.userForm.controls.subMinor.setValue(null);
  //   this.isFormValid();
  // }

  // async divisionChanged(divisionUUID){
  //   let postData1={
  //     parentUUID : divisionUUID,
  //     parentLocTypeName : 'CANAL_DIVISION',
  //     childLocTypeName : 'DISTRIBUTARY_CANAL'
  //   }
  //   let postData2={...postData1}
  //   postData2.childLocTypeName = 'MINOR_CANAL';
  //   let postData3={...postData1}
  //   postData3.childLocTypeName = 'SUB_MINOR_CANAL';
  //   this.distributaries = await this.getDropDownValues(postData1);
  //   this.minors = await this.getDropDownValues(postData2);
  //   this.subMinors = await this.getDropDownValues(postData3);
  //   this.userForm.controls.distributary.setValue(null);
  //   this.userForm.controls.minor.setValue(null);
  //   this.userForm.controls.subMinor.setValue(null);
  //   let i=1,j=i+300,z=j+300;
  //   Object.keys(this.distributaries).forEach((key) => {
  //     let x = { "id": i, "itemName": this.distributaries[key] };
  //     this.distributaryList.push(x);
  //     i++;
  //   })
  //   Object.keys(this.minors).forEach((key) => {
  //     let x = { "id": j, "itemName": this.minors[key] };
  //     this.minorList.push(x);
  //     j++;
  //   })
  //   Object.keys(this.subMinors).forEach((key) => {
  //     let x = { "id": z, "itemName": this.subMinors[key] };
  //     this.subMinorList.push(x);
  //     z++;
  //   })
  //   this.isFormValid();
  // }

  async initProjectListDropDown() {
    let projList = await this.getProjectDataForAssignment();
    let stage1ParentIds: string[] = this.canalParentIds['STAGE1'];
    let stage2ParentIds: string[] = this.canalParentIds['STAGE2'];

    let i = 1, j = 1;
    projList.forEach(element => {
      if (stage1ParentIds.indexOf(element.parentUUID) > -1) {
        let x = { "id": i, "itemName": element.projDisplayName };
        this.stage1List.push(x);
        i++;
      } else if (stage2ParentIds.indexOf(element.parentUUID) > -1) {
        let x = { "id": j, "itemName": element.projDisplayName };
        this.stage2List.push(x);
        j++;
      }
    });
  }

  initEditUserProjectMapping(userData): Promise<any> {
    let sent = false;
    let userMappingPostData = this.getPostDataForAddOrUpdateUser(userData);
    userMappingPostData.user_id = this.user.username;
    let promise = new Promise((resolve, reject) => {
      this.userService.editUserProjectMappingForCanal(userMappingPostData).subscribe((res: any) => {
        let jsonStr = JSON.stringify(res);
        let parsedJson = JSON.parse(jsonStr);
        if (parsedJson == "Successful") {
          resolve(true);
        }
      }),
        err => {
          console.log("Error Occurred", err);
          reject(sent);
        }
    });
    return promise;
  }

  initUserProjectMapping(userData): Promise<any> {
    let sent = false;
    let userMappingPostData = this.getPostDataForAddOrUpdateUser(userData);
    let promise = new Promise((resolve, reject) => {
      this.userService.sendUserProjectMappingForCanal(userMappingPostData).subscribe((res: any) => {
        let jsonStr = JSON.stringify(res);
        let parsedJson = JSON.parse(jsonStr);
        if (parsedJson == "Successful") {
          resolve(true);
        }
      }),
        err => {
          console.log("Error Occurred", err);
          reject(sent);
        }
    });
    return promise;
  }

  getProjectDataForAssignment(): Promise<any> {
    let data: any[] = [];
    let response = {};
    let postData = {
      'canalUUID': this.canalParentIds['canalUUID']
    }
    let promise = new Promise((resolve, reject) => {
      this.userService.getOfftakePointsData(postData).subscribe((res: any) => {
        let str = res;
        for (let key in str) {
          let projData = {};
          projData['projUUID'] = key;
          projData['parentUUID'] = str[key]['puuid'];
          // projData.entityUUID = str[key]['eUUID'];
          projData['projDisplayName'] = str[key]['name'];
          let nameIndex = this.offtakePointNames.indexOf(projData['projDisplayName']);
          if(nameIndex < 0) {
            this.offtakePointNames.push(projData['projDisplayName']);
            data.push(projData);
            this.projectData.push(projData);
          }
        }
        resolve(data);
      }),
        err => {
          console.log("Error Occurred", err);
          reject(response);
        }
    });
    return promise;
  }

  getPostDataForAddOrUpdateUser(userData) {
    let postdata = {
      user_id: this.userForm.value.username,
      user_type: this.userForm.value.role,
      proj_ids: this.getUUIDSOfAssignedProjects(),   // user-project mapping
    }
    return postdata;
  }

  getUUIDSOfAssignedProjects() {
    let projUUIDs = [];
    let merge1 = Object.assign(this.distributaries,this.minors);
    let merge = Object.assign(this.subMinors,merge1);    
    this.selectedItems.forEach(element => {
      Object.keys(merge).forEach((key) => {
        if(element['itemName']==merge[key]){
          projUUIDs.push(key);
        }
      });
    });
    return projUUIDs;
  }

  resetValues() {
    this.userForm.reset();
    this.states = [];
    this.districts = [];
    this.roles = [];
  }

  resetLocations() {
    this.userForm.patchValue({
      primary: '-1',
      secondary: "-1"
    })
  }

  closeModal() {
    this.modal.dismiss();
  }

  // getDropDownValues(postData): Promise<any> {
  //   let data: any[] = [];
  //   let response = {};
  //   let promise = new Promise((resolve, reject) => {
  //     this.userService.getDropDownValues(postData).subscribe((res: any) => {
  //       response = res;
  //       resolve(response);
  //     }),
  //       err => {
  //         console.log("Error Occurred", err);
  //         reject(response);
  //       }
  //   });
  //   return promise;
  // }

  // async getOfftakeData(){
  //   let postDataaa={
  //     user_id : this.user.username
  //   }    
  //   this.userService.getOfftakes(postDataaa).subscribe(async (res: any) => {
  //     if(res){
  //       let postData={
  //         parentUUID : res["CANAL"][0],
  //         parentLocTypeName : 'CANAL',
  //         childLocTypeName : 'CANAL_CIRCLE'
  //       }
  //       let postData1={
  //         parentUUID : res["CANAL_CIRCLE"][0],
  //         parentLocTypeName : 'CANAL_CIRCLE',
  //         childLocTypeName : 'CANAL_DIVISION'
  //       }
  //       let postData2={
  //         parentUUID : res["CANAL_DIVISION"][0],
  //         parentLocTypeName : 'CANAL_DIVISION',
  //         childLocTypeName : 'DISTRIBUTARY_CANAL'
  //       }
  //       let postData3={...postData2}
  //       postData3.childLocTypeName = 'MINOR_CANAL';
  //       let postData4={...postData2}
  //       postData4.childLocTypeName = 'SUB_MINOR_CANAL';
  //       this.circles =  await this.getDropDownValues(postData);
  //       this.divisions =  await this.getDropDownValues(postData1);
  //       this.distributaries =  await this.getDropDownValues(postData2);
  //       this.minors = await this.getDropDownValues(postData3);
  //       this.subMinors =  await this.getDropDownValues(postData4);
       
  //       let i=1,j=i+300,z=j+300;
  //       Object.keys(this.distributaries).forEach((key) => {
  //         let x = { "id": i, "itemName": this.distributaries[key] };
  //         this.distributaryList.push(x);
  //         i++;
  //       })
        
  //       Object.keys(this.minors).forEach((key) => {
  //         let x = { "id": j, "itemName": this.minors[key] };
  //         this.minorList.push(x);
  //         j++;
  //       })
  //       Object.keys(this.subMinors).forEach((key) => {
  //         let x = { "id": z, "itemName": this.subMinors[key] };
  //         this.subMinorList.push(x);
  //         z++;
  //       })

  //     if(res["DISTRIBUTARY_CANAL"]!=null && Object.keys(this.distributaries).length!=0){       
  //       let dist = res["DISTRIBUTARY_CANAL"];
  //       for(let i=0;i<dist.length;i++){
  //         let item = dist[i];          
  //         Object.keys(this.distributaries).forEach(key => {
  //           if(item==key){
  //             this.distributariesNames.push((this.distributaries[key]));
  //           }
  //         });
  //       }
  //       for(let i=0;i<this.distributaryList.length;i++){
  //         let item = this.distributaryList[i];          
  //         let index = this.distributariesNames.indexOf(item.itemName);          
  //         if(index!=-1){
  //           this.selectedDistValues.push(item);
  //         }
  //       }
  //     }
  //     this.selectedDist = this.selectedDistValues;

  //     if(res["MINOR_CANAL"]!=null && Object.keys(this.minors).length!=0){       
  //       let dist = res["MINOR_CANAL"];
  //       for(let i=0;i<dist.length;i++){
  //         let item = dist[i];          
  //         Object.keys(this.minors).forEach(key => {
  //           if(item==key){
  //             this.minorsNames.push((this.minors[key]));
  //           }
  //         });
  //       }

  //       for(let i=0;i<this.minorList.length;i++){
  //         let item = this.minorList[i];          
  //         let index = this.minorsNames.indexOf(item.itemName);
  //         if(index!=-1){
  //           this.selectedMinorValues.push(item);
  //         }
  //       }
  //     }
  //     this.selectedMinor = this.selectedMinorValues;

  //     if(res["SUB_MINOR_CANAL"]!=null && Object.keys(this.subMinors).length!=0){       
  //       let dist = res["SUB_MINOR_CANAL"];
  //       for(let i=0;i<dist.length;i++){
  //         let item = dist[i];          
  //         Object.keys(this.subMinors).forEach(key => {
  //           if(item==key){
  //             this.subMinorsNames.push((this.subMinors[key]));
  //           }
  //         });
  //       }
  //       for(let i=0;i<this.subMinorList.length;i++){
  //         let item = this.subMinorList[i];          
  //         let index = this.subMinorsNames.indexOf(item.itemName);
  //         if(index!=-1){
  //           this.selectedSubMinorValues.push(item);
  //         }
  //       }
  //     }
  //     this.selectedSubMinor = this.selectedSubMinorValues;

  //     if(res["DISTRIBUTARY_CANAL"]!=null || res["MINOR_CANAL"]!=null || res["SUB_MINOR_CANAL"]!=null){
  //       this.userForm.patchValue({
  //           stage : res["CANAL"].length>0 ? res["CANAL"][0] : null,
  //           circle : res["CANAL_CIRCLE"].length>0? res["CANAL_CIRCLE"][0] : null,
  //           division : res["CANAL_DIVISION"].length>0? res["CANAL_DIVISION"][0]: null,
  //           distributary : res["DISTRIBUTARY_CANAL"].length>0 ? this.selectedDist : null,
  //           minor : res["MINOR_CANAL"].length>0 ? this.selectedMinor : null,
  //           subMinor : res["SUB_MINOR_CANAL"].length>0 ? this.selectedSubMinor : null
  //         })
  //         if(this.selectedDist==null){
  //           this.selectedDist = [];
  //         }
  //         if(this.selectedMinor==null){
  //           this.selectedMinor =[];
  //         }
  //         if(this.selectedSubMinor==null){
  //           this.selectedSubMinor =[];
  //         }
  //         let merged = this.selectedDist.concat(this.selectedMinor);
  //         this.selectedItems = merged.concat(this.selectedSubMinor)
  //         this.isFormValid();
  //     }
     
  //     }
  //   })
  // }
}
