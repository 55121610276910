import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {
  navigationSubscription: any;
  urlParams: any;
  title : any;
  remarks : any;
  constructor(private expandmodal: NgbActiveModal,private router: Router) { 
  }

  modalObj : any = {
    issueRemarks : '',
    referTo : ''
  };
  selectionType : any;
  referToDDList : any = ['DE','EE','SE','CE','SE PMU','ENC General'];
  ngOnInit() {

  }

  closeModal(){
    this.expandmodal.dismiss();
  }

  submit(){
    this.closeModal();
    // different views cant route
    // this.router.navigate(['tsripms', 'projectDashboard', 'report', this.urlParams]);      
  }
}
