import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.scss']
})
export class LogoutPopupComponent implements OnInit {

  modalService: any;
  confirmedResult: boolean;
  @Input() title: string;
  titleForPop:string = '';
  message: boolean;
  @Output() public onDelete: EventEmitter<{}> = new EventEmitter();
  @Output() public onLogout: EventEmitter<{}> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // this.titleForPop = this.title;
    // this.cdRef.detectChanges();
  }
  logout() {
    this.message = true;
    this.activeModal.close(true);
    this.onLogout.emit(this.message);
    // this.activeModal.close(true);
  }

}