export const TableDefaultConstants = {
    dataTypes: ['LOCATION', 'FINALLOCATION', 'TEXT', 'NUMBER', 'PERCENTAGE', 'DATE', 'FLOAT', 'ICON', 'IMAGE', 'ACTIONS'],
    dataTypesHavingClick: ['LOCATION', 'CLICK', 'CALLBACK', 'POPUP'],
    sortOptions: { sortIndex: 0 },
    searchFilterOptions: '',
    districtSortOrderList: ['SRIKAKULAM', 'VIZIANAGARAM', 'VISAKHAPATNAM', 'VISHAKAPATNAM', 'EAST GODAVARI', 'WEST GODAVARI', 'KRISHNA', 'GUNTUR', 'PRAKASAM', 'NELLORE', 'CHITTOOR', 'KADAPA', 'Y.S.R KADAPA', 'ANANTHAPUR', 'ANANTAPUR', 'KURNOOL'],
  
    tableBasicConfig: {
      tableInfo: {
        type: 'dataTable'
      },
      css: {
        tableClass: '',
        theadClass: '',
        tbodyClass: '',
      },
      defaultSort: true,
      searchFilter: false,
      downloadOptions: 'xlsx',
      paginator: 'false',
      tableHeaderFixerInfo: {
          headerFix: false,
          colFix: true
      },
      totalRow: {
          name: 'TOTAL',
          jsonKey: '-1'
      } 
    },
    
    columnConstantDefaultValues: {
      name: '',
      sortable: true,
      filter: false,
      editable: false,
      sortOrderList: [],
      dataType: 'TEXT',
      jsonKey: '',
      class: '',
      jsonInfo: {type: null, path: null, operation: null},
      popupInfo: {component: null},
      storeValues: {}
    }
  }