import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {SessionStorageService} from 'angular-web-storage';
import { CookieService } from 'ngx-cookie-service';
import { EncdecService } from './encdec.service';
import { HttperrorhandlerService } from './httperrorhandler.service';
import { ConstantsModule } from '../constants/constants.module';

@Injectable({
  providedIn: 'root'
})
export class AuthloginService {

  constructor(private constants : ConstantsModule,
    private http : HttpClient,
    private errorHandler: HttperrorhandlerService,
    private session: SessionStorageService,
    private cookie : CookieService,
    private encdec  : EncdecService) { }

  getuserlogin(options: object): Observable<any>{
    let loginuser = "login-with-encryption";
    return this.http.post<any>(this.constants.API_URL_USER + loginuser, options)
  }

  logoutuser(authpromis): Observable<any>{
    let userdetails = {};
    userdetails['login'] = authpromis['login'];
    userdetails['sessionKey'] = authpromis['sessionKey'];
    let logoutuser = "logout";
    return this.http.post<any>(this.constants.API_URL_USER + logoutuser, userdetails).pipe(
      catchError(this.errorHandler.handleError('logout user', []))
    );
  }
  reset(options: object): Observable<any>{
    let reset = "reset-otp-enc-password";
    return this.http.post<any>(this.constants.API_URL_USER + reset, options)
  }

  clearusersession(){
    this.cookie.delete('userdetails','/');
  }

  createsession(userdetails){
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 365);
    this.cookie.set('userdetails',JSON.stringify(userdetails),dateNow,'/');
  }

  checkloginstatus(){    
    return JSON.parse(this.cookie.get('userdetails'));
  }

  getHeadersWithCsrf(api?: string) {
    const options = { headers: {},withCredentials:true}
    options.headers['Content-Type'] = 'application/json';
    options.headers['Access-Control-Allow-Origin'] = "*";
    if(this.cookie.get('USERID')){
      let user_info = this.cookie.get('USERID');
      let csrf_token = user_info;

      if(this.cookie.get('ACCESS_TOKEN')){
        csrf_token = csrf_token+(this.cookie.get('ACCESS_TOKEN').substring(this.cookie.get('ACCESS_TOKEN').length-20));
      }
      let enc_id:String = this.encdec.rsaenc(this.constants.PUBLIC_KEY, csrf_token);
      options.headers['X-CSRF-TOKEN'] = enc_id;
    }

    return options;
  }

  checkuserforresetpwd(options : object) : Observable<any>{
    let checkreset = "send-email-otp";
    return this.http.post<any>(this.constants.API_URL_USER + checkreset, options,{withCredentials :true})
  }
}