import { isNullOrUndefined } from 'util';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // transform(data: any[], val: any): any {
  //   return !isNullOrUndefined(data) ? data.filter((s) => {
  //     let found = false;
  //     for(let k in s) {
  //       let actualVal = s[k] ? s[k].toString().toLowerCase() : null;
  //       if(!!actualVal) {
  //         found = actualVal.indexOf(val.toString().toLowerCase()) != -1;
  //         if(!!found) {
  //           break;
  //         }
  //       }
  //     }
  //     return found;
  //   }) : [];
  // }

transform(data: any[], val: any): any {
  return !isNullOrUndefined(data) ? data.filter((s) => {
    let found = false;
    for(let k in s) {
      let isObj = false;
      let actualVal = s[k] ? s[k] : null;
      if(actualVal && typeof(actualVal)== 'object'){
        isObj = true;
        let valArr = Object.values(actualVal),arrVal='';
        valArr.forEach((val) =>{
          arrVal = arrVal + " "+ val;
        })
        actualVal = arrVal;
      }
      if(actualVal){
        actualVal = actualVal.toString().toLowerCase();
      }          
      if(!!actualVal) {
        if(!isObj)
        found = actualVal.startsWith(val.toString().toLowerCase()) != false;
        else
        found = actualVal.indexOf(val.toString().toLowerCase()) != -1;
        if(!!found) {
          break;
        }
      }
    }
    return found;
  }) : [];
}
}
