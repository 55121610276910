import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import {BehaviorSubject}  from 'rxjs';
import {  CookieService  } from 'ngx-cookie-service'; 
import { environment } from 'src/environments/environment';
import { EncdecService } from '../services/encdec.service';
import { AuthloginService } from '../services/authlogin.service';
import { UtilsService } from '../services/utils.service';
import { ConstantsModule } from '../constants/constants.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare const grecaptcha : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validation : object = {login : true,password:true,captcha:true};
  UserData : object = {login:'',password:''};
  validusertypes = ["central","state","district","field"];
  activespineer : boolean;
  environmentType:boolean;
  @Input() fromdatasubmit;
  @Input() urlParams
  constructor(public activeModal :NgbActiveModal,
    public utils : UtilsService,
    public constants : ConstantsModule,
    private loginSrv : AuthloginService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr : ToastrService,
    private encdecservice : EncdecService,
    private cookie : CookieService
    ) { }
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn());

  ngOnInit() {
    //this.addScript();
    this.environmentType = environment.captcha_flag;
  }
  public loginClick(){
    if(this.basicValidation()){
      this.activespineer = true;
      let that = this;
      let urlParams ={};
      let postData;
      if(this.environmentType){
        postData={
            "username": this.encdecservice.rsaenc(this.constants.PUBLIC_KEY,this.UserData['login']+","+this.UserData['password']),
            "lock" : true,
            "captchaResponse" : grecaptcha.getResponse()
        }
      }  
      else{
        postData={
            "username": this.encdecservice.rsaenc(this.constants.PUBLIC_KEY,this.UserData['login']+","+this.UserData['password']),
            "lock" : true, 
        }
      }
        this.loginSrv.getuserlogin(postData).subscribe(logresp =>{
          this.activespineer = false;
          if(logresp['result']['success']){
            this.router.navigate(['tsripms','projectDashboard']);
            this.toastr.success("","Logged In Successfully", {timeOut: 2000, positionClass: 'toast-top-right'});
            this.activeModal.dismiss('Cross click');
            let dateNow = new Date();
            dateNow.setDate(dateNow.getDate() + 365);
            this.cookie.set('loggedIn', 'true',dateNow);
            var content = logresp['result']['content'];
            this.cookie.set('ACCESS_TOKEN', content['token'],dateNow,'/');
            this.cookie.set('ROLE', this.createUserInfos(content['userDetails'])['roles'],dateNow,'/');
            this.cookie.set('USERID',content['userDetails']['userId'],dateNow,'/');
            this.cookie.set('USER_INFO', JSON.stringify(this.createUserInfos(content['userDetails'])),dateNow,'/');
            this.cookie.set('USER_DETAILS_INFO', JSON.stringify(this.createUserDetail(content['userDetails']['userDetailsJson'])),dateNow,'/');
            let formattedResp = this.formatData(logresp);
            this.loginSrv.createsession(formattedResp);
            this.loggedIn.next(true);
          }else{            
            this.cookie.set('loggedIn', 'false');
            // grecaptcha.reset();            
            this.clearCookies();
            this.loggedIn.next(false);
            if(logresp['result']['status'] == 410){
              this.toastr.error("User is locked.Please check you email to reset your password")
            } else if(logresp['result']['status'] == 412){
            this.toastr.error(logresp['result']['message']);
            } else if(logresp['result']['status'] == 411){
              this.toastr.error("No such user exists or user might be locked.check your email to reset your password")
            } else {
            this.toastr.error("user name and password doesn't match");
            }
            }
        },function(err){
          that.activespineer = false;
          if(err['error']){
            that.toastr.error(err['error']['msg'])
          }
        })
     }
   
  }
  public createUserInfos(userData) {
    var obj = {};
    if(userData === null){
    return obj;
    }
    obj['firstName'] = userData['firstName'];
    obj['lastName'] = userData['lastName'];
    obj['email'] = userData['email'];
    obj['mobileNo'] = userData['mobileNo'];
    obj['roles'] = userData['roles'];
    obj['userId'] = userData['userId'];
    return obj;
    
  }
    public createUserDetail(data: any) {
      let obj:any = {};
      if(isNullOrUndefined(data)) {
      return obj;
      }
      for(let key in data) {
      if(key.indexOf('secondary') == -1 && key.indexOf('bbox') == -1) {
      obj[key] = data[key];
      }
      }
      console.log("\n user Detials Json obj \n ", obj);
      return obj;
  }

  public isLoggedIn() {
    let token = this.cookie.get('ACCESS_TOKEN');
    if (token && token !== "" && !isNullOrUndefined(token)) {
    return true;
    }
    return false;
    }
  public clearCookies() {
    this.cookie.delete('ACCESS_TOKEN');
    this.cookie.delete('ROLE');
    this.cookie.delete('USERID');
    this.cookie.delete('USER_INFO');
    this.cookie.delete('USER_DETAILS_INFO');
    }

  basicValidation(){
    if(this.utils.checkfield(this.UserData['login'])){
      this.validation['login'] = true;
    }else{
      this.validation['login'] = false;
    }

    if(this.utils.checkfield(this.UserData['password'])){
      this.validation['password'] = true;
    }else{
      this.validation['password'] = false;
    }  
    if(this.environmentType){
      if(grecaptcha.getResponse()){
        this.validation['captcha'] = true;
      } else {
        this.validation['captcha'] = false;
      }
      return this.validation['password'] && this.validation['login'] && this.validation['captcha'];
    }else{
      return this.validation['password'] && this.validation['login'];
    }
  } 

  validateOnchange(type){
    if(this.utils.checkfield(this.UserData[type])){
      this.validation[type] = true;
    }else{
      this.validation[type] = false;
    }
  } 
  formatData(logresp){
    let loginUser = {};
              loginUser['login'] = logresp['result']['content']['userDetails']['username'];
              loginUser['accesssidetabs'] = this.constants.ROLEBASEDSIDETABMAP[logresp['result']['content']['userDetails']['roles'][0]];
              loginUser['roles'] = logresp['result']['content']['userDetails']['roles'];
              loginUser['module'] = logresp['result']['content']['userDetails']['userDetailsJson']? logresp['result']['content']['userDetails']['userDetailsJson']['module']:'-';
              loginUser['sessionKey'] = logresp['result']['content']['token'];
              loginUser['expirationTime'] = logresp['result']['content']['expirationTime'];
              loginUser['loggedSuccess'] = true;
              loginUser['loggedOut'] = false;
              loginUser['loggedUserData'] = {
                "userProfile": {
                  "userId": logresp['result']['content']['userDetails']['userId']?logresp['result']['content']['userDetails']['userId']:null,
                  "lname": logresp['result']['content']['userDetails']['lastName']?logresp['result']['content']['userDetails']['lastName']:null,
                  "fname": logresp['result']['content']['userDetails']['firstName']?logresp['result']['content']['userDetails']['firstName']:null,
                  // "stateName": logresp['result']['content']['userDetails']['userDetailsJson']['stateName']?logresp['result']['content']['userDetails']['userDetailsJson']['stateName']:null,
                  // "countryName": logresp['result']['content']['userDetails']['userDetailsJson']['countryName']?logresp['result']['content']['userDetails']['userDetailsJson']['countryName']:null,
                  // "stateUUID": logresp['result']['content']['userDetails']['userDetailsJson']['stateId']?logresp['result']['content']['userDetails']['userDetailsJson']['stateId']:null,
                  // "countryUUID": logresp['result']['content']['userDetails']['userDetailsJson']['countryId']?logresp['result']['content']['userDetails']['userDetailsJson']['countryId']:null,
                  // "locationId":  logresp['result']['content']['userDetails']['userDetailsJson']['locationId']?logresp['result']['content']['userDetails']['userDetailsJson']['locationId']:null,
                  // "locationName": logresp['result']['content']['userDetails']['userDetailsJson']['locationName']?logresp['result']['content']['userDetails']['userDetailsJson']['locationName']:null,
                  // "locationType": logresp['result']['content']['userDetails']['userDetailsJson']['locationType']?logresp['result']['content']['userDetails']['userDetailsJson']['locationType']:null,
                  // "associatedlocationsDetails": [
                  //   {
                  //     "entityName": logresp['result']['content']['userDetails']['userDetailsJson']['locationName']?logresp['result']['content']['userDetails']['userDetailsJson']['locationName']:null,
                  //     "entityUUID": logresp['result']['content']['userDetails']['userDetailsJson']['permissions'] ? logresp['result']['content']['userDetails']['userDetailsJson']['permissions']['25']['PERMITTED'][0]['entityList'][0]:null,
                  //     "entityType": logresp['result']['content']['userDetails']['userDetailsJson']['permissions'] ? logresp['result']['content']['userDetails']['userDetailsJson']['permissions']['25']['PERMITTED'][0]['entityType'] :null
                  //   }
                  // ]
                },
              };
              return loginUser;
    
  }

  addScript() {
    let script = document.createElement('script');
    // const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  
  resolved(){    
    if(grecaptcha.getResponse()){
      this.validation['captcha'] = true;
    } else {
      this.validation['captcha'] = false;
    }    
  }

}
