import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthloginService } from 'src/app/services/authlogin.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from 'src/app/modules/user-management/change-password/change-password.component';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../services/http.service';
import { SharedConstantsModule } from '../shared/shared-constants.module';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  userdetail:object = {};
  userdetails:any;
  urlParams: any;
  lastParam: string;
  sidebarcollapse: boolean = false;

  constructor(private router: Router,private sessionlogin : AuthloginService, private utils : UtilsService,
    private cookie : CookieService,private modalService: NgbModal, private toastr : ToastrService, private route: ActivatedRoute,
    private http: HttpService,private sharedConstants : SharedConstantsModule) { }

  ngOnInit() {
    if(!(localStorage.getItem('theme')) || localStorage.getItem('theme') == 'body-bg-dark light'){
      this.toggleTheme('light');
    }
    else{
      this.toggleTheme('black');
    }

    let params = this.router.url;
    let paramsList = params.split("/");
    this.lastParam = paramsList[paramsList.length - 1];
          
    if(this.cookie.get('loggedIn')=='true'){
      this.userdetail = this.sessionlogin.checkloginstatus();
      this.userdetail['login'] = this.utils.checkUndefinedOrNull(this.userdetail['login']); 
      this.userdetails = JSON.parse(this.cookie.get('userdetails'));
    }
   
  }
  toggleTheme(theme: string){
    if(theme === 'light') {document.body.classList.add('light')}else {document.body.classList.remove('light')}
    localStorage.setItem('theme',document.body.classList.value);
    this.http.themeChanged(theme);
  }
  sidebarToggle() {
    this.sidebarcollapse = !this.sidebarcollapse;
  }
  gisRouter(comp){
    if(comp == 'project'){
      this.urlParams={
        projectName : 'sitaRama',
        chartType : 'land acquisition',
        uuid : '932cff74-cc1f-4d47-b222-c2802abd986c',
        locType : 'PROJECT'
      }
      this.router.navigate(['tsripms','projectDashboard','projectDetails',this.urlParams]);
    }
    else if(comp == 'gisview'){
      let params ={
        locType : this.sharedConstants.LOC_CONSTANT[0],
        sDate : 20210101,
        eDate : 20210306
      }
      this.router.navigate(['tsripms','geoportal','gis',params]);
    }
  }
  goToLandingPage(){
    this.router.navigate(['tsripms']);
  }
  
  showProjectDetails(){
    this.urlParams={
      projectName : 'sitaRama'
    }
    this.router.navigate(['tsripms','projectDashboard','projectDetails',this.urlParams]);
  }
  routeToGIS(){
    this.router.navigate(['tsripms','geoportal']);
  }

  changePassword(){
    const modal = this.modalService.open(ChangePasswordComponent, {
    size: 'lg'
    });
    }

  public logoutUser(){
      let url  = this.router.url; 
      let option = this.sessionlogin.checkloginstatus();
      // this.activespineer = true;
      this.sessionlogin.logoutuser(option).subscribe(logoutres =>{
         if(logoutres['result']['success']){
          this.cookie.set('loggedIn', 'false');
          this.cookie.delete('ACCESS_TOKEN','/');
          this.cookie.delete('ROLE');
          this.cookie.delete('USERID','/');
          this.cookie.delete('USER_INFO','/');
          this.cookie.delete('USER_DETAILS_INFO','/');
          this.sessionlogin.clearusersession();
          this.router.navigate(["tsripms"]);
          this.toastr.success("","Logged Out Successfully", {timeOut: 2000, positionClass: 'toast-top-right'});
        }
      })
    }

  
    getUserRole(){
      let role = this.cookie.get('ROLE');      
      if(role=='ADMIN' || role=='SUPER_ADMIN'){
        return true;
      }else{
        return false;
      }
    }

    goToDetails(type){
      this.router.navigate(['tsripms','projectDashboard',type]);
    }
}

