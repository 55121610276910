import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { DataTableEventsService } from '../../../../services/datatable.events.service';

@Component({
  selector: 'lib-table-cell, [lib-table-cell]',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.css']
})
export class TableCellComponent implements OnInit {

  @Input() cellData: any;
  @Input() isTotalData: boolean;
  @Output() onCellClick = new EventEmitter<Object>();

  constructor(
    private tableEventsHandler: DataTableEventsService
    ) { }

  ngOnInit() {
  }

  click(element: any): void {
    this.onCellClick.emit(element);
  }

  popupCalled(cellData: any){
    this.tableEventsHandler.popupCalled.next(cellData);
  }

}
