import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TableDefaultConstants } from '../common/constants/table.constant';
import { tableHeaderFixerDirective } from '../common/directives/table-header-fixer.directive';
import { DataTableService } from '../common/services/data-table.service';
import { DataTableEventsService } from '../common/services/datatable.events.service';


@Component({
  selector: 'lib-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() tableConfig: any;
  @Input() data: object;
  @Input() tableNameForDownload: string;
  @Input() loader: boolean;
  @Input() tableType: string;
  @Input() tableId: string;
  @Input() customTemplates: TemplateRef<any>;
  @Input() metaData: object;

  @Output() onRowClick = new EventEmitter<Object>();
  
 // For Angular 7
  @ViewChild(tableHeaderFixerDirective) tablefixer: tableHeaderFixerDirective;
  // For Angular 8
  // @ViewChild(tableHeaderFixerDirective, { static: false }) tablefixer: tableHeaderFixerDirective;


  sortOptions = TableDefaultConstants.sortOptions;
  tableData: any = {};
  masterRecordData: any;
  masterTotalData: any;
  noData: boolean = false;
  paginatorOptions: any;
  headerFixerOptions: any;
  headerCons: object[];
  indexCons: object;
  breadcrumbCons: any;
  downloadOptions: object;

  subscriptions: Subscription[] = [];

  private recordDataSub: Subscription;
  private totalDataSub: Subscription;
  private dataSub: Subscription;
  staticRows: any;


  constructor(private tableEventsHandler: DataTableEventsService,
    private tableService: DataTableService) { }

  ngOnInit() {
    this.subscribeEvents();
    if (this.tableConfig == undefined || this.tableData == undefined) {
      console.log("Please define proper table configuration and data to render the table data.")
      return;
    }
    this.updateStateData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tableConfig == undefined || this.tableData == undefined) {
      console.log("Please define proper table configuration and data to render the table data.")
      return;
    }
    this.updateStateData();
  }

  ngOnDestroy() {
    this.unsubscribeEvents();
  }

  subscribeEvents() {
    this.recordDataSub = this.tableEventsHandler.recordData.subscribe(data => {
      if (data.length == 0 || isNullOrUndefined(data)) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.tableData.recordData = data;
    })
    this.totalDataSub = this.tableEventsHandler.totalData.subscribe(data => {
      this.tableData.totalData = data;
    })
    this.dataSub = this.tableEventsHandler.data.subscribe(data => {
      this.data = data;
      this.tableData.recordData = data;
      this.formatTable();
    })
    this.subscriptions.push(this.recordDataSub);
    this.subscriptions.push(this.totalDataSub);
    this.subscriptions.push(this.dataSub);
  }

  unsubscribeEvents() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  takeBackup = () => {
    this.masterRecordData = this.tableData.recordData ? JSON.parse(JSON.stringify(this.tableData.recordData)) : [];
    this.masterTotalData = this.tableData.totalData ? JSON.parse(JSON.stringify(this.tableData.totalData)) : [];
  }

  updateStateData() {
    this.staticRows = this.tableConfig.staticRows;
    this.paginatorOptions = this.tableConfig.paginatorOptions;
    this.headerFixerOptions = this.tableConfig.tableHeaderFixerInfo;
    this.headerCons = this.tableConfig.columns;
    this.indexCons = this.tableConfig.indexCons;
    this.breadcrumbCons = this.tableConfig.breadcrumbCons;
    this.downloadOptions = this.tableConfig.downloadOptions
    if (!isNullOrUndefined(this.tableConfig) && !isNullOrUndefined(this.tableConfig.sortOptions)) {
      this.sortOptions = this.tableConfig.sortOptions;
    }
    this.formatTable();

    this.tableEventsHandler.togglePage.subscribe(() => {
      setTimeout(() => {
        this.tablefixer.process();
      }, 10);
    })

    this.tableEventsHandler.sortOptions.subscribe(() => {
      setTimeout(() => {
        this.tablefixer.process();
      }, 10);
    })
    // if(!isNullOrUndefined(this.cons) && !isNullOrUndefined(this.cons.defaultSort)){
    //   this.sortOptions = {  sortOrder: this.cons.defaultSort };
    // }
  }

  formatTable() {
    switch (this.tableType) {

      case 'dataTable':
        this.tableData = this.tableService.formatTable(this.tableConfig, this.data);
        break;
      case 'treeTable':
        break;
      case 'crudTable':
        break;
      default:
        console.warn('Table type not found ! = ', this.tableType);
        break;
    }
    this.takeBackup();
  }

  click(element: any): void {
    this.onRowClick.emit(element);
  }

}
