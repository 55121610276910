import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  urlConstants = environment.urlMapping;
  invokeEvent: Subject<any> = new Subject();

  constructor(private httpClient: HttpClient) { }

  //subscriptions for Theme change
  themeChanged(value) {
    this.invokeEvent.next(value);
  }

  getMetaData(projectUUID) {
    let url = 'http://68.183.185.86/api/ppm/metadata/' + projectUUID;
    return this.httpClient.get<any>(url);
  }

  getProjectList(telanganaUUID){
    let url = 'http://68.183.185.86/api/ppm/getProjectMetaDataList/' + telanganaUUID;
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Physical Progress for Particular Activity *****************/

  getActivityLevelData(params) {
    let url = 'http://68.183.185.86/api/ppm/aggregated/activity/' + params['date'] + '/' + params['component'] + '/' + params['packageName'];
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Physical Progress upto Package Level *****************/

  getProjectComponentLevelData(params) {
    let url = 'http://68.183.185.86/api/ppm/aggregatedData/packageandproject/' + params['locType'] + '/' + params['uuid'] + '/' + params['date'];
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Financial Progress *****************/

  getFinancialLevelData() {
    let url = 'http://68.183.185.86/api/ppm/relay/financial';
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Land Acquisition Progress *****************/

  getLALevelData() {
    let url = 'http://68.183.185.86/api/ppm/relay/la';
    return this.httpClient.get<any>(url);
  }

  /********** Gives Composite Response For Package vs Activity *****************/

  getReportDataValidationResponse(params) {
    let url = 'http://68.183.185.86/api/ppm/aggregated/activity/' + params['date'] + '/' + params['packageName'];
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Alerts  *****************/

  getAlertsData(params) {
    let url = 'http://68.183.185.86/api/ppm/escalation/' + params['locType'] + '/' + params['package'] + '/' + params['sDate'] + '/' + params['eDate'];
    return this.httpClient.get<any>(url);
  }

  /********** Gives Response For Selected Alert *****************/

  getAlertDetailsForDataTab(eUUID) {
    let url = 'http://68.183.185.86/api/ppm/escalation/data/' + eUUID;
    return this.httpClient.get<any>(url);
  }

  /**************** Params For Physical Progress upto Package Level***************************/

  getProjectComponentLevelParams(date, locType, uuid) {
    return {
      'date': date,
      'locType': locType,
      'uuid': uuid
    };
  }

  /**************** Params For Physical Progress Activity Level ***************************/

  getActivityLevelParams(date, component, packageName) {
    return {
      'date': date,
      'component': component,
      'packageName': packageName
    };
  }

  /**************** Params For Alerts ***************************/

  getAlertsParams(urlParams) {
    return {
      'locType': 'PACKAGE',
      'package': urlParams['locType'] == 'PROJECT' ? 'ALL' : urlParams['packageName'],
      'sDate': urlParams['sDate'],
      'eDate': urlParams['eDate']
    };
  }
}
