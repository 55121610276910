import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { ExcelService } from '../../services/excel.service';
import { DataTableEventsService } from '../../services/datatable.events.service';


@Component({
  selector: 'lib-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class TableToolbarComponent implements OnInit, OnChanges {

  @Input() tableInfo: any;
  @Input() recordData: any;
  @Input() totalData: any;
  @Input() downloadOptions: any;
  @Input() tableId : string;
  @Input() headers : any;
  @Input() headerCons : object[];
  @Input() metaData : object;
  @Input() searchFilter: boolean=true;
  @Input() staticRows : any;
  @Input() downloadButton: object;

  searchText: string;
  searchTextHolder: string;
  allRecords: any;
  filteredRecords: object[] = [];
  totalDataCopy:object[] = [];
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementId: 'd'
  }
  staticRowsData: any=[];
  showOnView: any;
  constructor(private exportAsService: ExportAsService,
              private excelService: ExcelService,
              private tableEventsHandler: DataTableEventsService) { }

//   ngOnInit() {
//     if(this.recordData == undefined)  return;
//     this.filteredRecords =JSON.parse(JSON.stringify(this.recordData));
//     this.totalDataCopy = JSON.parse(JSON.stringify(this.totalData));
//     if(this.staticRows){
//       this.staticRowsData = this.staticRows.rowValues;
//       this.showOnView = this.staticRows.showOnView;
//     }
//     this.allRecords = this.recordData;
//     this.exportAsConfig.type = (this.downloadOptions == undefined)?"":this.downloadOptions.downloadType;
//     this.exportAsConfig.elementIdOrContent = this.tableId;
//   }

//   ngOnChanges(change: SimpleChanges){
//     this.ngOnInit();
//   }
//   filterRecords = () => {
//     let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/ ;
//     let textWithoutSpecialCharacters;
//     this.searchTextHolder = this.searchText.toLowerCase().trim();
//     if (!isNullOrUndefined(this.searchTextHolder) && this.searchTextHolder != '') {      
//       this.filteredRecords = [];
//       for (let i = 0; i < this.recordData.length; i++) {
//         let flag = false;
//         let outerRecord = this.recordData[i];
//         for (let j = 0; j < outerRecord.length; j++) {
//           let values: any = '';
//           let innerRecord = outerRecord[j];
//           [values] = [innerRecord.values]
//           let { value } = values[0];
//           if ((typeof values != 'object' || values instanceof Array) && isNullOrUndefined(value)) {
//             let text = values[0].toString();
//             if(specialCharacters.test(this.searchTextHolder)){
//               textWithoutSpecialCharacters = text;
//             }else{
//               textWithoutSpecialCharacters = text.replace(/[^a-zA-Z0-9]/g, '');
//             }
//             if (textWithoutSpecialCharacters.toLowerCase().indexOf(this.searchTextHolder) != -1) {              
//               flag = true;
//               break;
//             }
//           }
//           else {
//             [values] = innerRecord.values;
//             if (values['value'].toLowerCase().indexOf(this.searchTextHolder) != -1) {
//               flag = true;
//               break;
//             }
//           }
//         }
//         if (flag)
//           this.filteredRecords.push(outerRecord);
//       }
//       this.totalDataCopy = [];
//     } else {
//       this.filteredRecords = this.recordData;
//       this.totalDataCopy = this.totalData;
//     }

//     this.tableEventsHandler.recordData.next(this.filteredRecords);
//     this.tableEventsHandler.totalData.next(this.totalDataCopy);
//     this.tableEventsHandler.searchHolder.next(this.searchTextHolder);
//   }

//   // export() {
//   //   if(this.downloadOptions.downloadAll)
//   //   { 
//   //     this.excelService.downloadFile(this.downloadOptions,this.headers, this.allRecords, this.staticRowsData,this.showOnView,this.headerCons, this.metaData);
//   //   }
//   //   else{
//   //     this.exportAsService.save(this.exportAsConfig, this.downloadOptions.downloadFileName).subscribe(() => {
//   //   });
//   //   }
//   // }
//   export(option: object) {
//     this.exportAsConfig.type = (option == undefined) ? "" : option['downloadType'];
//    if(option['downloadAll'])
//    {
//      this.excelService.downloadFile(option,this.headers, this.filteredRecords,this.staticRowsData,this.showOnView, this.headerCons, this.metaData);
//    }
//    else{
//      this.exportAsService.save(this.exportAsConfig, option['downloadFileName']).subscribe(() => {
//      });
//    }
//  }
 ngOnInit() {
  if(this.recordData == undefined)  return;
  this.filteredRecords =JSON.parse(JSON.stringify(this.recordData));
  this.totalDataCopy = JSON.parse(JSON.stringify(this.totalData));
      if(this.staticRows){
      this.staticRowsData = this.staticRows.rowValues;
      this.showOnView = this.staticRows.showOnView;
    }
  this.allRecords = this.recordData;
  this.exportAsConfig.type = (this.downloadOptions == undefined)?"":this.downloadOptions.downloadType;
  this.exportAsConfig.elementId = this.tableId;
}

ngOnChanges(change: SimpleChanges){
  this.ngOnInit();
}
filterRecords = () => {
  this.searchTextHolder = this.searchText.toLowerCase().trim();
  if (!isNullOrUndefined(this.searchTextHolder) && this.searchTextHolder != '') {      
    this.filteredRecords = [];
    for (let i = 0; i < this.recordData.length; i++) {
      let flag = false;
      let outerRecord = this.recordData[i];
      for (let j = 0; j < outerRecord.length; j++) {
        let values: any = '';
        let innerRecord = outerRecord[j];
        [values] = [innerRecord.values]
        let { value } = values[0];
        if ((typeof values != 'object' || values instanceof Array) && isNullOrUndefined(value)) {
          if (values.toString().toLowerCase().indexOf(this.searchTextHolder) != -1) {
            flag = true;
            break;
          }
        }
        else {
          [values] = innerRecord.values;
          if (values['value'].toLowerCase().indexOf(this.searchTextHolder) != -1) {
            flag = true;
            break;
          }
        }
      }
      if (flag)
        this.filteredRecords.push(outerRecord);
    }
    this.totalDataCopy = [];
  } else {
    this.filteredRecords = this.recordData;
    this.totalDataCopy = this.totalData;
  }

  this.tableEventsHandler.recordData.next(this.filteredRecords);
  this.tableEventsHandler.totalData.next(this.totalDataCopy);
  this.tableEventsHandler.searchHolder.next(this.searchTextHolder);
}

export(option: object) {
   this.exportAsConfig.type = (option == undefined) ? "" : option['downloadType'];
  if(option['downloadAll'])
  {
    this.excelService.downloadFile(option,this.headers, this.filteredRecords,this.staticRowsData,this.showOnView, this.headerCons, this.metaData);
  }
  else{
    this.exportAsService.save(this.exportAsConfig, option['downloadFileName']).subscribe(() => {
    });
  }
}

/**
 * @param element
 */
getClass(element: string){
  let noDownloadText = this.downloadButton['text'] === '';
  let elementWeight = {
    mix: {
      'title': (10 + ((noDownloadText)?1:0)),
      'search': (10 + ((noDownloadText)?1:0)),
      'download': (2 - ((noDownloadText)?1:0))
    },
    heavy: {
      'title': 8,
      'search':4
    },
    normal: {
      'title': 7,
      'search':3,
      'download': 2
    }
  }
  let displayItems = 0, order='', heavyElements = false;
  let result = "";

  displayItems += (this.tableInfo['title'] && this.tableInfo['title']['display'])?1:0;
  displayItems += (this.searchFilter && this.searchFilter['display'])?1:0;
  displayItems += (this.downloadButton && this.downloadButton['display'])?1:0;
  heavyElements = (this.tableInfo['title'] && this.tableInfo['title']['display']) && (this.searchFilter && this.searchFilter['display']);
  
  if(element === 'title')
    order = this.tableInfo['title']['order'];
  else if(element === 'search')
    order = this.searchFilter['order'];
  else
    order = this.downloadButton['order']

  if(displayItems == 3)
    result = "col-md-" + (elementWeight['normal'][element]) + " " + order;
  
  else
    result = "col-md-" + ((heavyElements)?elementWeight['heavy'][element]:elementWeight['mix'][element]) + " " + order;
  return result;
}
}
